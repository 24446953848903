import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";

import { get } from "../../../helpers/RequestHelper";

function Filter({ onChange }) {
  const [exams, setExams] = useState([]);
  useEffect(() => {
    (async function getExamData() {
      const examData = await get(`/api/admin/exam/list`);
      setExams(examData);
    })();
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    onChange({
      exam: document.getElementById("exam").value,
      text: document.getElementById("text").value,
      visibility: document.getElementById("visibility").value,
      page: 1,
    });
  }

  function handleReset(e) {
    e.preventDefault();
    document.getElementById("exam").value = "";
    document.getElementById("text").value = "";
    document.getElementById("visibility").value = "all";

    onChange({
      exam: "",
      text: "",
      visibility: "",
      page: 1,
    });
  }

  return (
    <Form onSubmit={handleSubmit} onReset={handleReset}>
      <fieldset className="filters">
        <legend align="left">Filters:</legend>
        <div className="filters-body">
          <Row>
            <Col md="3">
              <Form.Group controlId="exam">
                <Form.Label>Exam</Form.Label>
                <Form.Control as="select" placeholder="Select" defaultValue="">
                  <option value="">All</option>
                  {exams.map((exam) => (
                    <option value={exam._id} key={exam._id}>
                      {exam.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group controlId="visibility">
                <Form.Label>Visibility</Form.Label>
                <Form.Control as="select" placeholder="Select" defaultValue="all" className="text-capitalize">
                  {['all', 'public','private','subscriber_only','package_only', 'national_mock'].map((v) => (
                    <option value={v} key={v} className="text-capitalize">
                      {v}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group controlId="text">
                <Form.Label>Search By Test Text</Form.Label>
                <Form.Control as="input" placeholder="Enter text" />
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className="filters-footer">
          <Button type="reset" variant="outline-secondary">
            Reset
          </Button>{" "}
          <Button type="submit" variant="primary">
            Submit
          </Button>
        </div>
      </fieldset>
    </Form>
  );
}
export default Filter;
