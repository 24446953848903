import React from "react";
import "./../../App.css";
import { Link } from "react-router-dom";
import { Nav, Navbar, NavItem, Dropdown, NavLink } from "react-bootstrap";
import { userContext } from "../../userContext";

export default function Navigate() {
  return (
    <Navbar bg="dark" expand="lg" variant="dark">
      <Nav>
        <Link to="/" className="nav-link">
          <NavItem>Eduwhere Admin</NavItem>
        </Link>
      </Nav>
      <userContext.Consumer>
        {({ user, logoutUser }) => {
          if (!user) {
            return "";
          }
          return (
            <>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                  <Link to="/admin/exam" className="nav-link">
                    <NavItem>Exam</NavItem>
                  </Link>
                  <Link to="/admin/subject" className="nav-link">
                    <NavItem>Subject</NavItem>
                  </Link>
                  <Link to="/admin/test" className="nav-link">
                    <NavItem>Test</NavItem>
                  </Link>
                  <Link to="/admin/question" className="nav-link">
                    <NavItem>Question</NavItem>
                  </Link>
                  <Link to="/admin/questiondirection" className="nav-link">
                    <NavItem>QuestionDirection</NavItem>
                  </Link>
                  <Link to="/admin/user" className="nav-link">
                    <NavItem>User</NavItem>
                  </Link>
                  <Link to="/admin/ewrole" className="nav-link">
                    <NavItem>Role</NavItem>
                  </Link>
                  <Dropdown as={NavItem}>
                    <Dropdown.Toggle as={NavLink} className="text-capitalize">
                      {user.name}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu-right">
                      <Dropdown.Item onClick={logoutUser}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav>
              </Navbar.Collapse>
            </>
          );
        }}
      </userContext.Consumer>
    </Navbar>
  );
}
