import React, { useState, useEffect } from "react";
import { Card, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Filters from "./Filter";
import ExamCard from "./ExamCard";
import { get } from "../../../helpers/RequestHelper";
import queryString from "query-string";

const LIMIT = 50;
function Exam() {
  let history = useHistory();
  const [exams, setExams] = useState([]);
  const [filters, setFilters] = useState({
    limit: LIMIT,
    page: 1,
  });
  const [totalCount, setTotalCount] = useState(0);
  useEffect(() => {
    getExamData();
  }, [filters]);

  async function getExamData(exam_id) {
    try {
      const stringified = queryString.stringify(filters);
      let url = `/api/admin/exam?${stringified}`;
      if (exam_id) {
        url = url + "$exam=" + exam_id;
      }
      const { exams: examData } = await get(url);
      setExams(examData);
      setTotalCount(examData.total_count);
    } catch (err) {}
  }

  async function onFilterChange(newFilters) {
    setFilters({
      ...filters,
      ...newFilters,
    });
  }

  const handleClick = () => {
    history.push(`/admin/exam/create`);
  };
  const examCategoryPage = () => {
    history.push("/admin/examcategory");
  };
  const examCards = exams.map((exam, index) => {
    return (
      <ExamCard
        exam={exam}
        getExamData={getExamData}
        key={exam._id}
        last={exams.length === index + 1}
      />
    );
  });

  return (
    <>
      <Filters exams={exams} onChange={onFilterChange} />
      <Card bg="light" text="dark" className="mb-2">
        <Card.Header>
          <h4 className="float-left">Manage Exams</h4>
          <div className="float-right">
            <Button variant="primary" onClick={examCategoryPage}>
              Manage Exam Category
            </Button>{" "}
            <Button variant="primary" onClick={handleClick}>
              Add New
            </Button>
          </div>
        </Card.Header>
        <Card.Body className="p-2">{examCards}</Card.Body>
      </Card>
    </>
  );
}
export default Exam;
