import React, { useEffect, useState } from "react";
import { Card, Button, Form } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { get, put } from "../../helpers/RequestHelper";
import axios from "axios";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
function Exam() {
  let history = useHistory();

  const [exam, setExam] = useState(null);
  const [categories, setCategory] = useState([]);
  let { examId } = useParams();

  useEffect(() => {
    getExamData(examId);
    getExamCategory();
  }, [examId]);

  async function getExamCategory() {
    const examCategory = await get("/api/admin/examcategory?status=1");
    setCategory(examCategory.categories);
  }

  const categoryData = (category) => {
    return (
      <option key={category._id} value={category._id}>
        {category.name}
      </option>
    );
  };

  function handleChange(event) {
    if (event.target.id === "available_languages") {
      let options = event.target.options;
      let value = [];
      for (let i = 0, l = options.length; i < l; i++) {
        if (options[i].selected) {
          value.push(options[i].value);
        }
      }

      setExam({
        ...exam,
        available_languages: value,
      });
      return;
    }

    setExam({
      ...exam,
      [event.target.id]: event.target.value,
    });
  }

  function goBack() {
    history.push(`/admin/exam`);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    if (exam.description === "") {
      var desc = document.getElementById("desc");
      desc.style.display = "block";

      document.getElementById("desc").scrollIntoView();

      return;
    } else if (exam.description.length < 150) {
      document.getElementById("descr").style.display = "block";
      document.getElementById("descr").scrollIntoView();
      return;
    } else if (exam.instructions === "") {
      document.getElementById("ins").style.display = "block";
      document.getElementById("ins").scrollIntoView();
      return;
    } else if (exam.meta_introduction === "") {
      document.getElementById("intro").style.display = "block";
      document.getElementById("intro").scrollIntoView();
      return;
    } else {
      try {
        await put(`/api/admin/exam/${examId}`, exam);
        goBack();
      } catch (err) {}
    }
  }

  function handleBlur(event) {
    setExam({
      ...exam,
      [event.target.id]: event.target.value.trim(),
    });
  }

  async function handleFileChange(event) {

    const fd = new FormData();
    fd.append("image", event.target.files[0], event.target.files[0].name);
    const res = await axios.post("/api/admin/upload/image?output=url", fd);
    setExam({
      ...exam,
      "image": res.data,
    });
  }

  async function getExamData(examId) {
    try {
      const examData = await get("/api/admin/exam/" + examId);

      setExam(examData);
    } catch (err) {
      setExam(null);
    }
  }

  return (
    <Card>
      <Card.Header>
        <h4 className="float-left">Update Collection</h4>
      </Card.Header>
      <Form onSubmit={handleSubmit}>
        <Card.Body>
          {exam ? (
            <>
              <Form.Group
                controlId="name"
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <Form.Label
                  className="float-left"
                  style={{ marginLeft: "5px", marginTop: "5px" }}
                >
                  Name
                </Form.Label>
                <Form.Control
                  as="input"
                  defaultValue={exam.name}
                  placeholder="Name"
                  required
                  value={exam.name}
                />
              </Form.Group>
              <Form.Group
                controlId="meta_title"
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <Form.Label
                  className="float-left"
                  style={{ marginLeft: "5px", marginTop: "20px" }}
                >
                  Meta Title
                </Form.Label>
                <Form.Control
                  as="textarea"
                  defaultValue={exam.meta_title}
                  rows="3"
                  placeholder="Description"
                  required
                  minLength="50"
                  value={exam.meta_title}
                />
              </Form.Group>
              <Form.Group
                controlId="meta_keyword"
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <Form.Label
                  className="float-left"
                  style={{ marginLeft: "5px", marginTop: "5px" }}
                >
                  Meta Keywords
                </Form.Label>
                <Form.Control
                  as="input"
                  defaultValue={exam.meta_keyword}
                  placeholder="Meta Keywords"
                  required
                  minLength="50"
                  value={exam.meta_keyword}
                />
              </Form.Group>
              <Form.Group
                controlId="meta_description"
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <Form.Label
                  className="float-left"
                  style={{ marginLeft: "5px", marginTop: "5px" }}
                >
                  Meta Description
                </Form.Label>
                <Form.Control
                  as="input"
                  defaultValue={exam.meta_description}
                  placeholder="Meta Description"
                  required
                  minLength="150"
                  value={exam.meta_description}
                />
              </Form.Group>
              <Form.Group
                controlId="visibility"
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <Form.Label
                  className="float-left"
                  style={{ marginLeft: "5px", marginTop: "5px" }}
                >
                  Visibility
                </Form.Label>
                <Form.Control
                  as="select"
                  defaultValue={exam.visibility}
                  required
                  placeholder="Visibility"
                  value={exam.visibility}
                >
                  <option key="select">Select Visibility</option>
                  <option key="Public" value="public">
                    Public
                  </option>
                  <option key="Private" value="private">
                    Private
                  </option>
                </Form.Control>
              </Form.Group>
              <Form.Group
                controlId="available_languages"
                onChange={handleChange}
              >
                <Form.Label
                  className="float-left"
                  style={{ marginLeft: "5px", marginTop: "5px" }}
                >
                  Select Language
                </Form.Label>
                <Form.Control
                  as="select"
                  defaultValue={exam.available_languages}
                  required
                  multiple
                  placeholder="Language"
                  value={exam.available_languages}
                >
                  <option key="english" value="english">
                    English
                  </option>
                  <option key="hindi" value="hindi">
                    Hindi
                  </option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="description">
                <Form.Label
                  className="float-left"
                  style={{ marginLeft: "5px", marginTop: "5px" }}
                >
                  Description
                </Form.Label>
                <br />
                <br />
                <CKEditor
                  id="description"
                  editor={ClassicEditor}
                  data={exam.description}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    if (data === "") {
                      document.getElementById("desc").style.display = "block";
                      return;
                    } else if (!(data === "")) {
                      document.getElementById("desc").style.display = "none";
                      setExam({
                        ...exam,
                        "description": data,
                      });
                      return;
                    }
                  }}
                  onBlur={(event, editor) => {
                    const data = editor.getData();
                    setExam({
                      ...exam,
                      "description": data.trim(),
                    });
                  }}
                />
                <p id="desc" style={{ display: "none", color: "red" }}>
                  Please fill in this field!
                </p>
                <p id="descr" style={{ display: "none", color: "red" }}>
                  Minimum length of this field is 150!
                </p>
              </Form.Group>
              <Form.Group controlId="instructions">
                <Form.Label
                  className="float-left"
                  style={{ marginLeft: "5px", marginTop: "5px" }}
                >
                  Instruction
                </Form.Label>
                <br />
                <br />
                <CKEditor
                  editor={ClassicEditor}
                  data={exam.instructions}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    if (data === "") {
                      document.getElementById("ins").style.display = "block";
                      return;
                    } else if (!(data === "")) {
                      document.getElementById("ins").style.display = "none";
                      setExam({
                        ...exam,
                        "instructions": data,
                      });
                      return;
                    }
                  }}
                  onBlur={(event, editor) => {
                    const data = editor.getData();
                    setExam({
                      ...exam,
                      "instructions": data.trim(),
                    });
                  }}
                />
                <p id="ins" style={{ display: "none", color: "red" }}>
                  Please fill in this field!
                </p>
              </Form.Group>
              <Form.Group controlId="category" onChange={handleChange}>
                <Form.Label
                  className="float-left"
                  style={{ marginLeft: "5px", marginTop: "5px" }}
                >
                  Category
                </Form.Label>
                <Form.Control
                  as="select"
                  defaultValue={exam.category._id}
                  placeholder="Category"
                  required
                  value={exam.category._id}
                >
                  {categories.map(categoryData)}
                </Form.Control>
                .
              </Form.Group>
              <Form.Group controlId="image" onChange={handleFileChange}>
                <Form.File name="File" as="file" label="Exam Image"></Form.File>
                <div className="img-thumbnail ">
                  <img src={exam.image} alt="exam-img" />
                </div>
              </Form.Group>

              <Form.Group controlId="meta_introduction">
                <Form.Label
                  className="float-left"
                  style={{ marginLeft: "5px", marginTop: "5px" }}
                >
                  Introduction
                </Form.Label>
                <br />
                <br />
                <CKEditor
                  editor={ClassicEditor}
                  data={exam.meta_introduction}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    if (data === "") {
                      document.getElementById("intro").style.display = "block";
                      return;
                    } else if (!(data === "")) {
                      document.getElementById("intro").style.display = "none";
                      setExam({
                        ...exam,
                        "meta_introduction": data,
                      });
                      return;
                    }
                  }}
                  onBlur={(event, editor) => {
                    const data = editor.getData();
                    setExam({
                      ...exam,
                      "meta_introduction": data.trim(),
                    });
                  }}
                />
                <p id="intro" style={{ display: "none", color: "red" }}>
                  Please fill in this field!
                </p>
              </Form.Group>
              <Form.Group controlId="meta_syllabus">
                <Form.Label
                  className="float-left"
                  style={{ marginLeft: "5px", marginTop: "5px" }}
                >
                  Syllabus
                </Form.Label>
                <br />
                <br />
                <CKEditor
                  editor={ClassicEditor}
                  data={exam.meta_syllabus}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setExam({
                      ...exam,
                      "meta_syllabus": data,
                    });
                  }}
                  onBlur={(event, editor) => {
                    const data = editor.getData();
                    setExam({
                      ...exam,
                      "meta_syllabus": data.trim(),
                    });
                  }}
                />
              </Form.Group>
              <Form.Group controlId="meta_pattern">
                <Form.Label
                  className="float-left"
                  style={{ marginLeft: "5px", marginTop: "5px" }}
                >
                  Exam Pattern
                </Form.Label>
                <br />
                <br />
                <CKEditor
                  editor={ClassicEditor}
                  data={exam.meta_pattern}
                  onChange={(event, editor) => {
                    const data = editor.getData();

                    setExam({
                      ...exam,
                      "meta_pattern": data,
                    });
                  }}
                  onBlur={(event, editor) => {
                    const data = editor.getData();
                    setExam({
                      ...exam,
                      "meta_pattern": data.trim(),
                    });
                  }}
                />
              </Form.Group>
              <Form.Group controlId="meta_dates">
                <Form.Label
                  className="float-left"
                  style={{ marginLeft: "5px", marginTop: "5px" }}
                >
                  Important Dates
                </Form.Label>
                <br />
                <br />
                <CKEditor
                  editor={ClassicEditor}
                  data={exam.meta_dates}
                  onChange={(event, editor) => {
                    const data = editor.getData();

                    setExam({
                      ...exam,
                      "meta_dates": data,
                    });
                  }}
                  onBlur={(event, editor) => {
                    const data = editor.getData();
                    setExam({
                      ...exam,
                      "meta_dates": data.trim(),
                    });
                  }}
                />
              </Form.Group>
              <Form.Group controlId="meta_faq">
                <Form.Label
                  className="float-left"
                  style={{ marginLeft: "5px", marginTop: "5px" }}
                >
                  FAQ
                </Form.Label>
                <br />
                <br />
                <CKEditor
                  editor={ClassicEditor}
                  data={exam.meta_faq}
                  onChange={(event, editor) => {
                    const data = editor.getData();

                    setExam({
                      ...exam,
                      "meta_faq": data,
                    });
                  }}
                  onBlur={(event, editor) => {
                    const data = editor.getData();
                    setExam({
                      ...exam,
                      "meta_faq": data.trim(),
                    });
                  }}
                />
              </Form.Group>
            </>
          ) : (
            ""
          )}
        </Card.Body>
        <Card.Footer>
          <Button
            type="button"
            onClick={goBack}
            className="float-left"
            style={{ color: "white" }}
          >
            Back
          </Button>
          &nbsp;
          <Button variant="primary" type="submit">
            {" "}
            Update{" "}
          </Button>
        </Card.Footer>
      </Form>
    </Card>
  );
}
export default Exam;
