import React from "react";
import { Card, Button, ButtonGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useHistory } from "react-router-dom";
import {
  faEdit,
  faArrowCircleUp,
  faArrowCircleDown,
  faCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { put, deleteRequest } from "../../../helpers/RequestHelper";
function ExamCard({ exam, last, getExamData }) {
  let history = useHistory();

  async function toggleStatus() {
    try {
      await deleteRequest("/api/admin/exam/" + exam._id);
      getExamData();
    } catch (err) {}
  }

  const packageCollectionPage = () => {
    history.push(`/admin/exam/${exam._id}/package-collection`);
  };

  async function moveUp() {
    await put("/api/admin/exam/" + exam._id + "/moveup", { _id: exam._id });
    getExamData();
  }

  async function moveDown() {
    await put("/api/admin/exam/" + exam._id + "/movedown", { _id: exam._id });
    getExamData();
  }

  return (
    <Card bg="light" text="dark" className="mb-2">
      <Card.Header className={exam.status === 1 ? "" : "bg-danger"}>
        <div className="float-left">
          <h5>{exam.name}</h5>
        </div>
        <ButtonGroup className="float-right" aria-label="Basic example">
          {exam.order > 1 ? (
            <Button variant="link">
              <FontAwesomeIcon
                icon={faArrowCircleUp}
                color="green"
                onClick={moveUp}
                title="Move Up"
              />
            </Button>
          ) : (
            ""
          )}
          {last ? (
            ""
          ) : (
            <Button variant="link">
              <FontAwesomeIcon
                icon={faArrowCircleDown}
                color="blue"
                onClick={moveDown}
                title="Move Down"
              />
            </Button>
          )}

          <Link to={`/admin/exam/${exam._id}/collection`}>
            <Button variant="link">Manage Test Collections</Button>
          </Link>
          <Button variant="link" onClick={packageCollectionPage}>
            Manage Package Collections
          </Button>
          <Link to={`/admin/exam/${exam._id}/edit`}>
            <Button variant="link" title="Edit">
              <FontAwesomeIcon icon={faEdit} color="black" />
            </Button>
          </Link>
          {exam.status === 1 ? (
            <Button
              variant="link"
              onClick={() => toggleStatus()}
              title="Disable"
            >
              <FontAwesomeIcon icon={faTimes} color="red" />
            </Button>
          ) : (
            <Button
              variant="link"
              onClick={() => toggleStatus()}
              title="Enable"
            >
              <FontAwesomeIcon icon={faCheck} color="blue" />
            </Button>
          )}
        </ButtonGroup>
      </Card.Header>
      <Card.Body>
        <p>
          <strong>Description</strong>
        </p>
        <p dangerouslySetInnerHTML={{ __html: exam.description }}></p>
      </Card.Body>
    </Card>
  );
}
export default ExamCard;