import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Container } from "react-bootstrap";
import Navigation from "./components/nav/NavigationBar";
import routes from "./routes";
import publicRoutes from "./routes/public";
import Home from "./pages/home/Home";
import { userContext } from "./userContext";
import { get } from "./helpers/RequestHelper";
import { PrivateRoute } from "./components/route/PrivateRoute";
import Loader from "./components/loader/FullPageLoader";
import "./App.css";
import axios from 'axios';

const UNAUTHORIZED = 401;

var sessionExpTimer = false;

function App() {

  const [user, setUser] = useState(null);
  const [isDataFirstLoaded, setDataFirstLoaded] = useState(false);

  
  useEffect(() => {
    getUserData();
  }, []);

  async function getUserData() {
    try {
      const userData = await get("/api/loggedin");
      setUser(userData);
    } catch (err) {
      setUser(null);
    } finally {
      setDataFirstLoaded(true);
    }
  }

  async function logoutUser() {
    try {
      await get("/api/logout");
      setUser(null);
    } catch (err) {
      //setUser(null);
    }
  }

  function sessionExp() {

    if( sessionExpTimer ){
      
      clearTimeout( sessionExpTimer );
      sessionExpTimer = false;
    }

    sessionExpTimer = setTimeout(()=> {
      if( user ) {

        setUser(null)
      }
      alert("Your session has been expired.");
    }, 500 );
  }

  axios.interceptors.response.use(
    response => response,
    error => {
      const {status} = error.response;
      if (status === UNAUTHORIZED) {
        sessionExp();
      }
      return Promise.reject(error);
   }
  );

  if (!isDataFirstLoaded) {
    return <Loader />;
  }

  const allRoutes = routes.map((route, index) => {
    const { path, component: componentRef, category } = route;
    return (
      <PrivateRoute path={path} key={index} exact component={componentRef} category={category} />
    );
  });

  return (
    <userContext.Provider value={{ user, logoutUser }}>
      <Router>
        <div className="App">
          <Navigation />
          <Container fluid className="p-5">
            <Switch>
              {publicRoutes.map((route, index) => (
                <Route
                  path={route.path}
                  key={index}
                  exact
                  component={route.component}
                />
              ))}
              {allRoutes}
              <PrivateRoute path="*" exact component={Home} />
            </Switch>
          </Container>
        </div>
      </Router>
    </userContext.Provider>
  );
}

export default App;
