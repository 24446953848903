import Auth from '../pages/auth/Login';

const auth = [{

    path: "/admin/login",
    title: "Login",
    component: Auth,
    props: {}
}]

export default auth;