import React, { useEffect, useState } from "react";
import { Container, Card, Button, Form } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { get, put } from "../../helpers/RequestHelper";

function Subject() {
  let history = useHistory();

  const [chapter, setChapter] = useState(null);
  let { subjectId, chapterId } = useParams();

  useEffect(() => {
    getChapterData(subjectId, chapterId);
  }, [chapterId, subjectId]);

  function handleChange(event) {
    setChapter({
      ...chapter,
      [event.target.id]: event.target.value,
    });
  }

  function handleBlur(event) {
    setChapter({
      ...chapter,
      [event.target.id]: ( event.target.value ).trim(),
    });
  }

  function goBack() {
    history.push(`/admin/subject/${subjectId}/chapter`);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      await put(
        `/api/admin/subject/${subjectId}/chapter/${chapterId}`,
        chapter
      );
      goBack();
    } catch (err) {}
  }

  async function getChapterData(subjectId, chapterId) {
    try {
      const chapterData = await get(
        "/api/admin/subject/" + subjectId + "/chapter/" + chapterId
      );

      setChapter(chapterData);
    } catch (err) {
      setChapter(null);
    }
  }

  return (
    <>
      <Container fluid="md">
        <Card style={{ marginTop: "50px" }}>
          <Card.Header>
            <h4 className="float-left">Update Chapter </h4>
          </Card.Header>
          <Form onSubmit={handleSubmit}>
            <Card.Body>
              {chapter ? (
                <>
                  <Form.Group controlId="name" onChange={handleChange} onBlur={handleBlur}>
                    <Form.Label
                      className="float-left"
                      style={{ marginLeft: "5px", marginTop: "5px" }}
                    >
                      Name
                    </Form.Label>
                    <Form.Control
                      as="input"
                      defaultValue={chapter.name}
                      placeholder="Name"
                      required
                      value={chapter.name}
                    />
                  </Form.Group>
                  <Form.Group controlId="description" onChange={handleChange} onBlur={handleBlur}>
                    <Form.Label
                      className="float-left"
                      style={{ marginLeft: "5px", marginTop: "20px" }}
                    >
                      Description
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      defaultValue={chapter.description}
                      rows="3"
                      placeholder="Description"
                      required
                      value={chapter.description}
                    />
                  </Form.Group>
                </>
              ) : (
                ""
              )}
            </Card.Body>
            <Card.Footer>
              <Button
                type="button"
                onClick={goBack}
                className="float-left"
                style={{ color: "white" }}
              >
                Back
              </Button>
              &nbsp;
              <Button variant="primary" type="submit">
                {" "}
                Update{" "}
              </Button>
            </Card.Footer>
          </Form>
        </Card>
      </Container>
    </>
  );
}
export default Subject;
