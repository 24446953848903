import React from "react";

function Exam() {
  return (
    <div>
      <h3>Exams Page</h3>
    </div>
  );
}
export default Exam;
