import ListTestPage from "../pages/test/ListTestPage/ListTestPage";
import CreateTestPage from "../pages/test/CreateTestPage.js";
import EditTestPage from "../pages/test/EditTestPage.js";
import ListTestQuestionPage from "../pages/test/ListTestQuestionPage.js";
import AddQuestionInTest from "../pages/test/AddQuestionInTest.js";

const test = [
  {
    path: '/admin/test',
    title: 'List test Page',
    props: {},
    component: ListTestPage
  },
  {
    path: '/admin/test/create',
    title: 'Create test Page',
    props: {},
    component: CreateTestPage
  },
  {
    path: '/admin/test/:testId/edit',
    title: 'Edit test Page',
    props: {},
    component: EditTestPage
  },
  {
    path: '/admin/test/:testId/question',
    title: 'List test question Page',
    props: {},
    component: ListTestQuestionPage
  },
  {
    path: '/admin/test/:testId/question/add',
    title: 'Add question in test Page',
    props: {},
    component: AddQuestionInTest
  },
  {
    path: '/admin/test/:testId/question/add/:sectionId',
    title: 'Add question in test section Page',
    props: {},
    component: AddQuestionInTest
  }
]

export default test;