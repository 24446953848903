import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Form } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { get, put } from "../../../helpers/RequestHelper";
// import { dynamicSort } from "../../../helpers/UtilityHelper";

import queryString from "query-string";
import Select from "react-select";

import Sidebar from "./Sidebar";

function Step6() {
  const history = useHistory();
  let { questionId } = useParams();

  const [validated, setValidated] = useState(false);
  const [questionData, setQuestionData] = useState({});
  const [associatedTests, setAssociatedTests] = useState([]);
  const [availableTests, setAvailableTests] = useState([]);
  const [formData, setFormData] = useState({});
  const [selectedTest, setSelectedTest] = useState(null);

  useEffect(() => {
    (async function () {
      setValidated(false);
      try {
        const question = await get(`/api/admin/question/${questionId}`);
        setQuestionData(question);
        // get test
        const { tests } = await get(
          `/api/admin/test/all?question_id=${questionId}`
        );
        setAssociatedTests(tests);

        const query = {
          exam_ids: question.exam_ids,
          tests_not_with_question_id: questionId,
        };

        const stringified = queryString.stringify(query);
        const availableTestdata = await get(
          `/api/admin/test/list?${stringified}`
        );
        //availableTestdata.sort(dynamicSort("name"));
        setAvailableTests(availableTestdata);
      } catch (err) {}
    })();
  }, [questionId]);

  useEffect(() => {
    if (!formData.selected_test) {
      setSelectedTest(null);
      return;
    }
    const test = availableTests.find((test) => {
      return test._id.toString() === formData.selected_test;
    });

    setSelectedTest(test);
    setFormData({
      selected_test: formData.selected_test,
      selected_test_section: "",
    });
  }, [formData.selected_test, availableTests]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    if (form.checkValidity() === false) {
      return;
    }
    if (!isQuestionValid()) {
      alert(
        "Invalid Question, Please check for question, options and atleast one correct choice marked."
      );
      return;
    }

    (async () => {
      try {
        await put(`/api/admin/question/${questionId}/enable`);
        await addQuestionInTest({ ...questionData });
        history.push(`/admin/question`);
      } catch (err) {
        alert(err && err.message ? err.message : "Something went wrong");
      }
    })();
  };

  function isQuestionValid() {
    var isValid = true;

    if (!questionData.question) {
      isValid = false;
    }

    if (!questionData.answer_choices.length) {
      isValid = false;
    }
    if (
      questionData.answer_choices.length &&
      questionData.answer_choices.length !== questionData.answer_choice_count
    ) {
      isValid = false;
    }

    if (
      questionData.correct_answers.length !== questionData.answer_choices.length
    ) {
      isValid = false;
    }

    if (!questionData.correct_answers.includes(1)) {
      isValid = false;
    }

    return isValid;
  }

  function isInTest(question, test) {
    for (let idx in test.questions) {
      if (
        test.questions[idx].question.hasOwnProperty("_id") &&
        test.questions[idx].question._id.toString() === question._id.toString()
      ) {
        return true;
      } else if (
        test.questions[idx].question.toString() === question._id.toString()
      ) {
        return true;
      }
    }
    return false;
  }

  async function addQuestionInTest(question) {
    const testSection =
      selectedTest && selectedTest.sections && selectedTest.sections.length
        ? selectedTest.sections.find((section) => {
            return section._id.toString() === formData.selected_test_section;
          })
        : null;

    if (!isInTest(question, selectedTest)) {
      const test = await get(`/api/admin/test/${selectedTest._id}`);
      const newTest = {
        ...test,
      };

      var next_order = 1;
      newTest.questions.forEach((itr_question) => {
        if (testSection && testSection._id === itr_question.section) {
          if (itr_question.order >= next_order) {
            next_order = itr_question.order + 1;
          }
        } else if (!itr_question.section) {
          if (itr_question.order >= next_order) {
            next_order = itr_question.order + 1;
          }
        }
      });

      newTest.questions.push({
        question: question,
        section: testSection,
        order: next_order,
      });

      await put(`/api/admin/test/${newTest._id}`, newTest);
    }
  }

  return (
    <Row>
      <Col md="3">
        <Sidebar
          currentStep={6}
          answerCount={questionData.answer_choice_count}
          questionId={questionData._id}
          optionNumber={null}
        />
      </Col>
      <Col md="9">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Card border="dark">
            <Card.Header>
              Add Question in Test - Step 6
              <div className="float-right">
                {questionData._id ? (
                  <Link to={"/admin/question/edit/steps/step5/" + questionId}>
                    <Button variant="secondary">Back</Button>
                  </Link>
                ) : (
                  ""
                )}{" "}
                <Button variant="primary" type="submit">
                  Save &amp; Finish
                </Button>
              </div>
            </Card.Header>
            {questionData._id ? (
              <Card.Body>
                {associatedTests.length ? (
                  <div style={{ paddingBottom: "inherit" }}>
                    <strong>Associated with tests: </strong>
                    <ol>
                      {associatedTests.map((test) => (
                        <li key={test._id}>
                          <a
                            href={`/admin/test/${test._id}/question`}
                            // eslint-disable-next-line react/jsx-no-target-blank
                            target="_blank"
                          >
                            {test.name}({test._id})
                          </a>
                        </li>
                      ))}
                    </ol>
                  </div>
                ) : (
                  <div style={{ paddingBottom: "inherit" }}>
                    <strong>
                      This question is not associated with any test.
                    </strong>
                  </div>
                )}
                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="selected_test">
                    <Form.Label>Select test</Form.Label>
                    <Select
                      value={(() => {
                        const test = availableTests.find((test) => {
                          return test._id === formData.selected_test;
                        });

                        if (!test) {
                          return null;
                        }
                        return {
                          value: test._id,
                          label: test.name,
                        };
                      })()}
                      options={(() => {
                        let options = [{ value: "", label: "Select a test" }];
                        availableTests.forEach((test) => {
                          options.push({
                            value: test._id,
                            label: test.name,
                          });
                        });
                        return options;
                      })()}
                      onChange={(selectedOption) => {
                        setFormData({
                          ...formData,
                          selected_test: selectedOption.value,
                        });
                      }}
                    />
                    <Form.Control
                      className="text-capitalize d-none"
                      required
                      as="select"
                      placeholder="Select test"
                      value={formData.selected_test}
                      onChange={(e) => {}}
                    >
                      <option value="">Select test</option>
                      {availableTests.map((test) => (
                        <option key={test._id} value={test._id}>
                          {test.name}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Please select test
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                {selectedTest &&
                selectedTest.sections &&
                selectedTest.sections.length ? (
                  <Form.Row>
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="selected_test_section"
                    >
                      <Form.Label>Select test section</Form.Label>
                      <Form.Control
                        className="text-capitalize"
                        required
                        as="select"
                        placeholder="Please select test section"
                        value={formData.selected_test_section}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            selected_test_section: e.target.value,
                          });
                        }}
                      >
                        <option value="">Select test section</option>
                        {selectedTest.sections.map((section) => (
                          <option key={section._id} value={section._id}>
                            {section.name}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please select test section
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                ) : (
                  ""
                )}
              </Card.Body>
            ) : (
              ""
            )}
            <Card.Footer>
              <div className="float-right">
                {questionData._id ? (
                  <Link to={"/admin/question/edit/steps/step5/" + questionId}>
                    <Button variant="secondary">Back</Button>
                  </Link>
                ) : (
                  ""
                )}{" "}
                <Button variant="primary" type="submit">
                  Save &amp; Finish
                </Button>
              </div>
            </Card.Footer>
          </Card>
        </Form>
      </Col>
    </Row>
  );
}
export default Step6;
