import ListUserPage from "../pages/user/ListUserPage.js";
import EditUserRolePage from "../pages/user/EditUserRolePage.js";
const user = [
  {
    path: '/admin/user',
    title: 'List User Page',
    props: {},
    component: ListUserPage
  },
  {
    path: '/admin/user/:userId/updaterole',
    title: 'Edit User Role Page',
    props: {},
    component: EditUserRolePage
  }
]

export default user;