import ListExamCategoryPage from "../pages/exam/ListExamCategoryPage";
import CreateExamCategoryPage from "../pages/exam/CreateExamCategoryPage";
import EditExamCategoryPage from "../pages/exam/EditExamCategoryPage";
import ListExamPage from "../pages/exam/ListExamPage/ListExamPage";
import CreateExamPage from "../pages/exam/CreateExamPage";
import EditExamPage from "../pages/exam/EditExamPage";
import ManageExamCollectionPage from "../pages/exam/ManageExamCollectionPage";
import CreateExamCollectionPage from "../pages/exam/CreateExamCollectionPage";
import EditExamCollectionPage from "../pages/exam/EditExamCollectionPage";
import ManageTestInExamCollectionPage from "../pages/exam/ManageTestInExamCollectionPage";
import AddTestInExamCollectionPage from "../pages/exam/AddTestInExamCollectionPage";
import ManagePackageCollectionPage from "../pages/exam/ManagePackageCollectionPage";
import CreatePackageCollectionPage from "../pages/exam/CreatePackageCollectionPage";
import EditPackageCollectionPage from "../pages/exam/EditPackageCollectionPage";
import ManagePackageInPackageCollectionPage from "../pages/exam/ManagePackageInPackageCollectionPage";
import AddPackageInPackageCollectionPage from "../pages/exam/AddPackageInPackageCollectionPage";

const exam = [
  {
    path: "/admin/examcategory",
    title: "List ExamCategory Page",
    component: ListExamCategoryPage,
    //category: "EXAM_CATEGORY.LIST",
    props: {},
  },
  {
    path: "/admin/examcategory/create",
    title: "Create Exam Category Page",
    component: CreateExamCategoryPage,
    props: {},
  },
  {
    path: "/admin/examcategory/:categoryId/edit",
    title: "Edit Exam Category Page",
    component: EditExamCategoryPage,
    props: {},
  },
  {
    path: "/admin/exam",
    title: "List Exam Page",
    component: ListExamPage,
    category: "EXAM.LIST",
    props: {},
  },
  {
    path: "/admin/exam/create",
    title: "Create Exam Page",
    component: CreateExamPage,
    props: {},
  },
  {
    path: "/admin/exam/:examId/edit",
    title: "Edit Exam Page",
    component: EditExamPage,
    props: {},
  },
  {
    path: "/admin/exam/:examId/collection",
    title: "Manage Exam Collection Page",
    component: ManageExamCollectionPage,
    props: {},
  },
  {
    path: "/admin/exam/:examId/collection/create",
    title: "Create Exam Collection Page",
    component: CreateExamCollectionPage,
    props: {},
  },
  {
    path: "/admin/exam/:examId/collection/:collectionId/edit",
    title: "Edit Exam Collection Page",
    component: EditExamCollectionPage,
    props: {},
  },
  {
    path: "/admin/exam/:examId/collection/:collectionId/managetest",
    title: "Manage Test In Exam Collection Page",
    component: ManageTestInExamCollectionPage,
    props: {},
  },
  {
    path: "/admin/exam/:examId/collection/:collectionId/addtest",
    title: "Add Test In Exam Collection Page",
    component: AddTestInExamCollectionPage,
    props: {},
  },
  {
    path: "/admin/exam/:examId/package-collection",
    title: "Manage Package Collection Page",
    component: ManagePackageCollectionPage,
    props: {},
  },
  {
    path: "/admin/exam/:examId/package-collection/create",
    title: "Create Package Collection Page",
    component: CreatePackageCollectionPage,
    props: {},
  },
  {
    path: "/admin/exam/:examId/package-collection/:packageCollectionId/edit",
    title: "Edit Package Collection Page",
    component: EditPackageCollectionPage,
    props: {},
  },
  {
    path:
      "/admin/exam/:examId/package-collection/:packageCollectionId/managepackage",
    title: "Manage Package In Package Collection Page",
    component: ManagePackageInPackageCollectionPage,
    props: {},
  },
  {
    path:
      "/admin/exam/:examId/package-collection/:packageCollectionId/addpackage",
    title: "Add Package In Package Collection Page",
    component: AddPackageInPackageCollectionPage,
    props: {},
  },
];

export default exam;
