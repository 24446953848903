import React, { useEffect, useState } from "react";
import { Card, Button, Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleUp,
  faArrowCircleDown,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import { get, put } from "./../../helpers/RequestHelper";

const getCharacterCode = function (value) {
  return String.fromCharCode(value);
};

function ListTestQuestions() {
  const { testId } = useParams();

  const [testData, setTestData] = useState({});
  const [sectionQuestions, setSectionQuestions] = useState({});
  const [testQuestions, setTestQuestions] = useState([]);

  useEffect(() => {
    (async () => {
      const test = await get(`/api/admin/test/${testId}`);
      setTestData(test);
      updateSectionQuestions(test);
    })();
  }, [testId]);

  useEffect(() => {
    if (!testQuestions.length && !Object.keys(sectionQuestions).length) {
      return;
    }
    // eslint-disable-next-line no-undef
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
  }, [sectionQuestions, testQuestions]);

  async function moveQuestion(question, order_to_move, question_section) {
    if (!order_to_move) {
      return;
    }
    try {
      const test = await put("/api/admin/test/question/movequestion", {
        id: testId,
        question: question,
        section: question_section ? question_section : null,
        order_to_move: order_to_move,
      });

      setTestData(test);
      updateSectionQuestions(test);
    } catch (err) {
      alert("Error while moving question");
    } finally {
      document.getElementsByClassName("move-question").forEach((elm) => {
        elm.value = "";
      });
    }
  }

  async function changeQuestionSection(question, section_id) {
    if (!section_id) {
      return;
    }
    try {
      const test = await put("/api/admin/test/question/changesection", {
        id: testId,
        question_id: question._id,
        section_id: section_id,
      });

      setTestData(test);
      updateSectionQuestions(test);
    } catch (err) {
      alert("Error while moving change section");
    } finally {
      document.getElementsByClassName("change-sec").forEach((elm) => {
        elm.value = "";
      });
    }
  }

  async function moveQuestionUp(
    test_question,
    current_question_order,
    test_section
  ) {
    try {
      const test = await put("/api/admin/test/question/moveup", {
        id: testId,
        question: test_question,
        section: test_section ? test_section : null,
      });

      setTestData(test);
      updateSectionQuestions(test);
    } catch (err) {
      alert("Error while moving up");
    }
  }

  async function moveQuestionDown(
    test_question,
    current_question_order,
    test_section
  ) {
    try {
      const test = await put("/api/admin/test/question/movedown", {
        id: testId,
        question: test_question,
        section: test_section ? test_section : null,
      });

      setTestData(test);
      updateSectionQuestions(test);
    } catch (err) {
      alert("Error while moving down");
    }
  }

  async function toggleQuestion(test_question) {
    try {
      const test = await put("/api/admin/test/question/toggle", {
        id: testId,
        question: test_question,
        section: null,
      });

      setTestData(test);
      updateSectionQuestions(test);
    } catch (err) {
      if (err && err.message) {
        alert(err.message);
        return;
      }
      alert("Error while removing question");
    }
  }

  function updateSectionQuestions(testData) {
    const section_questions = {};
    const test_questions = [];

    testData.questions.forEach(function (question) {
      if (question.section) {
        if (!section_questions.hasOwnProperty(question.section)) {
          section_questions[question.section] = [];
        }

        section_questions[question.section].push(question);
      } else {
        test_questions.push(question);
      }
    });

    setSectionQuestions(section_questions);
    setTestQuestions(test_questions);
  }

  if (!testData._id) {
    return <Card>Loading test...</Card>;
  }

  return (
    <Card>
      <Card.Header>
        <h4 className="float-left">Detail of - {testData.name}</h4>
        <div className="float-right">
          <Link to={"/admin/test"}>
            <Button variant="secondary">Back</Button>
          </Link>{" "}
        </div>
      </Card.Header>
      <Card.Body>
        <Card className="mb-3">
          <Card.Header>Description</Card.Header>
          <Card.Body
            style={{ whiteSpace: "pre-wrap" }}
            dangerouslySetInnerHTML={{ __html: testData.description }}
          ></Card.Body>
        </Card>
        <Card className="mb-3">
          <Card.Header>Instructions</Card.Header>
          <Card.Body
            style={{ whiteSpace: "pre-wrap" }}
            dangerouslySetInnerHTML={{ __html: testData.instructions }}
          ></Card.Body>
        </Card>
        <Card className="mb-3">
          <Card.Header>
            <strong>Exam:</strong> {testData.exam.name}
          </Card.Header>
        </Card>

        <Card className="mb-3">
          <Card.Header>
            <Row>
              <Col md="4">
                <strong>Total Questions:</strong> {testData.question_count}
                <span>
                  (Pending:{" "}
                  {testData.question_count - testData.questions.length})
                </span>
              </Col>
              <Col md="4">
                <strong>Marks Per Question:</strong>{" "}
                {testData.marks_per_question}
              </Col>
              <Col md="4">
                <strong>Negative Marks Per Question:</strong>{" "}
                {testData.negative_marks_per_question}
              </Col>
            </Row>
          </Card.Header>
        </Card>

        <Card className="mb-3">
          <Card.Header>
            <Row>
              <Col md="4">
                <strong>Duration (in Minutes)</strong> {testData.duration}
              </Col>
              <Col md="4">
                <strong>Difficulty Level:</strong> {testData.difficulty_level}
              </Col>
              <Col md="4">
                <strong>Price:</strong> {testData.price}
              </Col>
            </Row>
          </Card.Header>
        </Card>

        {testData.sections.length ? (
          <fieldset>
            <legend>Sections</legend>

            {testData.sections.map((section) => (
              <Card className="mb-3" key={section._id}>
                <Card.Header>
                  <div className="float-left">
                    <strong>{section.name}</strong>
                  </div>
                  <div className="float-right">
                    <Link
                      to={`/admin/test/${testData._id}/question/add/${section._id}`}
                    >
                      <Button variant="primary">Add Question(s)</Button>
                    </Link>{" "}
                  </div>
                </Card.Header>
                {sectionQuestions[section._id] ? (
                  <Card.Body>
                    <Card className="clearfix">
                      <Card.Header>
                        <Row>
                          <Col md="6">
                            <strong>Duration (in minutes):</strong>{" "}
                            {section.duration}
                          </Col>
                          <Col md="6">
                            <strong>Total Questions:</strong>{" "}
                            {section.question_count}
                            <span>
                              (Pending:{" "}
                              {section.question_count -
                                sectionQuestions[section._id].length}
                              )
                            </span>
                          </Col>
                        </Row>
                      </Card.Header>
                    </Card>
                    <fieldset>
                      <legend>Questions</legend>
                      {sectionQuestions[section._id].map(
                        (questionObj, index) => {
                          if (questionObj.section !== section._id) {
                            return "";
                          }
                          const initVal = "";
                          return (
                            <Row key={`sec-${section._id}-${index}`}>
                              <Card className="m-2 w-100">
                                <Card.Header>
                                  <Row>
                                    <Col md="1">
                                      <strong>Q. {index + 1}:</strong>
                                    </Col>
                                    <Col
                                      md="8"
                                      dangerouslySetInnerHTML={{
                                        __html: questionObj.question.question,
                                      }}
                                    ></Col>
                                    <Col md="3">
                                      <div className="pull-right">
                                        <div>
                                          <select
                                            className="form-control move-question"
                                            defaultValue={initVal}
                                            onChange={(e) => {
                                              moveQuestion(
                                                questionObj.question,
                                                e.target.value,
                                                section
                                              );
                                            }}
                                          >
                                            <option value="">
                                              Move Question to
                                            </option>
                                            {sectionQuestions[section._id].map(
                                              (moveQuestion, index) => {
                                                if (
                                                  moveQuestion.order ===
                                                  questionObj.order
                                                ) {
                                                  return "";
                                                }
                                                return (
                                                  <option
                                                    value={moveQuestion.order}
                                                    key={moveQuestion.order}
                                                  >
                                                    Q. {index + 1}
                                                  </option>
                                                );
                                              }
                                            )}
                                          </select>
                                        </div>
                                        <div>
                                          <select
                                            className="form-control change-sec"
                                            defaultValue={initVal}
                                            onChange={(e) => {
                                              changeQuestionSection(
                                                questionObj.question,
                                                e.target.value
                                              );
                                            }}
                                          >
                                            <option value="">
                                              Move question to section
                                            </option>
                                            {testData.sections.map((sec) => {
                                              if (sec._id === section._id) {
                                                return "";
                                              }
                                              return (
                                                <option
                                                  value={sec._id}
                                                  key={sec._id}
                                                >
                                                  {sec.name}
                                                </option>
                                              );
                                            })}
                                          </select>
                                        </div>
                                        <div>
                                          {index ? (
                                            <Button
                                              variant="link"
                                              onClick={() => {
                                                moveQuestionUp(
                                                  questionObj.question,
                                                  questionObj.order,
                                                  section
                                                );
                                              }}
                                            >
                                              <FontAwesomeIcon
                                                icon={faArrowCircleUp}
                                                color="green"
                                                title="Move Up"
                                              />
                                            </Button>
                                          ) : (
                                            ""
                                          )}

                                          {sectionQuestions[section._id]
                                            .length ===
                                          index + 1 ? (
                                            ""
                                          ) : (
                                            <Button
                                              variant="link"
                                              onClick={() => {
                                                moveQuestionDown(
                                                  questionObj.question,
                                                  questionObj.order,
                                                  section
                                                );
                                              }}
                                            >
                                              <FontAwesomeIcon
                                                icon={faArrowCircleDown}
                                                color="blue"
                                                title="Move Down"
                                              />
                                            </Button>
                                          )}
                                          <Button
                                            variant="link"
                                            title="Remove from test"
                                            onClick={() => {
                                              toggleQuestion(
                                                questionObj.question
                                              );
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={faTimes}
                                              color="red"
                                            />
                                          </Button>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </Card.Header>
                                <Card.Body>
                                  <Row>
                                    {questionObj.question.answer_choices.map(
                                      (answer_choice, ans_index) => (
                                        <Col md="6" key={ans_index}>
                                          <Row>
                                            <Col md="2">
                                              (
                                              {getCharacterCode(65 + ans_index)}
                                              )
                                            </Col>
                                            <Col
                                              md="10"
                                              dangerouslySetInnerHTML={{
                                                __html: answer_choice,
                                              }}
                                            ></Col>
                                          </Row>
                                        </Col>
                                      )
                                    )}
                                  </Row>
                                </Card.Body>
                              </Card>
                            </Row>
                          );
                        }
                      )}
                    </fieldset>
                  </Card.Body>
                ) : (
                  ""
                )}
              </Card>
            ))}
          </fieldset>
        ) : (
          ""
        )}

        <fieldset>
          <Card className="mb-3">
            <Card.Header>
              <div className="float-left">
                <strong>Test Questions</strong>
              </div>
              <div className="float-right">
                <Link to={`/admin/test/${testData._id}/question/add`}>
                  <Button variant="primary">Add More</Button>
                </Link>{" "}
              </div>
            </Card.Header>

            <Card.Body>
              {testQuestions.map((questionObj, index) => {
                if (questionObj.section) {
                  return "";
                }
                const initVal = "";
                return (
                  <Row>
                    <Card className="m-2 w-100">
                      <Card.Header>
                        <Row>
                          <Col md="1">
                            <strong>Q. {index + 1}:</strong>
                          </Col>
                          <Col
                            md="8"
                            dangerouslySetInnerHTML={{
                              __html: questionObj.question.question,
                            }}
                          ></Col>
                          <Col md="3">
                            <div className="pull-right">
                              <div>
                                <select
                                  className="form-control move-question"
                                  defaultValue={initVal}
                                  onChange={(e) => {
                                    moveQuestion(
                                      questionObj.question,
                                      e.target.value
                                    );
                                  }}
                                >
                                  <option value="">Move Question to</option>
                                  {testQuestions.map((moveQuestion, index) => {
                                    if (
                                      moveQuestion.order === questionObj.order
                                    ) {
                                      return "";
                                    }
                                    return (
                                      <option value={moveQuestion.order}>
                                        Q. {index + 1}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>

                              {testData.sections.length ? (
                                <div>
                                  <select
                                    className="form-control change-sec"
                                    defaultValue={initVal}
                                    onChange={(e) => {
                                      changeQuestionSection(
                                        questionObj.question,
                                        e.target.value
                                      );
                                    }}
                                  >
                                    <option value="">
                                      Move question to section
                                    </option>
                                    {testData.sections.map((sec) => {
                                      return (
                                        <option value={sec._id} key={sec._id}>
                                          {sec.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              ) : (
                                ""
                              )}
                              <div>
                                {index ? (
                                  <Button
                                    variant="link"
                                    onClick={() => {
                                      moveQuestionUp(
                                        questionObj.question,
                                        questionObj.order
                                      );
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faArrowCircleUp}
                                      color="green"
                                      title="Move Up"
                                    />
                                  </Button>
                                ) : (
                                  ""
                                )}

                                {testQuestions.length === index + 1 ? (
                                  ""
                                ) : (
                                  <Button
                                    variant="link"
                                    onClick={() => {
                                      moveQuestionDown(
                                        questionObj.question,
                                        questionObj.order
                                      );
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faArrowCircleDown}
                                      color="blue"
                                      title="Move Down"
                                    />
                                  </Button>
                                )}
                                <Button
                                  variant="link"
                                  onClick={() => {
                                    toggleQuestion(questionObj.question);
                                  }}
                                  title="Remove from test"
                                >
                                  <FontAwesomeIcon icon={faTimes} color="red" />
                                </Button>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          {questionObj.question.answer_choices.map(
                            (answer_choice, ans_index) => (
                              <Col md="6">
                                <Row>
                                  <Col md="2">
                                    ({getCharacterCode(65 + ans_index)})
                                  </Col>
                                  <Col
                                    md="10"
                                    dangerouslySetInnerHTML={{
                                      __html: answer_choice,
                                    }}
                                  ></Col>
                                </Row>
                              </Col>
                            )
                          )}
                        </Row>
                      </Card.Body>
                    </Card>
                  </Row>
                );
              })}
            </Card.Body>
          </Card>
        </fieldset>
      </Card.Body>
    </Card>
  );
}
export default ListTestQuestions;
