import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Form } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { get, put } from "../../../helpers/RequestHelper";

import CKEditor4 from "./../../../components/input/TextEditor";

import Sidebar from "./Sidebar";

function Step2() {
  const history = useHistory();
  let { questionId } = useParams();

  const [validated, setValidated] = useState(false);
  const [questionData, setQuestionData] = useState({});

  useEffect(() => {
    (async function () {
      try {
        const question = await get(`/api/admin/question/${questionId}`);
        setQuestionData(question);
      } catch (err) {}
    })();
  }, [questionId]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    if (form.checkValidity() === false) {
      return;
    }
    const dataToSave = { ...questionData };
    (async () => {
      try {
        await put(`/api/admin/question/${questionId}`, { ...dataToSave });
        history.push(`/admin/question/edit/steps/step3/${questionId}/answer/1`);
      } catch (err) {
        alert(err && err.message ? err.message : "Something went wrong");
      }
    })();
  };

  return (
    <Row>
      <Col md="3">
        <Sidebar
          currentStep={2}
          answerCount={questionData.answer_choice_count}
          questionId={questionData._id}
          optionNumber={null}
        />
      </Col>
      <Col md="9">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Card border="dark">
            <Card.Header>
              Provide Question - Step 2
              <div className="float-right">
                <Link to={`/admin/question/edit/steps/step1/${questionId}`}>
                  <Button variant="secondary">Back</Button>
                </Link>{" "}
                <Button variant="primary" type="submit">
                  Save &amp; Next
                </Button>
              </div>
            </Card.Header>
            {questionData._id ? (
              <Card.Body>
                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="question">
                    <Form.Label>Question</Form.Label>
                    <Form.Control
                      required
                      as="textarea"
                      value={questionData.question || ""}
                      className="d-none"
                      onChange={() => {}}
                    />
                    <CKEditor4
                      data={questionData.question}
                      onChange={(data) => {
                        setQuestionData({
                          ...questionData,
                          question: data,
                        });
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Question is required{" "}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
              </Card.Body>
            ) : (
              ""
            )}
            <Card.Footer>
              <div className="float-right">
                <Link to={`/admin/question/edit/steps/step1/${questionId}`}>
                  <Button variant="secondary">Back</Button>
                </Link>{" "}
                <Button variant="primary" type="submit">
                  Save &amp; Next
                </Button>
              </div>
            </Card.Footer>
          </Card>
        </Form>
      </Col>
    </Row>
  );
}
export default Step2;
