import ListQuestionsPage from "../pages/question/ListQuestionPage/ListQuestionPage";
import QuestionCreateStep1 from "../pages/question/CreateSteps/QuestionCreateStep1.js";
import QuestionEditStep1 from "../pages/question/EditSteps/QuestionEditStep1.js";
import QuestionEditStep2 from "../pages/question/EditSteps/QuestionEditStep2.js";
import QuestionEditStep3 from "../pages/question/EditSteps/QuestionEditStep3.js";
import QuestionEditStep4 from "../pages/question/EditSteps/QuestionEditStep4.js";
import QuestionEditStep5 from "../pages/question/EditSteps/QuestionEditStep5.js";
import QuestionEditStep6 from "../pages/question/EditSteps/QuestionEditStep6.js";
const question = [
  {
    path: '/admin/question',
    title: 'List Questions Page',
    component: ListQuestionsPage,
    props: {}
  },
  {
    path: '/admin/question/create/steps/step1',
    title: 'Question create step1',
    component: QuestionCreateStep1,
    props: {}
  },
  {
    path: '/admin/question/edit/steps/step1/:questionId',
    title: 'Question edit step1',
    component: QuestionEditStep1,
    props: {}
  },
  {
    path: '/admin/question/edit/steps/step2/:questionId',
    title: 'Question edit step2',
    component: QuestionEditStep2,
    props: {}
  },
  {
    path: '/admin/question/edit/steps/step3/:questionId/answer/:answerKey',
    title: 'Question edit step3',
    component: QuestionEditStep3,
    props: {}
  },
  {
    path: '/admin/question/edit/steps/step4/:questionId',
    title: 'Question edit step4',
    component: QuestionEditStep4,
    props: {}
  },
  {
    path: '/admin/question/edit/steps/step5/:questionId',
    title: 'Question edit step5',
    component: QuestionEditStep5,
    props: {}
  },
  {
    path: '/admin/question/edit/steps/step6/:questionId',
    title: 'Question edit step6',
    component: QuestionEditStep6,
    props: {}
  }
]

export default question;