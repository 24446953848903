import moment from 'moment';

export const dynamicSort = function (property) {
    var sortOrder = 1;

    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }

    return function (a, b) {
        if (sortOrder === -1) {
            return b[property].localeCompare(a[property]);
        } else {
            return a[property].localeCompare(b[property]);
        }
    }
}

export const dateToUnixTimestamp = function (date) {

    if (!date) {

        return "";
    }
    // convert date to unix timestamp
    date = moment(new Date(date)).format('X');

    if (isNaN(parseInt(date))) {

        return "";
    }

    return date;
}