import React, { useEffect, useState } from "react";
import { Container, Card, Button, Form } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { get, put } from "../../helpers/RequestHelper";

function Subject() {
  let history = useHistory();

  const [subject, setSubject] = useState(null);
  let { subjectId } = useParams();
  useEffect(() => {
    getSubjectData(subjectId);
  }, [subjectId]);

  function handleChange(event) {
    setSubject({
      ...subject,
      [event.target.id]: event.target.value,
    });
  }
  
  function handleBlur(event) {
    setSubject({
      ...subject,
      [event.target.id]: ( event.target.value ).trim(),
    });
  }
  
  function goBack() {
    history.push("/admin/subject");
  }

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      await put(`/api/admin/subject/${subjectId}`, subject);
      goBack();
    } catch (err) {}
  }

  async function getSubjectData(subjectId) {
    try {
      const subjectData = await get("/api/admin/subject/" + subjectId);

      setSubject(subjectData);
    } catch (err) {
      setSubject(null);
    }
  }

  return (
    <>
      <Container fluid="md">
        <Card style={{ marginTop: "50px" }}>
          <Card.Header>
            <h4 className="float-left">Update Subject</h4>
          </Card.Header>
          <Form onSubmit={handleSubmit}>
            <Card.Body>
              {subject ? (
                <>
                  <Form.Group controlId="name" onChange={handleChange} onBlur={handleBlur}>
                    <Form.Label
                      className="float-left"
                      style={{ marginLeft: "5px", marginTop: "5px" }}
                    >
                      Name
                    </Form.Label>
                    <Form.Control
                      as="input"
                      defaultValue={subject.name}
                      placeholder="Name"
                      required
                      value={subject.name}
                    />
                  </Form.Group>
                  <Form.Group controlId="description" onChange={handleChange} onBlur={handleBlur}>
                    <Form.Label
                      className="float-left"
                      style={{ marginLeft: "5px", marginTop: "20px" }}
                    >
                      Description
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      defaultValue={subject.description}
                      rows="3"
                      placeholder="Description"
                      required
                      value={subject.description}
                    />
                  </Form.Group>
                </>
              ) : (
                ""
              )}
            </Card.Body>
            <Card.Footer>
              <Button
                type="button"
                onClick={goBack}
                className="float-left"
                style={{ color: "white" }}
              >
                Back
              </Button>
              &nbsp;
              <Button variant="primary" type="submit">
                {" "}
                Update{" "}
              </Button>
            </Card.Footer>
          </Form>
        </Card>
      </Container>
    </>
  );
}
export default Subject;
