import React, { useState } from "react";
import { Container, Card, Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { post } from "../../helpers/RequestHelper";
function Subject() {
  let history = useHistory();

  const [subject, setSubject] = useState({name: "", description: ""});
  function handleChange(event) {
    setSubject({
      ...subject,
      [event.target.id]: event.target.value,
    });
  }

  function handleBlur(event) {
    setSubject({
      ...subject,
      [event.target.id]: ( event.target.value ).trim(),
    });
  }

  function goBack() {
    history.push(`/admin/subject`);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      await post(`/api/admin/subject`, subject );
      goBack();
    } catch (err) {}
  }

  return (
    <>
      <Container fluid="md">
        <Card style={{ marginTop: "50px" }}>
          <Card.Header>
            <h4 className="float-left">Create Subject </h4>
          </Card.Header>
          <Form onSubmit={handleSubmit}>
            <Card.Body>
              <>
                <Form.Group
                  controlId="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  
                >
                  <Form.Label
                    className="float-left"
                    style={{ marginLeft: "5px", marginTop: "5px" }}
                  >
                    Name
                  </Form.Label>
                  <Form.Control
                    as="input"
                    type="text"
                    required
                    placeholder="Name" 
                    value={subject.name}
                  />
                </Form.Group>
                <Form.Group
                  controlId="description"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  
                >
                  <Form.Label
                    className="float-left"
                    style={{ marginLeft: "5px", marginTop: "20px" }}
                  >
                    Description
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    type="text"
                    required
                    rows="3"
                    value={subject.description}
                    placeholder="Description"
                  />
                </Form.Group>
              </>
            </Card.Body>
            <Card.Footer>
              <Button
                type="button"
                onClick={goBack}
                className="float-left"
                style={{ color: "white" }}
              >
                Back
              </Button>
              &nbsp;
              <Button variant="primary" type="submit">
                {" "}
                Update{" "}
              </Button>
            </Card.Footer>
          </Form>
        </Card>
      </Container>
    </>
  );
}
export default Subject;
