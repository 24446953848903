import React, { useEffect, useState } from "react";
import { Container, Card, Button, Form } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { get, put } from "../../helpers/RequestHelper";
function Subject() {
  let history = useHistory();

  const [topic, setTopic] = useState(null);
  let { subjectId, chapterId, topicId } = useParams();
  useEffect(() => {
    getTopicData(chapterId, topicId);
  }, [chapterId, topicId]);

  function handleChange(event) {
    setTopic({
      ...topic,
      [event.target.id]: event.target.value,
    });
  }

  function handleBlur(event) {
    setTopic({
      ...topic,
      [event.target.id]: ( event.target.value ).trim(),
    });
  }

  function goBack() {
    history.push(`/admin/subject/${subjectId}/chapter/${chapterId}/topic`);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      await put(`/api/admin/chapter/${chapterId}/topic/${topicId}`, topic);
      goBack();
    } catch (err) {}
  }

  async function getTopicData(chapterId, topicId) {
    try {
      const topicData = await get(
        "/api/admin/chapter/" + chapterId + "/topic/" + topicId
      );

      setTopic(topicData);
    } catch (err) {
      setTopic(null);
    }
  }

  return (
    <>
      <Container fluid="md">
        <Card style={{ marginTop: "50px" }}>
          <Card.Header>
            <h4 className="float-left">Update Chapter </h4>
          </Card.Header>
          <Form onSubmit={handleSubmit}>
            <Card.Body>
              {topic ? (
                <>
                  <Form.Group controlId="name" onChange={handleChange} onBlur={handleBlur}>
                    <Form.Label
                      className="float-left"
                      style={{ marginLeft: "5px", marginTop: "5px" }}
                    >
                      Name
                    </Form.Label>
                    <Form.Control
                      as="input"
                      defaultValue={topic.name}
                      placeholder="Name"
                      required
                      value={topic.name}
                    />
                  </Form.Group>
                  <Form.Group controlId="description" onChange={handleChange} onBlur={handleBlur}>
                    <Form.Label
                      className="float-left"
                      style={{ marginLeft: "5px", marginTop: "20px" }}
                    >
                      Description
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      defaultValue={topic.description}
                      rows="3"
                      placeholder="Description"
                      required
                      value={topic.description}
                    />
                  </Form.Group>
                </>
              ) : (
                ""
              )}
            </Card.Body>
            <Card.Footer>
              <Button
                type="button"
                onClick={goBack}
                className="float-left"
                style={{ color: "white" }}
              >
                Back
              </Button>
              &nbsp;
              <Button variant="primary" type="submit">
                {" "}
                Update{" "}
              </Button>
            </Card.Footer>
          </Form>
        </Card>
      </Container>
    </>
  );
}
export default Subject;
