import React, { useEffect, useState } from "react";
import { Card, Form, Col, Button } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { get, put } from "./../../helpers/RequestHelper";
import axios from "axios";

import CKEditor4 from "./../../components/input/TextEditor";
import { dynamicSort, dateToUnixTimestamp } from "../../helpers/UtilityHelper";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const TEST_VISIBILITY = [
  "public",
  "private",
  "subscriber_only",
  "package_only",
  "national_mock",
];

function UpdateTest() {
  const history = useHistory();
  const { testId } = useParams();

  const [validated, setValidated] = useState(false);
  const [examSlug, setExamSlug] = useState("");
  const [existingTestName, setExistingTestName] = useState("");
  const [testData, setTestData] = useState({
    is_identical: false,
    section_level_timing: false,
  });
  const [exams, setExams] = useState([]);
  const [languagesToSelect, setLanguagesToSelect] = useState([]);
  const [mainTests, setMainTests] = useState([]);
  const [isResetMainTest, setResetMainTest] = useState(false);
  const [examPackages, setExamPackages] = useState([]);

  useEffect(() => {
    (async function getExamData() {
      const test = await get(`/api/admin/test/${testId}`);
      setExistingTestName(test.name);
      setExamSlug(test.exam.slug);
      test.exam = test.exam._id;
      if (test.solution_available_from) {
        test.solution_available_from = new Date(test.solution_available_from);
      }

      if (test.available_to) {
        test.available_to = new Date(test.available_to);
      }

      if (test.available_from) {
        test.available_from = new Date(test.available_from);
      }

      if (
        test.hasOwnProperty("national_mock_meta") &&
        test.national_mock_meta.hasOwnProperty("job_schedule") &&
        test.national_mock_meta.job_schedule.length
      ) {
        test.national_mock_meta.job_schedule = test.national_mock_meta.job_schedule.map(
          (item) => {
            item.schedule_time = new Date(item.schedule_time);
            return item;
          }
        );
      }

      setTestData(test);

      if (test.national_mock_meta.is_paid) {
        await findExamPackages(true, test.exam);
      }

      const examData = await get(`/api/admin/exam/list`);
      examData.sort(dynamicSort("name"));
      setExams(examData);
      const selectedExam = examData.find((exam) => {
        return exam._id.toString() === test.exam;
      });

      setLanguagesToSelect(
        selectedExam && selectedExam.available_languages
          ? selectedExam.available_languages
          : []
      );

      if (test.exam && test.is_identical) {
        const mainTestDataRes = await get(
          `/api/admin/exam/${test.exam}/test/main-tests`
        );
        mainTestDataRes.sort(dynamicSort("name"));
        setMainTests(mainTestDataRes);
      }
    })();
  }, [testId]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);

    if (form.checkValidity() === false) {
      return;
    }
    (async () => {
      try {
        const slugGenerated = URLSlugGeneration(
          testData.name.toLowerCase(),
          "-"
        );

        if (existingTestName && existingTestName !== testData.name) {
          const { tests } = await get(
            `/api/admin/test/all?slug=${slugGenerated}`
          );
          if (tests && tests.length) {
            throw new Error("Test name already exists");
          }
        }

        let testObj = { ...testData };
        testObj.available_from = dateToUnixTimestamp(testObj.available_from);
        testObj.available_to = dateToUnixTimestamp(testObj.available_to);
        testObj.solution_available_from = dateToUnixTimestamp(
          testObj.solution_available_from
        );

        if (
          testObj.national_mock_meta &&
          testObj.national_mock_meta.job_schedule
        ) {
          var job_schedule_obj = [];

          testObj.national_mock_meta.job_schedule.forEach(function (task) {
            if (task.task_name && task.schedule_time) {
              const taskData = {
                schedule_time: dateToUnixTimestamp(task.schedule_time),
                task_name: task.task_name,
              };
              job_schedule_obj.push(taskData);
            }
          });
          testObj = {
            ...testObj,
            national_mock_meta: {
              ...testObj.national_mock_meta,
              job_schedule: job_schedule_obj,
            },
          };
        }

        if (
          testObj.visibility === "national_mock" &&
          !testObj.national_mock_meta.instructions.length
        ) {
          return alert("Please add instructions for national mock test!");
        }

        if (
          testObj.visibility === "national_mock" &&
          !isNationalMockTimelineValid(testObj)
        ) {
          return alert("Invalid timeline, Check for missing/invalid dates!");
        }

        //to support updating of test with huge number of questions (request entity too large)
        //its already handled on server side ( do not update questions if not exists in request )
        delete testObj.questions;

        await put(`/api/admin/test/${testId}`, testObj);
        history.push(`/admin/test`);
      } catch (err) {
        if (
          err.response &&
          err.response.data &&
          err.response.data.error &&
          err.response.data.error.message
        ) {
          return alert(err.response.data.error.message);
        }
        alert(err && err.message ? err.message : "Something went wrong");
      }
    })();
  };

  function isNationalMockTimelineValid(test_obj) {
    var isValid = false;

    if (
      !test_obj.available_from ||
      new Date(parseInt(test_obj.available_from) * 1000) < new Date()
    ) {
      // test is live now
      return isValid;
    }

    if (
      new Date(parseInt(test_obj.available_from) * 1000) <
        new Date(parseInt(test_obj.available_to) * 1000) &&
      new Date(parseInt(test_obj.available_to) * 1000) <
        new Date(parseInt(test_obj.solution_available_from) * 1000)
    ) {
      isValid = true;
      if (test_obj.national_mock_meta.job_schedule.length) {
        test_obj.national_mock_meta.job_schedule.forEach((schedule) => {
          if (
            schedule.task_name === "time_left_mailer" &&
            !(
              new Date(parseInt(test_obj.available_from) * 1000) <=
                new Date(parseInt(schedule.schedule_time) * 1000) &&
              new Date(parseInt(schedule.schedule_time) * 1000) <=
                new Date(parseInt(test_obj.available_to) * 1000)
            )
          ) {
            isValid = false;
          }
        });
      }
    }
    return isValid;
  }

  function URLSlugGeneration(text, separator) {
    var slug = text
      .toLowerCase()
      .replace(/([^a-z0-9\-_]+)/g, separator)
      .replace(new RegExp(separator + "{2,}", "g"), separator);

    if (slug.substr(-1) === separator) {
      slug = slug.substr(0, slug.length - 1);
    }

    return slug;
  }

  function findLanguages(exam_id) {
    const selectedExam = exams.find((exam) => {
      return exam._id.toString() === exam_id;
    });

    setLanguagesToSelect(
      selectedExam && selectedExam.available_languages
        ? selectedExam.available_languages
        : []
    );
  }

  function handleChange(e) {
    let { value, id } = e.target;
    if (id === "exam") {
      findLanguages(e.target.value);
      setResetMainTest(true);
      if (testData.national_mock_meta.is_paid && e.target.value) {
        findExamPackages(true, e.target.value);
      }
    }

    if (id === "is_identical") {
      value = value === "false" || value === false ? false : true;
      setResetMainTest(true);
    }

    if (id === "section_level_timing") {
      value = value === "false" || value === false ? false : true;
    }

    if (id.includes(".")) {
      if (id === "national_mock_meta.is_paid") {
        value = value === "false" || value === false ? false : true;
        if (value && testData.exam) {
          findExamPackages(true, testData.exam);
        }
      }

      const [id1, id2] = id.split(".");

      const data = { ...testData }[id1];

      data[id2] = value;
      setTestData({
        ...testData,
        [id1]: data,
      });
    } else {
      setTestData({
        ...testData,
        [id]: value,
      });
    }
  }

  function handleSectionChange(e) {
    const [id, index] = e.target.id.split("-");
    let { sections } = { ...testData };
    sections = sections || [];

    sections[index][id] = e.target.value;
    setTestData({
      ...testData,
      sections,
    });
  }

  useEffect(() => {
    if (isResetMainTest) {
      setResetMainTest(false);
      setTestData({
        ...testData,
        main_test_id: "",
      });

      (async function () {
        if (testData.exam && testData.is_identical) {
          const mainTestDataRes = await get(
            `/api/admin/exam/${testData.exam}/test/main-tests`
          );
          mainTestDataRes.sort(dynamicSort("name"));
          setMainTests(mainTestDataRes);
        } else {
          setMainTests([]);
        }
      })();
    }
  }, [isResetMainTest, testData]);

  function isTestQuestionCountValid() {
    var isValid = true;

    if (testData && testData.sections) {
      var total_question_count = 0;

      testData.sections.forEach((section) => {
        total_question_count += isNaN(parseFloat(section.question_count))
          ? 0
          : parseFloat(section.question_count);
      });

      if (total_question_count !== parseFloat(testData.question_count)) {
        isValid = false;
      }
    }

    return isValid;
  }

  function isTestDurationValid() {
    var isValid = true;

    if (testData && testData.sections) {
      var total_duration = 0;

      testData.sections.forEach((section) => {
        total_duration += isNaN(parseFloat(section.duration))
          ? 0
          : parseFloat(section.duration);
      });

      if (total_duration !== parseFloat(testData.duration)) {
        isValid = false;
      }
    }

    return isValid;
  }

  async function findExamPackages(is_paid, selectedExamId) {
    var examPackages = [];

    if (!is_paid || is_paid === "false") {
      return;
    }

    if (typeof selectedExamId == "object") {
      selectedExamId = selectedExamId._id;
    }

    try {
      const { packages } = await get(
        `/api/admin/package?examId=${selectedExamId}`
      );
      packages.sort(dynamicSort("name"));
      examPackages = packages;
    } catch (err) {}

    setExamPackages(examPackages);
  }

  async function handleFileChange(event) {
    const { id } = event.target;
    if (
      ![
        "national_mock_banners_web",
        "national_mock_banners_mobile",
        "national_mock_banners_og_image",
      ].includes(id)
    ) {
      return;
    }
    const fd = new FormData();
    fd.append("image", event.target.files[0], event.target.files[0].name);
    const { data } = await axios.post("/api/admin/upload/image?output=url", fd);

    const national_mock_meta = { ...testData }.national_mock_meta;

    switch (id) {
      case "national_mock_banners_web":
        national_mock_meta.banners.web = data;
        break;
      case "national_mock_banners_mobile":
        national_mock_meta.banners.mobile = data;
        break;
      case "national_mock_banners_og_image":
        national_mock_meta.banners.og_image = data;
        break;
      default:
    }

    setTestData({
      ...testData,
      national_mock_meta: {
        ...national_mock_meta,
      },
    });
  }

  return (
    <Card>
      <Card.Header>
        <h4 className="float-left">Update Test</h4>
      </Card.Header>
      {testData._id ? (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Card.Body className="">
            <Form.Row>
              <Form.Group as={Col} md="4" controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter test name"
                  value={testData.name || ""}
                  onChange={handleChange}
                  onBlur={(e)=>{
                    setTestData({
                      ...testData,
                      name: e.target.value ? e.target.value.trim() : ''
                    })
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Test name is required
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="visibility">
                <Form.Label>Visibility</Form.Label>
                <Form.Control
                  as="select"
                  required
                  onChange={handleChange}
                  className="text-capitalize"
                  value={testData.visibility || ""}
                >
                  <option value="" key="empty-visibility">
                    Select test visibility
                  </option>
                  {TEST_VISIBILITY.map((visibility) => (
                    <option
                      key={visibility}
                      className="text-capitalize"
                      value={visibility}
                    >
                      {visibility}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please select test visibility
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="exam">
                <Form.Label>Exam</Form.Label>
                <Form.Control
                  as="select"
                  placeholder="Select"
                  required
                  value={testData.exam || ""}
                  onChange={handleChange}
                >
                  <option value="">Select Exam</option>
                  {exams.map((exam) => (
                    <option value={exam._id} key={exam._id}>
                      {exam.name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please select exam
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md="4" controlId="language">
                <Form.Label>Test Language</Form.Label>
                <Form.Control
                  as="select"
                  placeholder="Select"
                  required
                  value={testData.language || ""}
                  onChange={handleChange}
                  className="text-capitalize"
                >
                  <option value="">Select Test Language</option>
                  {languagesToSelect.map((language, index) => (
                    <option value={language} key={index}>
                      {language}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please select a language
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="attempt_allowed">
                <Form.Label> Allowed Attempt Numbers </Form.Label>
                <Form.Control
                  type="number"
                  min={1}
                  step={1}
                  value={testData.attempt_allowed || ""}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="solution_available">
                <Form.Label>Solution Available</Form.Label>
                <Form.Control
                  required
                  as="select"
                  value={testData.solution_available}
                  onChange={handleChange}
                >
                  <option value={1}>Yes</option>
                  <option value={0}>No</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please select test solution availability
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="available_from">
                <Form.Label>Test Available From</Form.Label>
                <br />
                <DatePicker
                  id="available_from"
                  selected={testData.available_from || ""}
                  dateFormat="MM/dd/yyyy h:mm aa"
                  showTimeSelect
                  showTimeInput
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="scroll"
                  minDate={new Date()}
                  onChange={(date) => {
                    setTestData({
                      ...testData,
                      available_from: date,
                    });
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="available_to">
                <Form.Label>Test Available To</Form.Label>
                <br />
                <DatePicker
                  id="available_to"
                  selected={testData.available_to || ""}
                  dateFormat="MM/dd/yyyy h:mm aa"
                  showTimeSelect
                  showTimeInput
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="scroll"
                  minDate={new Date()}
                  onChange={(date) => {
                    setTestData({
                      ...testData,
                      available_to: date,
                    });
                  }}
                />
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="solution_available_from">
                <Form.Label>Solution Available From</Form.Label>
                <br />
                <DatePicker
                  id="solution_available_from"
                  selected={testData.solution_available_from || ""}
                  dateFormat="MM/dd/yyyy h:mm aa"
                  showTimeSelect
                  showTimeInput
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="scroll"
                  minDate={new Date()}
                  onChange={(date) => {
                    setTestData({
                      ...testData,
                      solution_available_from: date,
                    });
                  }}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md="12" controlId="description">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  required
                  as="textarea"
                  placeholder="Enter test description"
                  value={testData.description}
                  className="d-none"
                  onChange={() => {}}
                />
                <CKEditor4
                  data={testData.description}
                  onChange={(data) => {
                    setTestData({
                      ...testData,
                      description: data,
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Test description is required
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="instructions">
                <Form.Label>Instructions</Form.Label>
                <Form.Control
                  required
                  as="textarea"
                  placeholder="Enter test instructions"
                  value={testData.instructions}
                  className="d-none"
                  onChange={() => {}}
                />
                <CKEditor4
                  data={testData.instructions}
                  onChange={(data) => {
                    setTestData({
                      ...testData,
                      instructions: data,
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Test instructions is required
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            {testData.visibility === "national_mock" && testData.exam ? (
              <Card className="mb-2">
                <Card.Header>
                  <strong className="text-uppercase">
                    *Add meta for national mock
                  </strong>
                  <br />
                  <strong>
                    Web URL: /exam/{examSlug}/{testData.slug}/enroll
                  </strong>
                  <br />
                  <strong>
                    Summary URL: /exam/{examSlug}/{testData.slug}/summary
                  </strong>
                </Card.Header>
                <Card.Body className="mb-2">
                  <Form.Row>
                    <Form.Group
                      as={Col}
                      md="4"
                      controlId="national_mock_meta.title"
                    >
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        placeholder="Enter meta title"
                        required
                        value={testData.national_mock_meta.title || ""}
                        onChange={handleChange}
                        onBlur={(e) => {
                          const { national_mock_meta } = { ...testData };
                          national_mock_meta.title = testData.national_mock_meta
                            .title
                            ? testData.national_mock_meta.title.trim()
                            : "";
                          setTestData({
                            ...testData,
                            national_mock_meta: {
                              ...national_mock_meta,
                            },
                          });
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Title is required
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      md="4"
                      controlId="national_mock_meta.sub_title"
                    >
                      <Form.Label>Subtitle</Form.Label>
                      <Form.Control
                        placeholder="Enter meta sub_title"
                        required
                        value={testData.national_mock_meta.sub_title || ""}
                        onChange={handleChange}
                        onBlur={(e) => {
                          const { national_mock_meta } = { ...testData };
                          national_mock_meta.sub_title = testData
                            .national_mock_meta.sub_title
                            ? testData.national_mock_meta.sub_title.trim()
                            : "";
                          setTestData({
                            ...testData,
                            national_mock_meta: {
                              ...national_mock_meta,
                            },
                          });
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Sub title is required
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      md="4"
                      controlId="national_mock_meta.tagline"
                    >
                      <Form.Label>Tagline</Form.Label>
                      <Form.Control
                        placeholder="Enter meta tagline"
                        required
                        value={testData.national_mock_meta.tagline || ""}
                        onChange={handleChange}
                        onBlur={(e) => {
                          const { national_mock_meta } = { ...testData };
                          national_mock_meta.tagline = testData
                            .national_mock_meta.tagline
                            ? testData.national_mock_meta.tagline.trim()
                            : "";
                          setTestData({
                            ...testData,
                            national_mock_meta: {
                              ...national_mock_meta,
                            },
                          });
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Tagline is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="national_mock_meta.meta_description"
                    >
                      <Form.Label>Meta Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter test meta description"
                        required
                        value={
                          testData.national_mock_meta.meta_description || ""
                        }
                        onChange={handleChange}
                        onBlur={(e) => {
                          const { national_mock_meta } = { ...testData };
                          national_mock_meta.meta_description = testData
                            .national_mock_meta.meta_description
                            ? testData.national_mock_meta.meta_description.trim()
                            : "";
                          setTestData({
                            ...testData,
                            national_mock_meta: {
                              ...national_mock_meta,
                            },
                          });
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Test meta description is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="national_mock_meta.meta_keyword"
                    >
                      <Form.Label>Meta Keyword</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter test meta keyword"
                        required
                        value={testData.national_mock_meta.meta_keyword || ""}
                        onChange={handleChange}
                        onBlur={(e) => {
                          const { national_mock_meta } = { ...testData };
                          national_mock_meta.meta_keyword = testData
                            .national_mock_meta.meta_keyword
                            ? testData.national_mock_meta.meta_keyword.trim()
                            : "";
                          setTestData({
                            ...testData,
                            national_mock_meta: {
                              ...national_mock_meta,
                            },
                          });
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Test meta keyword is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="national_mock_meta.is_paid"
                    >
                      <Form.Label>Is test paid?</Form.Label>
                      <Form.Control
                        as="select"
                        placeholder="Enter test meta keyword"
                        required
                        value={
                          testData.national_mock_meta.is_paid ? true : false
                        }
                        onChange={handleChange}
                      >
                        <option value={false}>No</option>
                        <option value={true}>Yes</option>
                      </Form.Control>
                    </Form.Group>
                    {testData.national_mock_meta.is_paid ? (
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="national_mock_meta.is_paid"
                      >
                        <Form.Label>Package</Form.Label>
                        <Form.Control
                          required
                          as="select"
                          value={testData.national_mock_meta.package_id || ""}
                          onChange={handleChange}
                        >
                          <option value="">Select Exam package</option>
                          {examPackages.map((packageItem) => (
                            <option
                              value={packageItem._id}
                              key={packageItem._id}
                            >
                              {packageItem.name}
                            </option>
                          ))}
                        </Form.Control>

                        <Form.Control.Feedback type="invalid">
                          For paid test package required!
                        </Form.Control.Feedback>
                      </Form.Group>
                    ) : (
                      ""
                    )}
                  </Form.Row>
                  <strong className="row text-uppercase">
                    Add banners for national mock
                  </strong>
                  <Form.Row>
                    <Form.Group
                      as={Col}
                      md="4"
                      controlId="national_mock_banners_web"
                      onChange={handleFileChange}
                    >
                      <Form.Label>Web banner</Form.Label>
                      <Form.File accept="image/*"></Form.File>
                      <Form.Control
                        className="d-none"
                        type="text"
                        required
                        value={testData.national_mock_meta.banners.web || ""}
                        onChange={() => {}}
                      ></Form.Control>
                      {testData.national_mock_meta.banners.web ? (
                        <img
                          className="img-thumbnail"
                          src={testData.national_mock_meta.banners.web}
                          alt="web-img"
                        />
                      ) : (
                        ""
                      )}
                      <Form.Control.Feedback type="invalid">
                        Banner required!
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      md="4"
                      controlId="national_mock_banners_mobile"
                      onChange={handleFileChange}
                    >
                      <Form.Label>Mobile banner</Form.Label>
                      <Form.File accept="image/*"></Form.File>
                      <Form.Control
                        className="d-none"
                        type="text"
                        required
                        value={testData.national_mock_meta.banners.mobile || ""}
                        onChange={() => {}}
                      ></Form.Control>
                      {testData.national_mock_meta.banners.mobile ? (
                        <img
                          className="img-thumbnail"
                          src={testData.national_mock_meta.banners.mobile}
                          alt="mobile-img"
                        />
                      ) : (
                        ""
                      )}
                      <Form.Control.Feedback type="invalid">
                        Banner required!
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      md="4"
                      controlId="national_mock_banners_og_image"
                      onChange={handleFileChange}
                    >
                      <Form.Label>OG image</Form.Label>
                      <Form.File accept="image/*"></Form.File>
                      <Form.Control
                        className="d-none"
                        type="text"
                        required
                        value={
                          testData.national_mock_meta.banners.og_image || ""
                        }
                        onChange={() => {}}
                      ></Form.Control>
                      {testData.national_mock_meta.banners.og_image ? (
                        <img
                          className="img-thumbnail"
                          src={testData.national_mock_meta.banners.og_image}
                          alt="og_image-img"
                        />
                      ) : (
                        ""
                      )}
                      <Form.Control.Feedback type="invalid">
                        Banner required!
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <p className="text-uppercase mt-5">Add Mailer schedule</p>
                  <p className="text-danger">
                    Please note: Add time left schedule b/w Test available from
                    to Test Available to dates
                  </p>
                  <Form.Row>
                    <Button
                      variant="primary"
                      onClick={() => {
                        const national_mock_meta = { ...testData }
                          .national_mock_meta;
                        const job_schedule =
                          national_mock_meta.job_schedule || [];
                        job_schedule.push({});
                        national_mock_meta.job_schedule = job_schedule;
                        setTestData({
                          ...testData,
                          national_mock_meta: { ...national_mock_meta },
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} /> Add schedule{" "}
                      {testData.national_mock_meta.job_schedule &&
                      testData.national_mock_meta.job_schedule.length
                        ? `(${testData.national_mock_meta.job_schedule.length})`
                        : ""}
                    </Button>
                  </Form.Row>
                  <br />
                  {testData.national_mock_meta.job_schedule &&
                  testData.national_mock_meta.job_schedule.length
                    ? testData.national_mock_meta.job_schedule.map(
                        (item, index) => (
                          <Form.Row key={index}>
                            <Col md="12">
                              <strong>Schedule - {index + 1}</strong>
                            </Col>
                            <Form.Group as={Col} md="5">
                              <Form.Label>Task</Form.Label>
                              <Form.Control
                                as="select"
                                required
                                value={item.task_name}
                                onChange={(e) => {
                                  const test = {
                                    ...testData,
                                  };

                                  test.national_mock_meta.job_schedule[
                                    index
                                  ].task_name = e.target.value;
                                  setTestData(test);
                                }}
                              >
                                <option value="">Select a Task</option>
                                <option value="time_left_mailer">
                                  Time_left_mailer
                                </option>
                              </Form.Control>
                              <Form.Control.Feedback type="invalid">
                                Please select a tesk
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="4">
                              <Form.Label> Schedule time</Form.Label>
                              <br />
                              <DatePicker
                                selected={item.schedule_time || ""}
                                dateFormat="MM/dd/yyyy h:mm aa"
                                showTimeSelect
                                showTimeInput
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="scroll"
                                minDate={new Date()}
                                onChange={(date) => {
                                  const test = {
                                    ...testData,
                                  };

                                  test.national_mock_meta.job_schedule[
                                    index
                                  ].schedule_time = date;
                                  setTestData(test);
                                }}
                              />
                              <Form.Control
                                required
                                value={item.schedule_time || ""}
                                onChange={() => {}}
                                className="d-none"
                              />
                              <Form.Control.Feedback type="invalid">
                                Enter Schedule time
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                              {testData.national_mock_meta.job_schedule
                                .length ===
                              index + 1 ? (
                                <FontAwesomeIcon
                                  icon={faTimesCircle}
                                  color="black"
                                  style={{
                                    marginTop: "37px",
                                    fontSize: "28px",
                                    marginLeft: "14px",
                                    cursor: "pointer",
                                  }}
                                  title="Remove"
                                  onClick={() => {
                                    const { job_schedule } = {
                                      ...testData,
                                    }.national_mock_meta;

                                    job_schedule.splice(index, 1);
                                    setTestData({
                                      ...testData,
                                      national_mock_meta: {
                                        ...testData.national_mock_meta,
                                        job_schedule,
                                      },
                                    });
                                  }}
                                />
                              ) : (
                                ""
                              )}
                            </Form.Group>
                          </Form.Row>
                        )
                      )
                    : ""}

                  <Form.Row>
                    <Button
                      variant="primary"
                      onClick={() => {
                        const national_mock_meta = { ...testData }
                          .national_mock_meta;
                        const instructions =
                          national_mock_meta.instructions || [];
                        instructions.push("");
                        national_mock_meta.instructions = instructions;
                        setTestData({
                          ...testData,
                          national_mock_meta: { ...national_mock_meta },
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} /> Add instructions{" "}
                      {testData.national_mock_meta.instructions &&
                      testData.national_mock_meta.instructions.length
                        ? `(${testData.national_mock_meta.instructions.length})`
                        : ""}
                    </Button>
                  </Form.Row>
                  <br />
                  {testData.national_mock_meta.instructions &&
                  testData.national_mock_meta.instructions.length
                    ? testData.national_mock_meta.instructions.map(
                        (instruction, index) => (
                          <Form.Row key={index}>
                            <Col md="12">
                              <strong>Instruction - {index + 1}</strong>
                            </Col>
                            <Form.Group as={Col} md="10">
                              <Form.Label>Name</Form.Label>
                              <Form.Control
                                required
                                value={instruction || ""}
                                onChange={(e) => {
                                  const test = {
                                    ...testData,
                                  };

                                  test.national_mock_meta.instructions[index] =
                                    e.target.value;
                                  setTestData(test);
                                }}
                                onBlur={(e) => {
                                  const test = {
                                    ...testData,
                                  };

                                  test.national_mock_meta.instructions[
                                    index
                                  ] = e.target.value
                                    ? e.target.value.trim()
                                    : "";
                                  setTestData(test);
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                Enter instruction
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="2">
                              {testData.national_mock_meta.instructions
                                .length ===
                              index + 1 ? (
                                <FontAwesomeIcon
                                  icon={faTimesCircle}
                                  color="black"
                                  style={{
                                    marginTop: "37px",
                                    fontSize: "28px",
                                    marginLeft: "14px",
                                    cursor: "pointer",
                                  }}
                                  title="Remove"
                                  onClick={() => {
                                    const { instructions } = {
                                      ...testData,
                                    }.national_mock_meta;

                                    instructions.splice(index, 1);
                                    setTestData({
                                      ...testData,
                                      national_mock_meta: {
                                        ...testData.national_mock_meta,
                                        instructions,
                                      },
                                    });
                                  }}
                                />
                              ) : (
                                ""
                              )}
                            </Form.Group>
                          </Form.Row>
                        )
                      )
                    : ""}
                </Card.Body>
              </Card>
            ) : (
              ""
            )}

            <Form.Row>
              <Form.Group as={Col} md="6" controlId="is_identical">
                <Form.Label>Is Identical test?</Form.Label>
                <Form.Control
                  as="select"
                  placeholder="Select"
                  required
                  value={testData.is_identical ? true : false}
                  onChange={handleChange}
                  className="text-capitalize"
                >
                  <option value={false}>No</option>
                  <option value={true}>Yes</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please select a choice
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="main_test_id">
                <Form.Label>Select Main Test</Form.Label>
                <Form.Control
                  as="select"
                  placeholder="Select"
                  required={testData.is_identical}
                  disabled={!testData.is_identical}
                  value={testData.main_test_id ? testData.main_test_id : ""}
                  onChange={handleChange}
                  className="text-capitalize"
                >
                  <option key={`main-test-opt`} value={""}>
                    Select Main test
                  </option>
                  {mainTests.map((test) => (
                    <option key={test._id} value={test._id}>
                      {test.name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please select a main test ( Required for Identical tests )
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="4" controlId="question_count">
                <Form.Label>Total Questions</Form.Label>
                <Form.Control
                  isInvalid={validated && !isTestQuestionCountValid()}
                  required
                  type="number"
                  min={1}
                  step={1}
                  placeholder="Enter question count"
                  value={testData.question_count}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  Question count &gt;0 is required and if sections available,
                  Total Questions should be equal to all section's.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="duration">
                <Form.Label>Duration (in minutes)</Form.Label>
                <Form.Control
                  isInvalid={validated && !isTestDurationValid()}
                  required
                  type="number"
                  min={1}
                  step={1}
                  placeholder="Enter test duration"
                  onChange={handleChange}
                  value={testData.duration}
                />
                <Form.Control.Feedback type="invalid">
                  Duration &gt;0 is required and if sections available, Total
                  Duration should be equal to sum of all section's.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="difficulty_level">
                <Form.Label>Difficulty Level</Form.Label>
                <Form.Control
                  className="text-capitalize"
                  required
                  as="select"
                  value={testData.difficulty_level}
                  onChange={handleChange}
                >
                  <option value="">Select Difficulty Level</option>
                  {[
                    "beginner",
                    "basic",
                    "intermediate",
                    "advanced",
                    "expert",
                  ].map((difficulty) => (
                    <option
                      className="text-capitalize"
                      key={difficulty}
                      value={difficulty}
                    >
                      {difficulty}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please select the test difficulty level
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="marks_per_question">
                <Form.Label>Marks per question</Form.Label>
                <Form.Control
                  required
                  type="number"
                  min={0.01}
                  step={0.01}
                  placeholder="Enter marks per question"
                  onChange={handleChange}
                  value={testData.marks_per_question}
                />
                <Form.Control.Feedback type="invalid">
                  Marks per question is required and should be a +ve no.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                as={Col}
                md="4"
                controlId="negative_marks_per_question"
              >
                <Form.Label>Negative Marks per question</Form.Label>
                <Form.Control
                  required
                  type="number"
                  min={0}
                  step={0.01}
                  placeholder="Enter negative marks per question"
                  onChange={handleChange}
                  value={testData.negative_marks_per_question}
                />
                <Form.Control.Feedback type="invalid">
                  Negative marks per question is required and should be a non
                  -ve no.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="price">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  required
                  type="number"
                  min={0}
                  placeholder="Enter test price"
                  onChange={handleChange}
                  value={testData.price}
                />
                <Form.Control.Feedback type="invalid">
                  Test price is required and must be a not -ve no.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Button
                variant="primary"
                onClick={() => {
                  let { sections } = { ...testData };
                  sections = sections || [];
                  sections.push({});
                  setTestData({
                    ...testData,
                    sections,
                  });
                }}
              >
                <FontAwesomeIcon icon={faPlus} /> Add section{" "}
                {testData.sections && testData.sections.length
                  ? `(${testData.sections.length})`
                  : ""}
              </Button>
            </Form.Row>
            <br />
            {testData.sections && testData.sections.length ? (
              <>
                <Form.Row>
                  <Form.Group as={Col} md="3" controlId="section_level_timing">
                    <Form.Label> Section Level Timing </Form.Label>
                    <Form.Control
                      value={testData.section_level_timing ? true : false}
                      className="text-capitalize"
                      as="select"
                      onChange={handleChange}
                    >
                      {[
                        {
                          key: "no",
                          value: false,
                        },
                        {
                          key: "yes",
                          value: true,
                        },
                      ].map((op) => (
                        <option
                          className="text-capitalize"
                          key={op.key}
                          value={op.value}
                        >
                          {op.key}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  {testData.section_level_timing ? (
                    <Form.Group as={Col} md="3" controlId="can_submit_section">
                      <Form.Label> Allow Manual Submit </Form.Label>
                      <Form.Control
                        value={testData.can_submit_section ? true : false}
                        className="text-capitalize"
                        as="select"
                        onChange={handleChange}
                      >
                        {[
                          {
                            key: "no",
                            value: false,
                          },
                          {
                            key: "yes",
                            value: true,
                          },
                        ].map((op) => (
                          <option
                            className="text-capitalize"
                            key={op.key}
                            value={op.value}
                          >
                            {op.key}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  ) : (
                    ""
                  )}
                </Form.Row>
                {testData.sections.map((section, index) => (
                  <Form.Row key={`section-${index}`}>
                    <Form.Group as={Col} md="2" controlId={`name-${index}`}>
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter section name"
                        onChange={handleSectionChange}
                        value={section.name}
                        onBlur={() => {
                          let { sections } = testData;

                          sections[index].name = sections[index].name
                            ? sections[index].name.trim()
                            : "";

                          setTestData({
                            ...testData,
                            sections,
                          });
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Section name is required
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="2" controlId={`duration-${index}`}>
                      <Form.Label>Duration (in minutes)</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        min={1}
                        step={1}
                        placeholder="Enter test duration"
                        onChange={handleSectionChange}
                        value={section.duration}
                      />
                      <Form.Control.Feedback type="invalid">
                        Duration &gt;0 is required.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      md="2"
                      controlId={`marks_per_question-${index}`}
                    >
                      <Form.Label> Question mark </Form.Label>
                      <Form.Control
                        required
                        type="number"
                        min={0.01}
                        step={0.01}
                        placeholder="Enter marks per question"
                        onChange={handleSectionChange}
                        value={section.marks_per_question}
                      />
                      <Form.Control.Feedback type="invalid">
                        Marks per question is required and should be a +ve no.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      md="2"
                      controlId={`negative_marks_per_question-${index}`}
                    >
                      <Form.Label> -ve question mark </Form.Label>
                      <Form.Control
                        required
                        type="number"
                        min={0}
                        step={0.01}
                        placeholder="Enter negative marks per question"
                        onChange={handleSectionChange}
                        value={section.negative_marks_per_question}
                      />
                      <Form.Control.Feedback type="invalid">
                        Negative marks per question is required and should be a
                        non -ve no.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="2"
                      controlId={`question_count-${index}`}
                    >
                      <Form.Label>Total Questions</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        min={1}
                        step={1}
                        placeholder="Enter question count"
                        onChange={handleSectionChange}
                        value={section.question_count}
                      />
                      <Form.Control.Feedback type="invalid">
                        Question count &gt;0 is required.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="1">
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        color="black"
                        style={{
                          marginTop: "37px",
                          fontSize: "28px",
                          marginLeft: "14px",
                          cursor: "pointer",
                        }}
                        title="Remove"
                        onClick={() => {
                          let { sections } = { ...testData };
                          sections.splice(index, 1);
                          setTestData({
                            ...testData,
                            sections: [...sections],
                          });
                        }}
                      />
                    </Form.Group>
                  </Form.Row>
                ))}
              </>
            ) : (
              ""
            )}
          </Card.Body>
          <Card.Footer>
            <Link to={`/admin/test`}>
              <Button variant="secondary">Back</Button>
            </Link>{" "}
            <Button type="submit">Update</Button>
          </Card.Footer>
        </Form>
      ) : (
        <h1>Loading test...</h1>
      )}
    </Card>
  );
}

export default UpdateTest;
