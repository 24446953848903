import React, { useState, useEffect } from "react";
import { Card, Table, Form, Button } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { get, put } from "../../helpers/RequestHelper";
function Exam() {
  let { examId, collectionId } = useParams();
  const [tests, setTests] = useState([]);
  const [collection, setCollection] = useState({});
  let history = useHistory();
  useEffect(() => {
    getTestData();
    getTestCollectionData();
  }, []);

  const handleClick = () => {
    history.push("/admin/exam/" + examId + "/collection");
  };

  async function getTestCollectionData() {
    try {
      const testCollectionData = await get(
        `/api/admin/exam/${examId}/collection/${collectionId}`
      );

      setCollection(testCollectionData);
    } catch (err) {}
  }

  async function handleChange(test) {
    try {
      const checkboxData = await put("/api/admin/exam/collection/test/toggle", {
        collectionId,
        examId,
        test,
      });
      setCollection(checkboxData);
    } catch (err) {}
  }
  async function getTestData() {
    try {
      const data = await get(
        `/api/admin/test?exam=${examId}&is_identical=false&visibility=public`
      );
      setTests(data.tests);
    } catch (err) {}
  }

  function isAlreadyAdded(test_id) {
    let testInCollection = false;

    if (collection.test_ids && collection.test_ids.length) {
      testInCollection = collection.test_ids.find(
        (test) => test._id.toString() === test_id.toString()
      );
    }

    return testInCollection ? true : false;
  }

  const renderData = (test, index) => {
    return (
      <tr key={test._id}>
        <td>
          <Form.Group id={test._id}>
            <Form.Check
              type="checkbox"
              label={test.name}
              defaultChecked={isAlreadyAdded(test._id)}
              onChange={() => handleChange(test)}
            />
          </Form.Group>
        </td>
      </tr>
    );
  };
  return (
    <Card>
      <Card.Header>Add/Remove Test</Card.Header>
      <Card.Body>
        {tests.length && collection._id ? (
          <Table striped bordered hover>
            <tbody>{tests.map(renderData)}</tbody>
          </Table>
        ) : (
          ""
        )}
      </Card.Body>
      <Card.Footer>
        <Button variant="info" onClick={handleClick}>
          Save & Back
        </Button>
      </Card.Footer>
    </Card>
  );
}
export default Exam;
