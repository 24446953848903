import React from "react";
import CKEditor from "ckeditor4-react";

    CKEditor.editorUrl = `${window.location.origin}/libs/ckeditor-full/ckeditor.js`
    
const CONFIG = { 
  filebrowserUploadUrl: "/api/admin/upload/image",
  mathJaxLib :"//cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.1/MathJax.js?config=TeX-AMS_HTML",

  toolbar: [
    {
      name: "document",
      items: [
        "Source",
        "-",
        "Save",
        "NewPage",
        "Preview",
        "Print",
        "-",
        "Templates",
      ],
    },
    {
      name: "clipboard",
      items: [
        "Cut",
        "Copy",
        "Paste",
        "PasteText",
        "PasteFromWord",
        "-",
        "Undo",
        "Redo",
      ],
    },
    {
      name: "editing",
      items: ["Find", "Replace", "-", "SelectAll", "-", "Scayt"],
    },
    {
      name: "forms",
      items: [
        "Form",
        "Checkbox",
        "Radio",
        "TextField",
        "Textarea",
        "Select",
        "Button",
        "ImageButton",
        "HiddenField",
      ],
    },
    "/",
    {
      name: "basicstyles",
      items: [
        
        "Bold",
        "Italic",
        "Underline",
        "Strike",
        "Subscript",
        "Superscript",
        "-",
        "RemoveFormat",
      ],
    },
    {
      name: "paragraph",
      items: [
        "NumberedList",
        "BulletedList",
        "-",
        "Outdent",
        "Indent",
        "-",
        "Blockquote",
        "CreateDiv",
        "-",
        "JustifyLeft",
        "JustifyCenter",
        "JustifyRight",
        "JustifyBlock",
        "-",
        "BidiLtr",
        "BidiRtl",
        "Language",
      ],
    },
    { name: "links", items: ["Link", "Unlink", "Anchor"] },
    {
      name: "insert",
      items: [
        "Image",
        "Flash",
        "Table",
        "HorizontalRule",
        "Smiley",
        "SpecialChar",
        "PageBreak",
        "Iframe",
      ],
    },
    "/",
    { name: "styles", items: ["Styles", "Format", "Font", "FontSize"] },
    { name: "colors", items: ["TextColor", "BGColor"] },
    { name: "tools", items: ["Maximize", "ShowBlocks"] },
    { name: "about", items: ["About"] },
    { name: "math", items: ["Mathjax"] },
  ],
}


export default function ({
  data,
  onFocus,
  onBlur,
  onChange,
  onSelectionChange,
}) {
  return (
    <CKEditor
      data={data ? data : ""}
     config={CONFIG}
      onFocus={(e) => {
        if (onFocus) {
          onFocus(e);
        }
      }}
      onBlur={(e) => {
        if (onBlur) {
          onBlur(e);
        }
      }}
      onChange={(e) => {
        if (onChange) {
          onChange(e.editor.getData())
        }
      }}
      onSelectionChange={(e) => {
        if (onSelectionChange) {
          onSelectionChange(e);
        }
      }}
    />
  );
}
