import React, { useEffect, useState } from "react";
import { Card, Button, Table, Row, Col, Form } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../components/pagination/Pagination";

import { get, deleteRequest } from "../../helpers/RequestHelper";
import queryString from "query-string";

const LIMIT = 10;

function Subject() {
  let history = useHistory();
  const handleClick = () => {
    history.push("/admin/subject/" + subjectId + "/chapter/create");
  };
  const goBack=()=>{
    history.push("/admin/subject");
  }
  const [chapters, setChapter] = useState([]);
  const [filters, setFilters] = useState({
    limit: LIMIT,
    page: 1,
  });
  const [totalCount, setTotalCount] = useState(0);
  let { subjectId } = useParams();
  useEffect(() => {
    getChapterData(subjectId);
  }, [filters]);

  async function getChapterData(subjectId) {
    try {
      const stringified = queryString.stringify(filters);
      const userData = await get(
        `/api/admin/subject/${subjectId}/chapter?${stringified}`
      );
      setChapter(userData.chapters);
      setTotalCount(userData.total_count);
    } catch (err) {
      setChapter([]);
    }
  }

  async function onFilterChange(newFilters) {
    setFilters({
      ...filters,
      ...newFilters,
    });
  }

  async function toggleStatus(subjectId, chapter_id) {
    try {
      await deleteRequest(
        "/api/admin/subject/" + subjectId + "/chapter/" + chapter_id
      );
      await getChapterData(subjectId);
    } catch (err) {}
  }

  const renderData = (chapter, index) => {
    let indexCount = (filters.page - 1) * LIMIT + index + 1;
    return [
      <tr key={chapter._id} className={chapter.status === 1 ? "" : "bg-danger"}>
        <td>{indexCount}</td>
        <td>{chapter.name}</td>
        <td>{chapter.description}</td>
        <td>{chapter.created_by.name}</td>
        <td>{new Date(chapter.created_on).toLocaleString()}</td>
        <td>
          <Link
            to={`/admin/subject/${chapter.parent_id._id}/chapter/${chapter._id}/topic`}
            className="btn"
            style={{ color: "blue" }}
          >
            Manage Topics
          </Link>
          <Link
            to={`/admin/subject/${chapter.parent_id._id}/chapter/${chapter._id}/edit`}
            className="btn"
            title="Edit"
          >
            <FontAwesomeIcon icon={faEdit} color="blue" />
          </Link>
          {chapter.status === 1 ? (
            <Button
              variant="link"
              onClick={() => toggleStatus(chapter.parent_id._id, chapter._id)}
              title="Disable"
            >
              <FontAwesomeIcon icon={faTimes} color="red" />
            </Button>
          ) : (
            <Button
              variant="link"
              onClick={() => toggleStatus(chapter.parent_id._id, chapter._id)}
              title="Enable"
            >
              <FontAwesomeIcon icon={faCheck} color="blue" />
            </Button>
          )}
        </td>
      </tr>,
    ];
  };

  return (
    <>
      <Filter onChange={onFilterChange} />
      <Card>
        <Card.Header>
          <h4 className="float-left">Manage chapters</h4>
          
          <Button variant="info" onClick={goBack}  style={{marginLeft:"860px"}}>
           Back
          </Button>{" "}
          <Button variant="info" onClick={handleClick} className="float-right">
            Add New
          </Button>
        </Card.Header>
        <Card.Body className="p-0">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Description</th>
                <th>Created By</th>
                <th>Created On</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{chapters.map(renderData)}</tbody>
          </Table>
        </Card.Body>
        <Card.Footer>
          <Pagination
            currentPage={filters.page}
            limit={LIMIT}
            totalCount={totalCount}
            onChange={onFilterChange}
            filters={{}}
          />
        </Card.Footer>
      </Card>
    </>
  );
}
export default Subject;

function Filter(props) {
  function onSubmit(e) {
    e.preventDefault();
    props.onChange({
      text: document.getElementById("name").value,
      page: 1,
    });
  }

  function onReset(e) {
    e.preventDefault();
    props.onChange({
      text: "",
      page: 1,
    });
    document.getElementById("name").value="";
  }

  return (
    <Form onSubmit={onSubmit} onReset={onReset}>
      <fieldset className="filters">
        <legend align="left">Filters:</legend>
        <div className="filters-body">
          <Row>
            <Col md="3">
              <Form.Group controlId="name">
                <Form.Label>Chapter</Form.Label>
                <Form.Control as="input" placeholder="Enter text" />
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className="filters-footer">
          <Button type="reset" variant="outline-secondary">
            Reset
          </Button>{" "}
          <Button type="submit" variant="primary">
            Submit
          </Button>
        </div>
      </fieldset>
    </Form>
  );
}
