import React, { useEffect, useState } from "react";
import { Modal, Button, Card, Form, Col } from "react-bootstrap";
import { get, put } from "../../../helpers/RequestHelper";

function PreviewModal({ show, handleClose, currentTest: test }) {
  const [validated, setValidated] = useState(false);
  const [testData, setTestData] = useState(null);
  const [previewCurrentIndex, setPreviewCurrentIndex] = useState(-1);
  const [currentQuestion, setCurrentQuestion] = useState(null);

  const saveAndNextQuestion = async (event) => {
    const form = event.currentTarget;

    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    let questionData = { ...currentQuestion };

    questionData.question = questionData.question._id;
    await put(`/api/admin/test/question/marking`, {
      id: testData._id,
      question: questionData,
    });

    if (previewCurrentIndex + 1 === testData.questions.length) {
      handleClose();
      return;
    }

    let questions = [...testData.questions];

    questions[ previewCurrentIndex ] =   { ...currentQuestion };

    setTestData({
      ...testData,
      questions: questions
    });
    nextQuestion();
  }

  function handleChange(event) {
    setCurrentQuestion({
      ...currentQuestion,
      [event.target.id]: event.target.value,
    });
  }

  useEffect(() => {
    (async function getTestData() {
      if (show && test) {
        const testData = await get(`/api/admin/test/${test._id}`);
        setTestData(testData);
        if (testData.questions && testData.questions.length) {
          setPreviewCurrentIndex(0);
          setCurrentQuestion(testData.questions[0]);
        }
      }
    })();
  }, [show, test]);

  useEffect(() => {
    if (currentQuestion) {
      // eslint-disable-next-line no-undef
      MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    }
  }, [currentQuestion]);

  function previousQuestion() {
    setValidated(false);
    if (previewCurrentIndex <= 0) {
      setPreviewCurrentIndex(0);
      setCurrentQuestion(testData.questions[0]);
      return;
    }

    setCurrentQuestion(testData.questions[previewCurrentIndex - 1]);
    setPreviewCurrentIndex(previewCurrentIndex - 1);
  }

  function nextQuestion() {
    setValidated(false);
    if (previewCurrentIndex >= testData.questions.length - 1) {
      setCurrentQuestion(testData.questions[testData.questions.length - 1]);
      setPreviewCurrentIndex(testData.questions.length - 1);
      return;
    }
    setCurrentQuestion(testData.questions[previewCurrentIndex + 1]);
    setPreviewCurrentIndex(previewCurrentIndex + 1);
  }

  if (!testData) {
    return "";
  }

  return (
    <>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span style={{ fontWeight: "normal" }}>Preview of </span>
            {testData.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentQuestion ? (
            <div className="clearfix mb-2">
              <div className="float-right">
                <Button
                  variant="primary"
                  disabled={previewCurrentIndex === 0}
                  onClick={previousQuestion}
                >
                  Previous
                </Button>{" "}
                <Button
                  variant="primary"
                  disabled={
                    previewCurrentIndex + 1 === testData.questions.length
                  }
                  onClick={nextQuestion}
                >
                  Next
                </Button>
              </div>
            </div>
          ) : (
            ""
          )}
          {currentQuestion ? (
            <Card className="w-100" id="question-preview-card">
              <Card.Header>
                <div
                  className="float-left"
                  style={{ fontWeight: "bold", marginRight: "5px" }}
                >
                  Question {previewCurrentIndex + 1}:
                </div>
                <div className="float-left">
                  {currentQuestion.question.direction ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: currentQuestion.question.direction.description,
                      }}
                    ></div>
                  ) : (
                    ""
                  )}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: currentQuestion.question.question,
                    }}
                    style={{ wordBreak: "break-all" }}
                  ></div>
                </div>
              </Card.Header>

              <Card.Body style={{ maxHeight: "225px", overflow: "auto" }}>
                {currentQuestion.question.answer_choices.map(
                  (choice, index) => (
                    <div className="m-2" key={index}>
                      <span className="float-left mr-2">
                        <input
                          type={
                            currentQuestion.question.correct_answer_type ===
                            "single"
                              ? "radio"
                              : "checkbox"
                          }
                          name="option"
                          checked={
                            currentQuestion.question.correct_answers[index] ===
                            1
                              ? true
                              : false
                          }
                          disabled
                        />
                      </span>
                      <span
                        className="float-left"
                        dangerouslySetInnerHTML={{
                          __html: choice,
                        }}
                      ></span>
                      <span className="clearfix"></span>
                    </div>
                  )
                )}
              </Card.Body>
              <Card.Footer>
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={saveAndNextQuestion}
                >
                  <Form.Row className="align-items-center">
                    <Col xs="auto">
                      <Form.Label htmlFor="mark" srOnly>
                        Marks per question
                      </Form.Label>
                      <Form.Control
                        type="number"
                        min={1}
                        className="mb-2"
                        id="mark"
                        required
                        placeholder={
                          previewCurrentQuestionPlaceholder(
                            currentQuestion,
                            testData
                          ).preview_question_mark_placeholder
                        }
                        value={currentQuestion.mark || ''}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Mark for question is required and should be a +ve no
                      </Form.Control.Feedback>
                    </Col>

                    <Col xs="auto">
                      <Form.Label htmlFor="negative_mark" srOnly>
                        Negative mark
                      </Form.Label>
                      <Form.Control
                        required
                        type="number"
                        min={0}
                        className="mb-2"
                        id="negative_mark"
                        value={currentQuestion.negative_mark || 0}
                        onChange={handleChange}
                        placeholder={
                          previewCurrentQuestionPlaceholder(
                            currentQuestion,
                            testData
                          ).preview_question_negative_mark_placeholder
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Negative mark for question is required should be non -ve
                        no
                      </Form.Control.Feedback>
                    </Col>

                    <Col xs="auto">
                      <Button type="submit" className="mb-2">
                        {previewCurrentIndex + 1 !== testData.questions.length
                          ? "Save & next"
                          : "Save & close"}
                      </Button>
                    </Col>
                  </Form.Row>
                </Form>
              </Card.Footer>
            </Card>
          ) : (
            <div className="alert alert-danger">
              This test does not have any question.
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PreviewModal;

function previewCurrentQuestionPlaceholder(
  preview_current_question,
  preview_test
) {
  var preview_question_negative_mark_placeholder = "Enter negetive mark";
  var preview_question_mark_placeholder = "Enter mark";

  // Show question level marks suggetion as placeholder
  if (
    isNaN(parseFloat(preview_current_question.negative_mark)) &&
    isNaN(parseFloat(preview_current_question.mark))
  ) {
    if (
      preview_test.is_section_level_marking &&
      preview_current_question.section
    ) {
      var preview_test_sections = [...preview_test.sections];
      var currentSection = preview_test_sections.find((section) => {
        return (
          section._id.toString() === preview_current_question.section.toString()
        );
      });

      if (
        currentSection &&
        !isNaN(parseFloat(currentSection.negative_marks_per_question)) &&
        !isNaN(parseFloat(currentSection.marks_per_question))
      ) {
        preview_question_negative_mark_placeholder =
          currentSection.negative_marks_per_question;
        preview_question_mark_placeholder = currentSection.marks_per_question;
      } else {
        preview_question_negative_mark_placeholder =
          preview_test.negative_marks_per_question;
        preview_question_mark_placeholder = preview_test.marks_per_question;
      }
    } else {
      preview_question_negative_mark_placeholder =
        preview_test.negative_marks_per_question;
      preview_question_mark_placeholder = preview_test.marks_per_question;
    }
  }

  return {
    preview_question_mark_placeholder,
    preview_question_negative_mark_placeholder,
  };
}
