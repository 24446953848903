import React from "react";

function QuestionDirection() {
  return (
    <div>
      <h3>QuestionDirection Page</h3>
    </div>
  );
}
export default QuestionDirection;
