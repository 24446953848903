import React from "react";
import { Route, Redirect } from "react-router-dom";
import { userContext } from "../../userContext";

import RoleHelper from "../../helpers/RoleBaseAuthHelper";
import NoAccess from './NoAccess';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <userContext.Consumer>
      {({ user }) => {
        return (
          <Route
            {...rest}
            render={(props) => {
              if (user) {
                if (rest.category) {
                  const userRole = RoleHelper(user);
                  const keys = rest.category.split(".");
                  let data = userRole.Actions;
                  keys.forEach((key) => {
                    data = data[key];
                  });
                  const isUserCan = userRole.can(data.VALUE);

                  if (!isUserCan) {
                    return <NoAccess />
                  }
                }

                return <Component {...props} />;
              }
              return (
                <Redirect
                  to={{
                    pathname: "/admin/login",
                    state: { from: props.location },
                  }}
                />
              );
            }}
          />
        );
      }}
    </userContext.Consumer>
  );
};
