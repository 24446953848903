import React, { useEffect, useState } from "react";
import { Card, Form, Col, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { get, post } from "./../../helpers/RequestHelper";

import CKEditor4 from "./../../components/input/TextEditor";
import { dynamicSort } from "../../helpers/UtilityHelper";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const TEST_VISIBILITY = [
  "public",
  "private",
  "subscriber_only",
  "package_only",
  "national_mock",
];

function CreateTest() {
  const history = useHistory();

  const [validated, setValidated] = useState(false);
  const [testData, setTestData] = useState({
    is_identical: false,
    section_level_timing: false,
    can_submit_section: false,
  });
  const [exams, setExams] = useState([]);
  const [languagesToSelect, setLanguagesToSelect] = useState([]);
  const [mainTests, setMainTests] = useState([]);
  const [isResetMainTest, setResetMainTest] = useState(false);

  useEffect(() => {
    (async function getExamData() {
      const examData = await get(`/api/admin/exam/list`);
      examData.sort(dynamicSort("name"));
      setExams(examData);
    })();
  }, []);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);

    if (form.checkValidity() === false) {
      return;
    }

    (async () => {
      try {
        const slugGenerated = URLSlugGeneration(
          testData.name.toLowerCase(),
          "-"
        );

        const { tests } = await get(
          `/api/admin/test/all?slug=${slugGenerated}`
        );
        if (tests && tests.length) {
          throw new Error("Test name already exists");
        }
        await post("/api/admin/test", {
          ...testData,
        });
        history.push(`/admin/test`);
      } catch (err) {
        alert(err && err.message ? err.message : "Something went wrong");
      }
    })();
  };

  function URLSlugGeneration(text, separator) {
    var slug = text
      .toLowerCase()
      .replace(/([^a-z0-9\-_]+)/g, separator)
      .replace(new RegExp(separator + "{2,}", "g"), separator);

    if (slug.substr(-1) === separator) {
      slug = slug.substr(0, slug.length - 1);
    }

    return slug;
  }

  function handleChange(e) {
    let value = e.target.value;
    if (e.target.id === "exam") {
      const selectedExam = exams.find((exam) => {
        return exam._id.toString() === e.target.value;
      });

      setLanguagesToSelect(
        selectedExam && selectedExam.available_languages
          ? selectedExam.available_languages
          : []
      );

      setResetMainTest(true);
    }

    if (e.target.id === "is_identical") {
      value = value === "false" || value === false ? false : true;
      setResetMainTest(true);
    }

    if (e.target.id === "section_level_timing") {
      value = value === "false" || value === false ? false : true;
    }

    if (e.target.id === "can_submit_section") {
      value = value === "false" || value === false ? false : true;
    }

    setTestData({
      ...testData,
      [e.target.id]: value,
    });
  }

  function handleSectionChange(e) {
    const [id, index] = e.target.id.split("-");
    let { sections } = { ...testData };
    sections = sections || [];

    sections[index][id] = e.target.value;
    setTestData({
      ...testData,
      sections,
    });
  }

  useEffect(() => {
    if (isResetMainTest) {
      setResetMainTest(false);
      setTestData({
        ...testData,
        main_test_id: "",
      });

      (async function () {
        if (testData.exam && testData.is_identical) {
          const mainTestDataRes = await get(
            `/api/admin/exam/${testData.exam}/test/main-tests`
          );
          mainTestDataRes.sort(dynamicSort("name"));
          setMainTests(mainTestDataRes);
        } else {
          setMainTests([]);
        }
      })();
    }
  }, [isResetMainTest, testData]);

  function isTestQuestionCountValid() {
    var isValid = true;

    if (testData && testData.sections) {
      var total_question_count = 0;

      testData.sections.forEach((section) => {
        total_question_count += isNaN(parseFloat(section.question_count))
          ? 0
          : parseFloat(section.question_count);
      });

      if (total_question_count !== parseFloat(testData.question_count)) {
        isValid = false;
      }
    }

    return isValid;
  }

  function isTestDurationValid() {
    var isValid = true;

    if (testData && testData.sections) {
      var total_duration = 0;

      testData.sections.forEach((section) => {
        total_duration += isNaN(parseFloat(section.duration))
          ? 0
          : parseFloat(section.duration);
      });

      if (total_duration !== parseFloat(testData.duration)) {
        isValid = false;
      }
    }

    return isValid;
  }

  return (
    <Card>
      <Card.Header>
        <h4 className="float-left">Create Test</h4>
      </Card.Header>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Card.Body className="">
          <Form.Row>
            <Form.Group as={Col} md="3" controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter test name"
                onChange={handleChange}
                value={ testData.name || ''}
                onBlur={(e)=>{
                  setTestData({
                    ...testData,
                    name: e.target.value ? e.target.value.trim() : ''
                  })
                }}
              />
              <Form.Control.Feedback type="invalid">
                Test name is required
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="visibility">
              <Form.Label>Visibility</Form.Label>
              <Form.Control as="select" required onChange={handleChange} className="text-capitalize">
                <option value="" key="empty-visibility">
                  Select test visibility
                </option>
                {TEST_VISIBILITY.map((visibility) => (
                  <option
                    key={visibility}
                    className="text-capitalize"
                    value={visibility}
                  >
                    {visibility}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select test visibility
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="exam">
              <Form.Label>Exam</Form.Label>
              <Form.Control
                as="select"
                placeholder="Select"
                required
                value={testData.exam}
                onChange={handleChange}
              >
                <option value="">Select Exam</option>
                {exams.map((exam) => (
                  <option value={exam._id} key={exam._id}>
                    {exam.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select exam
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="language">
              <Form.Label>Test Language</Form.Label>
              <Form.Control
                as="select"
                placeholder="Select"
                required
                value={testData.language}
                onChange={handleChange}
                className="text-capitalize"
              >
                <option value="">Select Test Language</option>
                {languagesToSelect.map((language, index) => (
                  <option value={language} key={index}>
                    {language}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select a language
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="12" controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                required
                as="textarea"
                placeholder="Enter test description"
                value={testData.description}
                className="d-none"
              />
              <CKEditor4
                data={testData.description}
                onChange={(data) => {
                  setTestData({
                    ...testData,
                    description: data,
                  });
                }}
              />
              <Form.Control.Feedback type="invalid">
                Test description is required
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="12" controlId="instructions">
              <Form.Label>Instructions</Form.Label>
              <Form.Control
                required
                as="textarea"
                placeholder="Enter test instructions"
                value={testData.instructions}
                className="d-none"
              />
              <CKEditor4
                data={testData.instructions}
                onChange={(data) => {
                  setTestData({
                    ...testData,
                    instructions: data,
                  });
                }}
              />
              <Form.Control.Feedback type="invalid">
                Test instructions is required
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="6" controlId="is_identical">
              <Form.Label>Is Identical test?</Form.Label>
              <Form.Control
                as="select"
                placeholder="Select"
                required
                value={testData.is_identical}
                onChange={handleChange}
                className="text-capitalize"
              >
                <option value={false}>No</option>
                <option value={true}>Yes</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select a choice
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="main_test_id">
              <Form.Label>Select Main Test</Form.Label>
              <Form.Control
                as="select"
                placeholder="Select"
                required={testData.is_identical}
                disabled={!testData.is_identical}
                value={testData.main_test_id}
                onChange={handleChange}
                className="text-capitalize"
              >
                <option key={`main-test-opt`} value={""}>
                  Select Main test
                </option>
                {mainTests.map((test) => (
                  <option key={test._id} value={test._id}>
                    {test.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select a main test ( Required for Identical tests )
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="question_count">
              <Form.Label>Total Questions</Form.Label>
              <Form.Control
                isInvalid={validated && !isTestQuestionCountValid()}
                required
                type="number"
                min={1}
                step={1}
                placeholder="Enter question count"
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Question count &gt;0 is required and if sections available, Total
                Questions should be equal to all section's.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="duration">
              <Form.Label>Duration (in minutes)</Form.Label>
              <Form.Control
                isInvalid={validated && !isTestDurationValid()}
                required
                type="number"
                min={1}
                step={1}
                placeholder="Enter test duration"
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Duration &gt;0 is required and if sections available, Total
                Duration should be equal to sum of all section's.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="difficulty_level">
              <Form.Label>Difficulty Level</Form.Label>
              <Form.Control
                className="text-capitalize"
                required
                as="select"
                onChange={handleChange}
              >
                <option value="">Select Difficulty Level</option>
                {[
                  "beginner",
                  "basic",
                  "intermediate",
                  "advanced",
                  "expert",
                ].map((difficulty) => (
                  <option
                    className="text-capitalize"
                    key={difficulty}
                    value={difficulty}
                  >
                    {difficulty}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select the test difficulty level
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="marks_per_question">
              <Form.Label>Marks per question</Form.Label>
              <Form.Control
                required
                type="number"
                min={0.01}
                step={0.01}
                placeholder="Enter marks per question"
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Marks per question is required and should be a +ve no.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="negative_marks_per_question">
              <Form.Label>Negative Marks per question</Form.Label>
              <Form.Control
                required
                type="number"
                min={0}
                step={0.01}
                placeholder="Enter negative marks per question"
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Negative marks per question is required and should be a non -ve
                no.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="price">
              <Form.Label>Price</Form.Label>
              <Form.Control
                required
                type="number"
                min={0}
                placeholder="Enter test price"
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Test price is required and must be a not -ve no.
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Button
              variant="primary"
              onClick={() => {
                let { sections } = { ...testData };
                sections = sections || [];
                sections.push({});
                setTestData({
                  ...testData,
                  sections,
                });
              }}
            >
              <FontAwesomeIcon icon={faPlus} /> Add section{" "}
              {testData.sections && testData.sections.length
                ? `(${testData.sections.length})`
                : ""}
            </Button>
          </Form.Row>
          <br />
          {testData.sections && testData.sections.length ? (
            <>
              <Form.Row>
                <Form.Group as={Col} md="3" controlId="section_level_timing">
                  <Form.Label> Section Level Timing </Form.Label>
                  <Form.Control
                    value={testData.section_level_timing}
                    className="text-capitalize"
                    as="select"
                    onChange={handleChange}
                  >
                    {[
                      {
                        key: "no",
                        value: false,
                      },
                      {
                        key: "yes",
                        value: true,
                      },
                    ].map((op) => (
                      <option
                        className="text-capitalize"
                        key={op.key}
                        value={op.value}
                      >
                        {op.key}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                {testData.section_level_timing ? (
                  <Form.Group as={Col} md="3" controlId="can_submit_section">
                    <Form.Label> Allow Manual Submit </Form.Label>
                    <Form.Control
                      value={testData.can_submit_section ? true : false}
                      className="text-capitalize"
                      as="select"
                      onChange={handleChange}
                    >
                      {[
                        {
                          key: "no",
                          value: false,
                        },
                        {
                          key: "yes",
                          value: true,
                        },
                      ].map((op) => (
                        <option
                          className="text-capitalize"
                          key={op.key}
                          value={op.value}
                        >
                          {op.key}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                ) : (
                  ""
                )}
              </Form.Row>
              {testData.sections.map((section, index) => (
                <Form.Row key={`section-${index}`}>
                  <Form.Group as={Col} md="2" controlId={`name-${index}`}>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter section name"
                      onChange={handleSectionChange}
                      onBlur={() => {
                        let { sections } = testData;

                        sections[index].name = sections[index].name
                          ? sections[index].name.trim()
                          : "";

                        setTestData({
                          ...testData,
                          sections,
                        });
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Section name is required
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="2" controlId={`duration-${index}`}>
                    <Form.Label>Duration (in minutes)</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      min={1}
                      step={1}
                      placeholder="Enter test duration"
                      onChange={handleSectionChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      Duration &gt;0 is required.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="2"
                    controlId={`marks_per_question-${index}`}
                  >
                    <Form.Label> Question mark </Form.Label>
                    <Form.Control
                      required
                      type="number"
                      min={0.01}
                      step={0.01}
                      placeholder="Enter marks per question"
                      onChange={handleSectionChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      Marks per question is required and should be a +ve no.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="2"
                    controlId={`negative_marks_per_question-${index}`}
                  >
                    <Form.Label> -ve question mark </Form.Label>
                    <Form.Control
                      required
                      type="number"
                      min={0}
                      step={0.01}
                      placeholder="Enter negative marks per question"
                      onChange={handleSectionChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      Negative marks per question is required and should be a
                      non -ve no.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="2"
                    controlId={`question_count-${index}`}
                  >
                    <Form.Label>Total Questions</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      min={1}
                      step={1}
                      placeholder="Enter question count"
                      onChange={handleSectionChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      Question count &gt;0 is required.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="1">
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      color="black"
                      style={{
                        marginTop: "37px",
                        fontSize: "28px",
                        marginLeft: "14px",
                        cursor: "pointer",
                      }}
                      title="Remove"
                      onClick={() => {
                        let { sections } = { ...testData };
                        sections.splice(index, 1);
                        console.log(sections);
                        setTestData({
                          ...testData,
                          sections: [...sections],
                        });
                      }}
                    />
                  </Form.Group>
                </Form.Row>
              ))}
            </>
          ) : (
            ""
          )}
        </Card.Body>
        <Card.Footer>
          <Link to={`/admin/test`}>
            <Button variant="secondary">Back</Button>
          </Link>{" "}
          <Button type="submit">Save</Button>
        </Card.Footer>
      </Form>
    </Card>
  );
}

export default CreateTest;
