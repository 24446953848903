import ListSubjectPage from "../pages/subject/ListSubjectPage.js";
import CreateSubjectPage from "../pages/subject/CreateSubjectPage.js";
import EditSubjectPage from "../pages/subject/EditSubjectPage.js";
import ListChapterPage from "../pages/subject/ListChapterPage.js";
import CreateChapterPage from "../pages/subject/CreateChapterPage.js";
import EditChapterPage from "../pages/subject/EditChapterPage.js";
import ListTopicPage from "../pages/subject/ListTopicPage.js";
import CreateTopicPage from "../pages/subject/CreateTopicPage.js";
import EditTopicPage from "../pages/subject/EditTopicPage.js";

const subject = [
  {
    path: '/admin/subject',
    title: 'List Subject Page',
    props: {},
    component: ListSubjectPage
  },
  {
    path: '/admin/subject/create',
    title: 'Create Subject Page',
    props: {},
    component: CreateSubjectPage
  },
  {
    path: '/admin/subject/:subjectId/edit',
    title: 'Edit Subject Page',
    props: {},
    component: EditSubjectPage
  },
  {
    path: '/admin/subject/:subjectId/chapter',
    title: 'List Chapter Page',
    props: {},
    component: ListChapterPage
  },
  {
    path: '/admin/subject/:subjectId/chapter/create',
    title: 'Create Chapter Page',
    props: {},
    component: CreateChapterPage
  },
  {
    path: '/admin/subject/:subjectId/chapter/:chapterId/edit',
    title: 'Edit Chapter Page',
    props: {},
    component: EditChapterPage
  },
  {
    path: '/admin/subject/:subjectId/chapter/:chapterId/topic',
    title: 'List Topic Page',
    props: {},
    component: ListTopicPage
  },
  {
    path: '/admin/subject/:subjectId/chapter/:chapterId/topic/create',
    title: 'Create Topic Page',
    props: {},
    component: CreateTopicPage
  },
  {
    path: '/admin/subject/:subjectId/chapter/:chapterId/topic/:topicId/edit',
    title: 'Edit Topic Page',
    props: {},
    component: EditTopicPage
  }
];

export default subject;