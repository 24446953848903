import React from "react";

export default function PaginationComponent(props) {
  function getAdjacentPages(
    totalCount,
    limit = null,
    current = null,
    adjacents = null
  ) {
    let pages = [];

    if (!totalCount || !limit) {
      return pages;
    }

    const totalPages = Math.ceil(totalCount / limit);
    pages = Array.apply(null, Array(totalPages)).map((_, i) => {
      return i + 1;
    });

    if (!current || !adjacents) {
      return pages;
    }

    if ((adjacents = Math.floor(adjacents / 2) * 2 + 1) >= 1) {
      const startOffset = Math.max(
        0,
        Math.min(
          totalPages - adjacents,
          parseInt(current) - Math.ceil(adjacents / 2)
        )
      );
      const endPosition = startOffset + adjacents;
      pages = pages.slice(startOffset, endPosition);
    }

    return pages;
  }

  function changePage(e) {
    e.preventDefault();
    props.onChange({
      ...props.filters,
      page: parseInt(e.currentTarget.dataset.pageNum),
    });
  }

  function getTotalPageCount() {
    return props.totalCount
      ? Math.ceil(parseInt(props.totalCount) / parseInt(props.limit))
      : 1;
  }

  function getFirstPageLi() {
    const isCurrentPage = props.currentPage === 1;

    if (isCurrentPage) {
      return (
        <li className="page-item disabled">
          <a className="page-link" href="#!" tabIndex="-1" aria-disabled="true">
            &laquo;&nbsp;First
          </a>
        </li>
      );
    }
    return (
      <li className="page-item">
        <a
          className="page-link"
          href="#!"
          onClick={changePage}
          data-page-num="1"
          aria-label="First"
        >
          <span area-hidden="true">&laquo;&nbsp;First</span>
        </a>
      </li>
    );
  }

  function getLastPageLi() {
    const isCurrentPage = props.currentPage === getTotalPageCount();

    if (isCurrentPage) {
      return (
        <li className="page-item disabled">
          <a className="page-link" href="#!" tabIndex="-1" aria-disabled="true">
            Last&nbsp;&raquo;
          </a>
        </li>
      );
    }
    return (
      <li className="page-item">
        <a
          className="page-link"
          href="!#"
          onClick={changePage}
          data-page-num={getTotalPageCount()}
          aria-label="Last"
        >
          <span area-hidden="true">Last&nbsp;&raquo;</span>
        </a>
      </li>
    );
  }

  function getPagesLi() {
    const pages = getAdjacentPages(
      props.totalCount,
      props.limit,
      props.currentPage,
      8
    );

    let pagesLi = [];
    pages.forEach((pageNum, index) => {
      const isCurrentPage = pageNum === props.currentPage;
      if (isCurrentPage) {
        pagesLi.push(
          <li className="page-item active" key={index} aria-current="page">
            <a
              className="page-link"
              href="#!"
              onClick={(e) => e.preventDefault()}
            >
              {pageNum}
              <span className="sr-only">(current)</span>
            </a>
          </li>
        );
        return;
      }
      pagesLi.push(
        <li className="page-item" key={index}>
          <a
            className="page-link"
            href="#!"
            onClick={changePage}
            data-page-num={pageNum}
          >
            {pageNum}
          </a>
        </li>
      );
    });
    return pagesLi;
  }

  function gotToPage(e) {
    e.preventDefault();
    const pageNum = document.getElementById("target-page").value;
    if (pageNum) {
      props.onChange({
        ...props.filters,
        page: parseInt(pageNum),
      });
    }
  }

  return (
    <nav className="bg-transparent">
      <ul className="pagination float-left w-100">
        {getFirstPageLi()}
        {getPagesLi()}
        {getLastPageLi()}
        <form autoComplete="off" onSubmit={gotToPage}>
          <div className="page-item">
            <div className="d-inline-block">
              <span
                className="page-link bg-secondary text-dark"
                title="Go to page"
              >
                Enter page no.
              </span>
            </div>
            <div className="d-inline-block">
              <span>
                <input
                  style={{ width: "80px" }}
                  id="target-page"
                  type="number"
                  min="1"
                  step="1"
                  max={getTotalPageCount()}
                  className="form-control small-input"
                  title="Go to page"
                  required
                />
              </span>
            </div>
            <div className="d-inline-block">
              <span className="input-group-btn">
                <button className="btn btn-primary" type="submit">
                  GO »
                </button>
              </span>
            </div>
          </div>
        </form>
      </ul>
    </nav>
  );
}