import React, {} from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
function Sidebar({ currentStep, answerCount, questionId, optionNumber }) {
  
  const lists = [];

  const appendStep = function (step_number) {
    let elem_text = "";
    const style = { backgroundColor: "transparent" };
    if (currentStep === step_number) {
      style.backgroundColor = "yellow";
    }

    switch (step_number) {
      case 1:
        elem_text = "Basic Details - Step 1";
        break;
      case 2:
        elem_text = "Question - Step 2";
        break;
      case 4:
        elem_text = "Solution - Step 4";
        break;
      case 5:
        elem_text = "Preview - Step 5";
        break;
      case 6:
        elem_text = "Add to Test - Step 6 (Final)";
        break;
      default:
    }

    lists.push(
      <li style={style} key={`step-${step_number}`}>
        <Link
          to={
            "/admin/question/edit/steps/step" + step_number + "/" + questionId
          }
        >
          {elem_text}
        </Link>
      </li>
    );
  };

  const addAnswerHeader = function () {
    let steps = [];

    for (let answer = 1; answer <= answerCount; answer++) {
      const style = { backgroundColor: "transparent" };
      if (parseInt(optionNumber) === answer) {
        style.backgroundColor = "yellow";
      }

      steps.push(
        <li style={style} key={`option-${answer}`}>
          <Link
            to={`/admin/question/edit/steps/step3/${questionId}/answer/${answer}`}
          >
            Option {answer}
          </Link>
        </li>
      );
    }

    lists.push(
      <li key={`step-3`}>
        <a
          href="#!"
          className="text-secondary disabled"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          Provide Answer(s) - Step 3
        </a>
        <ul>{steps}</ul>
      </li>
    );
  };

  if( questionId ) {
    for (let step = 1; step <= 6; step++) {
      if (step === 3) {
        addAnswerHeader();
      } else {
        appendStep(step);
      }
    }
  }

  return (
    <Card border="dark">
      <Card.Header>Question Navigation</Card.Header>
      <Card.Body>
        <ul>
          {lists}
        </ul>
      </Card.Body>
    </Card>
  );
}

export default React.memo( Sidebar );
