import React, { useEffect, useState } from "react";
import { Card, Button, Table } from "react-bootstrap";

import queryString from "query-string";
import { Link } from "react-router-dom";

import { get, deleteRequest } from "../../../helpers/RequestHelper";
import TestRow from "./TestRow";
import Pagination from "../../../components/pagination/Pagination";
import Filter from "./Filter";

import PreviewModal from "./PreviewModal";

const LIMIT = 10;
const ListTest = function () {
  const [tests, setTests] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [filters, setFilters] = useState({
    limit: LIMIT,
    page: 1,
  });
  const [showPreview, setShowPreview] = useState(false);
  const [currentTest, setCurrentTest] = useState(null);

  useEffect(() => {
    (async function getTestData() {
      const stringified = queryString.stringify(filters);
      const testData = await get(`/api/admin/test?${stringified}`);
      const { tests: testList, total_count: count } = testData;

      setTests(testList);
      setTotalCount(count);
    })();
  }, [filters]);

  async function onFilterChange(newFilters) {
    setFilters({
      ...filters,
      ...newFilters,
    });
  }

  async function toggleStatus(test_id) {
    try {
      await deleteRequest("/api/admin/test/" + test_id);
      onFilterChange({});
    } catch (err) {

      if( typeof err.error === "string") {
        alert( err.error );
      }
      if (err && err.error && err.error.message) {
        alert(err.error.message);
      }
    }
  }

  const handlePreviewClose = (test) => {
    setShowPreview(false);
    setCurrentTest(null);
  };
  const handlePreviewShow = (test) => {
    setShowPreview(true);
    setCurrentTest(test);
  };

  return (
    <>
      <Filter onChange={onFilterChange} />
      <Card>
        <Card.Header>
          <h4 className="float-left">Manage Tests</h4>
          <Link to={`/admin/test/create`} className="float-right">
            <Button variant="info">Add New</Button>
          </Link>
          <p className="float-right m-2">Total Tests: {totalCount}</p>
        </Card.Header>
        <Card.Body className="p-0">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th style={{ width: "12%" }}>Name</th>
                <th>Description</th>
                <th>Exam</th>
                <th>Created By</th>
                <th style={{ width: "12%" }}>Created On</th>
                <th style={{ width: "12%" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {tests.map((test, index) => (
                <TestRow
                  key={test._id}
                  test={test}
                  handlePreviewShow={handlePreviewShow}
                  toggleStatus={toggleStatus}
                  testIndex={(filters.page - 1) * LIMIT + index + 1}
                />
              ))}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer>
          {tests && tests.length ? (
            <Pagination
              currentPage={filters.page}
              limit={LIMIT}
              totalCount={totalCount}
              onChange={onFilterChange}
              filters={filters}
            />
          ) : (
            ""
          )}
        </Card.Footer>
      </Card>
      {currentTest ? (
        <PreviewModal
          show={showPreview}
          handleClose={handlePreviewClose}
          currentTest={currentTest}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default ListTest;
