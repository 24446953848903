import homeRoutes from "./home";
import examRoutes from "./exam";
import subjectRoutes from "./subject";
import testRoutes from "./test";
import questiondirectionRoutes from "./questiondirection";
import questionRoutes from "./question";
import userRoutes from "./user";
import rolesRoutes from "./roles";
//import publicRoutes from './public';

const allRoutes = [
    ...homeRoutes,
    ...examRoutes,
    ...subjectRoutes,
    ...testRoutes,
    ...questiondirectionRoutes,
    ...questionRoutes,
    ...userRoutes,
    ...rolesRoutes,
    //...publicRoutes
]

export default allRoutes;