/* eslint-disable no-throw-literal */
import axios from 'axios';

export const get = async (url, headers = {}) => {

    const response = await axios.get(url, {
        headers
    });

    if (!response.data.status) {

        throw ({
            error: response.data.error
        });
    }

    return response.data.data;
}

export const deleteRequest = async (url, headers = {}) => {

    const response = await axios.delete(url, {
        headers
    });

    if (!response.data.status) {

        throw ({
            error: response.data.error
        });
    }

    return response.data.data;
}

export const getAll = async (url, headers = {}) => {

    const response = await axios.get(url, {
        headers
    });
    if (!response.data.status) {

        throw ({
            error: response.data.error
        });
    }

    return response.data;
}

export const post = async (url, data = {}, headers = {}) => {

    const response = await axios.post(url, data, {
        headers
    });
    if (!response.data.status) {

        throw ({
            error: response.data.error
        });
    }

    return response.data.data;
}


export const put = async (url, data = {}, headers={}) => {

    const response = await axios.put(url, data, {
        headers
    });
    if (!response.data.status) {

        throw ({
            error: response.data.error
        });
    }

    return response.data.data;
}