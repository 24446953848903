import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Form } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { get, post, put } from "../../../helpers/RequestHelper";
import { dynamicSort } from "../../../helpers/UtilityHelper";

import Select from "react-select";

import queryString from "query-string";

import Sidebar from "./Sidebar";

const DIFFICULTIES = [
  "beginner",
  "basic",
  "intermediate",
  "advanced",
  "expert",
];

function Step1() {
  const history = useHistory();
  let { questionId } = useParams();

  const [validated, setValidated] = useState(false);
  const [questionData, setQuestionData] = useState({});
  const [availableSubjects, setAvailableSubjects] = useState([]);
  const [availableChapters, setAvailableChapters] = useState([]);
  const [availableTopics, setAvailableTopics] = useState([]);
  const [availableExams, setAvailableExams] = useState([]);
  const [languagesToSelect, setLanguagesToSelect] = useState([]);
  const [availableTags, setAvailableTags] = useState([]);

  useEffect(() => {
    (async function () {
      try {
        const question = await get(`/api/admin/question/${questionId}`);
        console.log(question);
        setQuestionData({
          ...question,
          exams: question.exams.map((exam) => exam._id),
        });
        const { subjects } = await get(`/api/admin/subject`);
        const tags = await get(`/api/admin/tag`);
        subjects.sort(dynamicSort("name"));
        //tags.sort(dynamicSort("name"));
        setAvailableSubjects(subjects);
        setAvailableTags(tags);
        if (question.subject) {
          loadChapters(question.subject);
        }

        if (question.subject && question.topic) {
          loadTopics(question.chapter, question.topic);
        }
      } catch (err) {}
    })();
  }, [questionId]);

  useEffect(() => {
    if (questionData.subject) {
      (async function getExamData() {
        const stringified = queryString.stringify({
          subjectId: questionData.subject,
          chapterId: questionData.chapter,
          topicId: questionData.topic,
        });
        const { exams } = await get(`/api/admin/exam?${stringified}`);
        exams.sort(dynamicSort("name"));
        setAvailableExams(exams);
      })();
    }
  }, [questionData.subject, questionData.chapter, questionData.topic]);

  useEffect(() => {
    (async () => {
      if (
        !questionData.exams ||
        !Array.isArray(questionData.exams) ||
        !questionData.exams.length
      ) {
        setLanguagesToSelect([]);
      } else {
        const languages = await get(
          `/api/admin/exam/distinct/language?${queryString.stringify({
            exam_ids: [...questionData.exams],
          })}`
        );
        setLanguagesToSelect(languages);
      }
    })();
  }, [questionData.exams]);

  const loadChapters = async (subject_id) => {
    setAvailableChapters([]);
    setAvailableTopics([]);

    if (!subject_id) {
      return;
    }

    try {
      const { chapters } = await get(
        `/api/admin/subject/${subject_id}/chapter`
      );
      chapters.sort(dynamicSort("name"));
      setAvailableChapters(chapters);
    } catch (err) {}
  };

  const loadTopics = async (chapter_id, subject_id) => {
    setAvailableTopics([]);

    if (!chapter_id) {
      return;
    }

    try {
      const { topics } = await get(
        `/api/admin/chapter/${chapter_id}/topic?subjectId=${subject_id}`
      );
      topics.sort(dynamicSort("name"));
      setAvailableTopics(topics);
    } catch (err) {}
  };

  const handleChange = (event) => {
    const target = event.target;
    const { id } = target;
    let { value } = target;

    if (id === "exams") {
      const options = target.options;
      value = [];
      for (let i = 0, l = options.length; i < l; i++) {
        if (options[i].selected) {
          value.push(options[i].value);
        }
      }
    }

    const newData = {
      [id]: value,
    };

    if (id === "subject") {
      newData["topic"] = "";
      newData["chapter"] = "";
      loadChapters(value);
    }

    if (id === "chapter") {
      newData["topic"] = "";
      loadTopics(value, questionData.subject);
    }

    setQuestionData({
      ...questionData,
      ...newData,
    });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    if (form.checkValidity() === false) {
      return;
    }
    const dataToSave = { ...questionData };
    (async () => {
      try {
        // create the question
        if (dataToSave.newtag) {
          const tag = await post("/api/admin/tag", { name: dataToSave.newtag });
          delete dataToSave.newtag;
          dataToSave.tag = tag._id;
          setQuestionData({
            ...dataToSave,
          });

          setAvailableTags([...availableTags, tag]);
        }
        await put(`/api/admin/question/${questionId}`, { ...dataToSave });
        history.push(`/admin/question/edit/steps/step2/${questionId}`);
      } catch (err) {
        alert(err && err.message ? err.message : "Something went wrong");
      }
    })();
  };

  return (
    <Row>
      <Col md="3">
        <Sidebar
          currentStep={1}
          answerCount={questionData.answer_choice_count}
          questionId={questionData._id}
          optionNumber={null}
        />
      </Col>
      <Col md="9">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Card border="dark">
            <Card.Header>
              Provide Question's Basic Info - Step 1
              <div className="float-right">
                <Link to="/admin/question">
                  <Button variant="secondary">Back</Button>
                </Link>{" "}
                <Button variant="primary" type="submit">
                  Save &amp; Next
                </Button>
              </div>
            </Card.Header>
            {questionData._id ? (
              <Card.Body>
                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="subject">
                    <Form.Label>Subject</Form.Label>
                    <Form.Control
                      className="text-capitalize"
                      required
                      as="select"
                      placeholder="Select Subject name"
                      value={questionData.subject}
                      onChange={handleChange}
                    >
                      <option value="">Select Subject</option>
                      {availableSubjects.map((subject) => (
                        <option key={subject._id} value={subject._id}>
                          {subject.name}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Please select subject
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>

                {availableChapters.length ? (
                  <Form.Row>
                    <Form.Group as={Col} md="12" controlId="chapter">
                      <Form.Label>Chapter</Form.Label>
                      <Form.Control
                        className="text-capitalize"
                        as="select"
                        value={questionData.chapter}
                        onChange={handleChange}
                      >
                        <option value="">Select Chapter</option>
                        {availableChapters.map((chapter) => (
                          <option key={chapter._id} value={chapter._id}>
                            {chapter.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Form.Row>
                ) : (
                  ""
                )}

                {availableTopics.length ? (
                  <Form.Row>
                    <Form.Group as={Col} md="12" controlId="topic">
                      <Form.Label>Topic</Form.Label>
                      <Form.Control
                        className="text-capitalize"
                        as="select"
                        value={questionData.topic}
                        onChange={handleChange}
                      >
                        <option value="">Select Topic</option>
                        {availableTopics.map((topic) => (
                          <option key={topic._id} value={topic._id}>
                            {topic.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Form.Row>
                ) : (
                  ""
                )}
                {questionData.subject ? (
                  <Form.Row>
                    <Form.Group as={Col} md="12" controlId="exams">
                      <Form.Label>Please select exams</Form.Label>
                      <Form.Control
                        required
                        as="select"
                        multiple
                        value={questionData.exams}
                        onChange={handleChange}
                      >
                        {availableExams.map((exam) => (
                          <option key={exam._id} value={exam._id}>
                            {exam.name}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please select Exams
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                ) : (
                  ""
                )}

                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="tag">
                    <Form.Label>Tag</Form.Label>
                    <Select
                      value={(() => {
                        const tag = availableTags.find((tag) => {
                          return tag._id === questionData.tag;
                        });

                        if (!tag) {
                          return null;
                        }
                        return {
                          value: tag._id,
                          label: tag.name,
                        };
                      })()}
                      options={(() => {
                        let options = [{ value: "", label: "Select a tag" }];
                        availableTags.forEach((tag) => {
                          options.push({
                            value: tag._id,
                            label: tag.name,
                          });
                        });
                        options.push({
                          value: "other",
                          label: "Create New Tag",
                        });
                        return options;
                      })()}
                      onChange={(selectedOption) => {
                        setQuestionData({
                          ...questionData,
                          tag: selectedOption.value,
                        });
                      }}
                    />
                    <Form.Control
                      className="d-none"
                      required
                      as="select"
                      placeholder="Select a tag"
                      value={questionData.tag}
                      onChange={() => {}}
                    >
                      <option value="">Select a Tag</option>
                      {availableTags.map((tag) => (
                        <option key={tag._id} value={tag._id}>
                          {tag.name}
                        </option>
                      ))}
                      <option value="other">Create New Tag</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Tag is required
                    </Form.Control.Feedback>
                  </Form.Group>
                  {questionData.tag === "other" ? (
                    <Form.Group as={Col} md="12" controlId="newtag">
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter tag name"
                        onChange={handleChange}
                        onBlur={() => {
                          setQuestionData({
                            ...questionData,
                            newtag: questionData.newtag
                              ? questionData.newtag.trim()
                              : "",
                          });
                        }}
                        value={questionData.newtag || ""}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Tag is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  ) : (
                    ""
                  )}
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="difficulty_level">
                    <Form.Label>Difficulty Level</Form.Label>
                    <Form.Control
                      required
                      className="text-capitalize"
                      as="select"
                      placeholder="Select Difficulty"
                      value={questionData.difficulty_level}
                      onChange={handleChange}
                    >
                      <option value="">Select Difficulty</option>
                      {DIFFICULTIES.map((difficulty) => (
                        <option key={difficulty} value={difficulty}>
                          {difficulty}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Please select the question's difficulty level
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="language">
                    <Form.Label>Question Language</Form.Label>
                    <Form.Control
                      required
                      as="select"
                      className="text-capitalize"
                      value={questionData.language}
                      onChange={handleChange}
                    >
                      <option value="">Select</option>
                      {languagesToSelect.map((language) => (
                        <option key={language} value={language}>
                          {language}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Please select a language
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="answer_choice_count">
                    <Form.Label>Answer Choice Count</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      min={2}
                      max={6}
                      step={1}
                      onChange={handleChange}
                      value={questionData.answer_choice_count || ""}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please the Choice count ( Range [2:6])
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
              </Card.Body>
            ) : (
              ""
            )}
            <Card.Footer>
              <div className="float-right">
                <Link to="/admin/question">
                  <Button variant="secondary">Back</Button>
                </Link>{" "}
                <Button variant="primary" type="submit">
                  Save &amp; Next
                </Button>
              </div>
            </Card.Footer>
          </Card>
        </Form>
      </Col>
    </Row>
  );
}
export default Step1;
