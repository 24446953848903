import React, { useEffect, useState } from "react";
import { Card, Button, Form, Row, Col, Table } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../components/pagination/Pagination";
import { get, deleteRequest } from "../../helpers/RequestHelper";
import queryString from "query-string";
const LIMIT = 10;

function Subject(props) {
  let history = useHistory();
  const handleClick = () => {
    history.push("/admin/subject/create");
  };

  const [subjects, setSubject] = useState([]);
  const [filters, setFilters] = useState({
    limit: LIMIT,
    page: 1,
  });
  const [totalCount, setTotalCount] = useState(0);
  useEffect(() => {
    getSubjectData();
  }, [filters]);

  async function getSubjectData() {
    try {
      const stringified = queryString.stringify(filters);
      const subjectData = await get(`/api/admin/subject?${stringified}`);
      setSubject(subjectData.subjects);
      setTotalCount(subjectData.total_count);
    } catch (err) {
      setSubject([]);
    }
  }

  async function onFilterChange(newFilters) {
    setFilters({
      ...filters,
      ...newFilters,
    });
  }

  async function toggleStatus(subject_id) {
    try {
      await deleteRequest("/api/admin/subject/" + subject_id);
      await getSubjectData();
    } catch (err) {}
  }

  const subjectRows = subjects.map((subject, index) => {
    let indexCount = (filters.page - 1) * LIMIT + index + 1;
    return (
      <tr key={subject._id} className={subject.status === 1 ? "" : "bg-danger"}>
        <td>{indexCount}</td>
        <td>{subject.name}</td>
        <td>{subject.description}</td>
        <td>{subject.created_by.name}</td>
        <td>{new Date(subject.created_on).toLocaleString()}</td>
        <td>
          <Link
            to={`/admin/subject/${subject._id}/chapter`}
            className="btn"
            style={{ color: "blue" }}
          >
            Manage Chapters
          </Link>
          <Link
            to={`/admin/subject/${subject._id}/edit`}
            className="btn"
            title="Edit"
          >
            <FontAwesomeIcon icon={faEdit} color="blue" />
          </Link>
          {subject.status === 1 ? (
            <Button variant="link" onClick={() => toggleStatus(subject._id)} title="Disable">
              <FontAwesomeIcon icon={faTimes} color="red" />
            </Button>
          ) : (
            <Button variant="link" onClick={() => toggleStatus(subject._id)} title="Enable">
              <FontAwesomeIcon icon={faCheck} color="blue" />
            </Button>
          )}
        </td>
      </tr>
    );
  });

  return (
    <>
      <Filter onChange={onFilterChange} />
      <Card>
        <Card.Header>
          <h4 className="float-left">Manage Subjects</h4>
          <Button variant="info" onClick={handleClick} className="float-right">
            Add New
          </Button>
        </Card.Header>
        <Card.Body className="p-0">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Description</th>
                <th>Created By</th>
                <th>Created On</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{subjectRows}</tbody>
          </Table>
        </Card.Body>
        <Card.Footer>
          <Pagination
            currentPage={filters.page}
            limit={LIMIT}
            totalCount={totalCount}
            onChange={onFilterChange}
            filters={{}}
          />
        </Card.Footer>
      </Card>
    </>
  );
}
export default Subject;

function Filter(props) {
  function onSubmit(e) {
    e.preventDefault();
    props.onChange({
      text: document.getElementById("name").value,
      page: 1,
    });
  }

  function onReset(e) {
    e.preventDefault();
    props.onChange({
      text: "",
      page: 1,
    });
    document.getElementById("name").value="";
  }

  return (
    <Form onSubmit={onSubmit} onReset={onReset}>
      <fieldset className="filters">
        <legend align="left">Filters:</legend>
        <div className="filters-body">
          <Row>
            <Col md="3">
              <Form.Group controlId="name">
                <Form.Label>Subject</Form.Label>
                <Form.Control as="input" placeholder="Enter text" />
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className="filters-footer">
          <Button type="reset" variant="outline-secondary">
            Reset
          </Button>{" "}
          <Button type="submit" variant="primary">
            Submit
          </Button>
        </div>
      </fieldset>
    </Form>
  );
}
