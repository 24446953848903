import React, { useEffect, useState } from "react";
import { Card, Button, Form } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { get, put } from "../../helpers/RequestHelper";
function Exam() {
  let history = useHistory();

  const [collection, setCollection] = useState(null);
  let { examId, collectionId } = useParams();

  useEffect(() => {
    getCollectionData(examId, collectionId);
  }, [collectionId, examId]);

  function handleChange(event) {
    setCollection({
      ...collection,
      [event.target.id]: event.target.value,
    });
  }

  function handleBlur(event) {
    setCollection({
      ...collection,
      [event.target.id]: event.target.value.trim(),
    });
  }

  function goBack() {
    history.push(`/admin/exam/${examId}/collection`);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      await put(`/api/admin/exam/collection/update`, {
        collectionId,
        description: collection.description,
        examId,
        name: collection.name,
        test_name_prefix: collection.test_name_prefix,
      });
      goBack();
    } catch (err) {}
  }

  async function getCollectionData(examId, collectionId) {
    try {
      const collectionData = await get(
        "/api/admin/exam/" + examId + "/collection/" + collectionId
      );

      setCollection(collectionData);
    } catch (err) {
      setCollection(null);
    }
  }

  return (
    <Card>
      <Card.Header>
        <h4 className="float-left">Update Collection</h4>
      </Card.Header>
      <Form onSubmit={handleSubmit}>
        <Card.Body>
          {collection ? (
            <>
              <Form.Group
                controlId="name"
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <Form.Label
                  className="float-left"
                  style={{ marginLeft: "5px", marginTop: "5px" }}
                >
                  Name
                </Form.Label>
                <Form.Control
                  as="input"
                  defaultValue={collection.name}
                  required
                  placeholder="Name"
                  value={collection.name}
                />
              </Form.Group>
              <Form.Group
                controlId="description"
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <Form.Label
                  className="float-left"
                  style={{ marginLeft: "5px", marginTop: "20px" }}
                >
                  Description
                </Form.Label>
                <Form.Control
                  as="textarea"
                  defaultValue={collection.description}
                  rows="3"
                  placeholder="Description"
                  required
                  value={collection.description}
                />
              </Form.Group>
              <Form.Group
                controlId="test_name_prefix"
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <Form.Label
                  className="float-left"
                  style={{ marginLeft: "5px", marginTop: "5px" }}
                >
                  Test Name Prefix
                </Form.Label>
                <Form.Control
                  as="input"
                  defaultValue={collection.test_name_prefix}
                  placeholder="Enter Test Name Prefix"
                  required
                  value={collection.test_name_prefix}
                />
              </Form.Group>
            </>
          ) : (
            ""
          )}
        </Card.Body>
        <Card.Footer>
          <Button
            type="button"
            onClick={goBack}
            className="float-left"
            style={{ color: "white" }}
          >
            Back
          </Button>
          &nbsp;
          <Button variant="primary" type="submit">
            {" "}
            Update{" "}
          </Button>
        </Card.Footer>
      </Form>
    </Card>
  );
}
export default Exam;
