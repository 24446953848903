import ListQuestionDirectionPage from "../pages/questiondirection/ListQuestionDirectionPage.js";
import CreateQuestionDirectionPage from "../pages/questiondirection/CreateQuestionDirectionPage.js";
import EditQuestionDirectionPage from "../pages/questiondirection/EditQuestionDirectionPage.js";

const questiondirection = [
  {
    path: '/admin/questiondirection',
    title: 'List Question direction Page',
    props: {},
    component: ListQuestionDirectionPage
  },
  {
    path: '/admin/questiondirection/create',
    title: 'Create Question direction Page',
    props: {},
    component: CreateQuestionDirectionPage
  },
  {
    path: '/admin/questiondirection/:directionId/edit',
    title: 'Edit Question direction Page',
    props: {},
    component: EditQuestionDirectionPage
  }
];

export default questiondirection;