import React from "react";
import { Spinner, Row, Col } from "react-bootstrap";

export default function () {
  return (
    <Row className="h-100 w-100 position-fixed">
      <Col md="12" className="text-center  my-auto">
        <Spinner animation="grow" />
      </Col>
    </Row>
  );
}