import React from "react";

function Exam() {
  return (
    <div>
      <h3 style={{textAlign:"center"}}>This page is no longer in use!!</h3>
    </div>
  );
}
export default Exam;
