import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { get } from "../../../helpers/RequestHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import Sidebar from "./Sidebar";

const getCharacterCode = function (value) {
  return String.fromCharCode(value);
};

function Step4() {
  let { questionId } = useParams();

  const [questionData, setQuestionData] = useState({});

  useEffect(() => {
    (async function () {
      try {
        const question = await get(`/api/admin/question/${questionId}`);
        setQuestionData(question);
      } catch (err) {}
    })();
  }, [questionId]);

  useEffect(() => {
    if (questionData._id) {
      // eslint-disable-next-line no-undef
      MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    }
  }, [questionData]);

  return (
    <Row>
      <Col md="3">
        <Sidebar
          currentStep={5}
          answerCount={questionData.answer_choice_count}
          questionId={questionData._id}
          optionNumber={null}
        />
      </Col>
      <Col md="9">
        <Card border="dark">
          <Card.Header>
            Preview Question - Step 5
            <div className="float-right">
              {questionData._id ? (
                <>
                  <Link to={"/admin/question/edit/steps/step4/" + questionId}>
                    <Button variant="secondary">Back</Button>
                  </Link>{" "}
                  <Link to={"/admin/question/edit/steps/step6/" + questionId}>
                    <Button variant="primary">Next</Button>
                  </Link>
                </>
              ) : (
                ""
              )}
            </div>
          </Card.Header>
          {questionData._id ? (
            <Card.Body>
              <Row>
                <Col md="1">
                  <strong>Q.</strong>
                </Col>
                <Col
                  md="11"
                  dangerouslySetInnerHTML={{ __html: questionData.question }}
                ></Col>
              </Row>
              <Row
                style={{
                  marginTop: "25px",
                  marginBottom: "25px",
                  marginLeft: "10px",
                }}
              >
                {questionData.answer_choices.map((answer_choice, index) => (
                  <Row className="w-100" key={index}>
                    <Col md="1">({getCharacterCode(65 + index)})</Col>
                    <Col md="1">
                      {questionData.correct_answers[index] ? (
                        <FontAwesomeIcon icon={faCheck} color="blue" />
                      ) : (
                        ""
                      )}
                    </Col>

                    <Col
                      md="10"
                      dangerouslySetInnerHTML={{ __html: answer_choice }}
                    ></Col>
                  </Row>
                ))}
              </Row>

              <Row>
                <Col md="1">
                  <strong>Sol.</strong>
                </Col>
                <Col
                  md="11"
                  dangerouslySetInnerHTML={{ __html: questionData.solution }}
                ></Col>
              </Row>
            </Card.Body>
          ) : (
            ""
          )}
          <Card.Footer>
            <div className="float-right">
              {questionData._id ? (
                <>
                  <Link to={"/admin/question/edit/steps/step4/" + questionId}>
                    <Button variant="secondary">Back</Button>
                  </Link>{" "}
                  <Link to={"/admin/question/edit/steps/step6/" + questionId}>
                    <Button variant="primary">Next</Button>
                  </Link>
                </>
              ) : (
                ""
              )}
            </div>
          </Card.Footer>
        </Card>
      </Col>
    </Row>
  );
}
export default Step4;
