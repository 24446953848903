import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";

import { get } from "../../../helpers/RequestHelper";

function Filter({ onChange }) {
  const [filterMeta, setFilterMeta] = useState({ subjects: [], tags: [] });
  useEffect(() => {
    (async function () {
      try {
        const { subjects } = await get(`/api/admin/subject`);
        const tags = await get(`/api/admin/tag`);
        setFilterMeta({
          subjects: subjects,
          tags: tags,
        });
      } catch (err) {}
    })();
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    onChange({
      subject: document.getElementById("subject").value,
      text: document.getElementById("text").value,
      id: document.getElementById("id").value,
      tag: document.getElementById("tag").value,
      page: 1,
    });
  }

  function handleReset(e) {
    e.preventDefault();
    document.getElementById("subject").value = "";
    document.getElementById("text").value = "";
    document.getElementById("id").value = "";
    document.getElementById("tag").value = "";

    onChange({
      subject: "",
      text: "",
      id: "",
      tag: "",
      page: 1,
    });
  }

  const { subjects, tags } = filterMeta;

  return (
    <Form onSubmit={handleSubmit} onReset={handleReset}>
      <fieldset className="filters">
        <legend align="left">Filters:</legend>
        <div className="filters-body">
          <Row>
            <Col md="3">
              <Form.Group controlId="subject">
                <Form.Label>Subject </Form.Label>
                <Form.Control as="select" placeholder="Select" defaultValue="">
                  <option value="">All</option>
                  {subjects.map((subject) => (
                    <option value={subject._id} key={subject._id}>
                      {subject.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group controlId="tag">
                <Form.Label>Tag </Form.Label>
                <Form.Control as="select" placeholder="Select" defaultValue="">
                  <option value="">All</option>
                  {tags.map((tag) => (
                    <option value={tag._id} key={tag._id}>
                      {tag.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group controlId="text">
                <Form.Label>Search By Question Text</Form.Label>
                <Form.Control as="input" placeholder="Enter text" />
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group controlId="id">
                <Form.Label>Search By Question Id</Form.Label>
                <Form.Control as="input" placeholder="Enter id" />
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className="filters-footer">
          <Button type="reset" variant="outline-secondary">
            Reset
          </Button>{" "}
          <Button type="submit" variant="primary">
            Submit
          </Button>
        </div>
      </fieldset>
    </Form>
  );
}
export default Filter;
