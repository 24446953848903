import ListRolePage from "../pages/roles/ListRolePage.js";
import CreateRolePage from "../pages/roles/CreateRolePage.js";
import EditRolePage from "../pages/roles/EditRolePage.js";

const roles = [
  {
    path: '/admin/ewrole',
    title: 'List EW-Role Page',
    props: {},
    component: ListRolePage
  },
  {
    path: '/admin/ewrole/create',
    title: 'Create EW-Role Page',
    props: {},
    component: CreateRolePage
  },
  {
    path: '/admin/ewrole/:ewroleId/edit',
    title: 'Edit EW-Role Page',
    props: {},
    component: EditRolePage
  }
]

export default roles;