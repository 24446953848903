import React, { useState } from "react";
import { Container, Card, Button, Form } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { post } from "../../helpers/RequestHelper";

function Chapter() {
  const history = useHistory();
  const { subjectId } = useParams();
  const [chapter, setChapter] = useState({name:"",description:""});

  function handleChange(event) {
    setChapter({
      ...chapter,
      [event.target.id]: event.target.value,
    });
  }

  function handleBlur(event) {
    setChapter({
      ...chapter,
      [event.target.id]: ( event.target.value ).trim(),
    });
  }

  function goBack() {
    history.push(`/admin/subject/${subjectId}/chapter`);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      await post(`/api/admin/subject/${subjectId}/chapter`, {
        ...chapter,
        parent_id: subjectId,
      });
      goBack();
    } catch (err) {}
  }

  return (
    <>
      <Container fluid="md">
        <Card style={{ marginTop: "50px" }}>
          <Card.Header>
            <h4 className="float-left">Create Chapter </h4>
          </Card.Header>
          <Form onSubmit={handleSubmit}>
            <Card.Body>
              <>
                <Form.Group controlId="name" onChange={handleChange} onBlur={handleBlur}>
                  <Form.Label
                    className="float-left"
                    style={{ marginLeft: "5px", marginTop: "5px" }}
                  >
                    Name
                  </Form.Label>
                  <Form.Control
                    as="input"
                    placeholder="Enter chapter name"
                    required
                    value={chapter.name}
                  />
                </Form.Group>
                <Form.Group controlId="description" onChange={handleChange} onBlur={handleBlur}>
                  <Form.Label
                    className="float-left"
                    style={{ marginLeft: "5px", marginTop: "20px" }}
                  >
                    Enter Description
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    placeholder="Description"
                    required
                    value={chapter.description}
                  />
                </Form.Group>
              </>
            </Card.Body>
            <Card.Footer>
              <Button
                type="button"
                onClick={goBack}
                className="float-left"
                style={{ color: "white" }}
              >
                Back
              </Button>
              &nbsp;
              <Button variant="primary" type="submit">
                Update
              </Button>
            </Card.Footer>
          </Form>
        </Card>
      </Container>
    </>
  );
}
export default Chapter;
