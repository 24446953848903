function accessRoleHelper(User) {
    var self = {
        'Actions': {
            'EXAM': {
                'LIST': {
                    'NAME': 'List Exam',
                    'VALUE': 101
                },
                'CREATE': {
                    'NAME': 'Create Exam',
                    'VALUE': 102
                },
                'UPDATE': {
                    'NAME': 'Update Exam',
                    'VALUE': 103
                }
            },

            'SUBJECT': {
                'LIST': {
                    'NAME': 'List Subject',
                    'VALUE': 201
                },
                'CREATE': {
                    'NAME': 'Create Subject',
                    'VALUE': 202
                },
                'UPDATE': {
                    'NAME': 'Update Subject',
                    'VALUE': 203
                }
            },

            'CHAPTER': {
                'LIST': {
                    'NAME': 'List Chapter',
                    'VALUE': 301
                },
                'CREATE': {
                    'NAME': 'Create Chapter',
                    'VALUE': 302
                },
                'UPDATE': {
                    'NAME': 'Update Chapter',
                    'VALUE': 303
                }
            },

            'TOPIC': {
                'LIST': {
                    'NAME': 'List Topic',
                    'VALUE': 401
                },
                'CREATE': {
                    'NAME': 'Create Topic',
                    'VALUE': 402
                },
                'UPDATE': {
                    'NAME': 'Update Topic',
                    'VALUE': 403
                }
            },

            'TEST': {
                'LIST': {
                    'NAME': 'List Test',
                    'VALUE': 501
                },
                'CREATE': {
                    'NAME': 'Create Test',
                    'VALUE': 502
                },
                'UPDATE': {
                    'NAME': 'Update Test',
                    'VALUE': 503
                }
            },

            'QUESTION': {
                'LIST': {
                    'NAME': 'List Question',
                    'VALUE': 601
                },
                'CREATE': {
                    'NAME': 'Create Question',
                    'VALUE': 602
                },
                'UPDATE': {
                    'NAME': 'Update Question',
                    'VALUE': 603
                }
            },

            'QUESTION_DIRECTION': {
                'LIST': {
                    'NAME': 'List Question Direction',
                    'VALUE': 701
                },
                'CREATE': {
                    'NAME': 'Create Question Direction',
                    'VALUE': 702
                },
                'UPDATE': {
                    'NAME': 'Update Question Direction',
                    'VALUE': 703
                }
            },

            'USER': {
                'LIST': {
                    'NAME': 'List User',
                    'VALUE': 801
                },
                'CREATE': {
                    'NAME': 'Create User',
                    'VALUE': 802
                },
                'UPDATE': {
                    'NAME': 'Update User',
                    'VALUE': 803
                }
            },

            'EW_ROLE': {
                'LIST': {
                    'NAME': 'List EW Role',
                    'VALUE': 901
                },
                'CREATE': {
                    'NAME': 'Create EW Role',
                    'VALUE': 902
                },
                'UPDATE': {
                    'NAME': 'Update EW Role',
                    'VALUE': 903
                }
            },

            'TEST_COLLECTION': {
                'LIST': {
                    'NAME': 'List Test Collection',
                    'VALUE': 1001
                },
                'CREATE': {
                    'NAME': 'Create Test Collection',
                    'VALUE': 1002
                },
                'UPDATE': {
                    'NAME': 'Update Test Collection',
                    'VALUE': 1003
                }
            },

            'EXAM_CATEGORY': {
                'LIST': {
                    'NAME': 'List Exam Category',
                    'VALUE': 1101
                },
                'CREATE': {
                    'NAME': 'Create Exam Category',
                    'VALUE': 1102
                },
                'UPDATE': {
                    'NAME': 'Update Exam Category',
                    'VALUE': 1103
                }
            },

            'PACKAGE_COLLECTION': {
                'LIST': {
                    'NAME': 'List Package Collection',
                    'VALUE': 1201
                },
                'CREATE': {
                    'NAME': 'Create Package Collection',
                    'VALUE': 1202
                },
                'UPDATE': {
                    'NAME': 'Update Package Collection',
                    'VALUE': 1203
                }
            },
        },

        valueToNameMap: false,

        can: (action) => {
            var hasAuth = false;

            if (User.isAdmin) {
                hasAuth = true;
            } else {
                if (User.actions.indexOf(action) !== -1) {
                    hasAuth = true;
                }
            }
            return hasAuth;
        },

        actionName: function (value) {
            if (!self.valueToNameMap) {
                self.valueToNameMap = {};
                self.Actions.forEach(action_group => {
                    action_group.forEach(action => {
                        self.valueToNameMap[action.VALUE] = action.NAME;
                    });
                });
            }
            return self.valueToNameMap[value];
        }
    }

    return self;
}

export default accessRoleHelper;