import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Moment from "react-moment";

const TestRow = function ({
  question,
  questionIndex,
  toggleStatus,
}) {
  function toggleTestStatus() {
    toggleStatus(question._id);
  }

  return (
    <tr key={question._id} className={question.status === 1 ? "" : "bg-danger"}>
      <td>{questionIndex}</td>
      <td dangerouslySetInnerHTML={{ __html: question.question }}></td>
      <td>{question.subject.name}</td>
      <td>{question.tag.name}</td>
      <td>{question.created_by}</td>
      <td>
        <Moment format="MMM DD, YYYY hh:mm:ss a">{question.created_on}</Moment>
      </td>
      <td>
        <Link to={`/admin/question/edit/steps/step1/${question._id}`} className="btn" title="Edit">
          <FontAwesomeIcon icon={faEdit} color="blue" />
        </Link>
        {question.status === 1 ? (
          <Button variant="link" onClick={toggleTestStatus} title="Disable">
            <FontAwesomeIcon icon={faTimes} color="red" />
          </Button>
        ) : (
          <Button variant="link" onClick={toggleTestStatus} title="Enable">
            <FontAwesomeIcon icon={faCheck} color="blue" />
          </Button>
        )}
      </td>
    </tr>
  );
};

export default TestRow;
