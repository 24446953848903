import React, { useState } from "react";
import { Card, Button, Form } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { post } from "../../helpers/RequestHelper";
function Exam() {
  let history = useHistory();

  const [collection, setCollection] = useState({
    name: "",
    description: "",
    test_name_prefix: "",
  });
  let { examId } = useParams();

  function handleChange(event) {
    setCollection({
      ...collection,
      [event.target.id]: event.target.value,
    });
  }

  function handleBlur(event) {
    setCollection({
      ...collection,
      [event.target.id]: event.target.value.trim(),
    });
  }

  function goBack() {
    history.push(`/admin/exam/${examId}/collection`);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      const data = await post(
        `/api/admin/exam/${examId}/collection`,
        collection
      );
      goBack();
    } catch (err) {}
  }

  // async function getCollectionData(examId,collectionId) {
  //   try {
  //     const collectionData = await get(
  //       "/api/admin/exam/" + examId + "/collection/" + collectionId
  //     );

  //     setCollection(collectionData);
  //   } catch (err) {
  //     setCollection(null);
  //   }
  // }

  return (
    <Card>
      <Card.Header>
        <h4 className="float-left">Update Collection</h4>
      </Card.Header>
      <Form onSubmit={handleSubmit}>
        <Card.Body>
          <>
            <Form.Group
              controlId="name"
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <Form.Label
                className="float-left"
                style={{ marginLeft: "5px", marginTop: "5px" }}
              >
                Name
              </Form.Label>
              <Form.Control
                as="input"
                required
                placeholder="Name"
                value={collection.name}
              />
            </Form.Group>
            <Form.Group
              controlId="description"
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <Form.Label
                className="float-left"
                style={{ marginLeft: "5px", marginTop: "20px" }}
              >
                Description
              </Form.Label>
              <Form.Control
                as="textarea"
                required
                rows="3"
                placeholder="Description"
                value={collection.description}
              />
            </Form.Group>
            <Form.Group
              controlId="test_name_prefix"
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <Form.Label
                className="float-left"
                style={{ marginLeft: "5px", marginTop: "5px" }}
              >
                Test Name Prefix
              </Form.Label>
              <Form.Control
                as="input"
                required
                placeholder="Enter Test Name Prefix"
                value={collection.test_name_prefix}
              />
            </Form.Group>
          </>
        </Card.Body>
        <Card.Footer>
          <Button
            type="button"
            onClick={goBack}
            className="float-left"
            style={{ color: "white" }}
          >
            Back
          </Button>
          &nbsp;
          <Button variant="primary" type="submit">
            {" "}
            Save{" "}
          </Button>
        </Card.Footer>
      </Form>
    </Card>
  );
}
export default Exam;
