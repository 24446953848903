import React, { useEffect, useState } from "react";
import { Card, Button, Table } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faArrowCircleUp,
  faArrowCircleDown,
} from "@fortawesome/free-solid-svg-icons";
import { get, put } from "../../helpers/RequestHelper";

const LIMIT = 10;
function Exam() {
  let history = useHistory();
  const handleClick = () => {
    history.push(
      "/admin/exam/" + examId + "/collection/" + collectionId + "/addtest"
    );
  };
  let { examId, collectionId } = useParams();
  const [tests, setTest] = useState([]);
  const [collection, setCollection] = useState({});
  const [filters, setFilters] = useState({
    limit: LIMIT,
    page: 1,
  });
  const [totalCount, setTotalCount] = useState(0);
  useEffect(() => {
    getTestCollectionData();
  }, [filters]);

  const goBack = () => {
    history.push(`/admin/exam/${examId}/collection`);
  };

  async function getTestCollectionData() {
    try {
      const testData = await get(
        `/api/admin/exam/${examId}/collection/${collectionId}`
      );
      setTest(testData.test_ids);
      setCollection(testData);

      setTotalCount(testData.test_ids.length);
    } catch (err) {
      setTest([]);
    }
  }

  async function moveUp(testId) {
    await put("/api/admin/exam/collection/test/move/up", {
      collectionId: collection._id,
      examId: collection.exam_id,
      test: {
        _id: testId,
      },
    });
    onFilterChange({});
  }
  async function moveDown(testId) {
    await put("/api/admin/exam/collection/test/move/down", {
      collectionId: collection._id,
      examId: collection.exam_id,
      test: {
        _id: testId,
      },
    });
    onFilterChange({});
  }

  function onFilterChange(newFilters) {
    setFilters({
      ...filters,
      ...newFilters,
    });
  }

  async function toggleStatus(test) {
    try {
      await put("/api/admin/exam/collection/test/toggle", {
        collectionId: collection._id,
        examId: collection.exam_id,
        test,
      });
      await getTestCollectionData();
    } catch (err) {}
  }

  const renderData = (test, index) => {
    let indexCount = (filters.page - 1) * LIMIT + index + 1;

    return [
      <tr key={test._id} className={collection.status === 1 ? "" : "bg-danger"}>
        <td>{indexCount}</td>

        <td>{test.name}</td>

        <td>
          {(() => {
            if (collection.status === 1) {
              return (
                <Button
                  variant="link"
                  onClick={() => toggleStatus(test)}
                  title="Remove"
                >
                  <FontAwesomeIcon icon={faTimes} color="red" />
                </Button>
              );
            }

            if (collection.order > 1) {
              return (
                <Button
                  variant="link"
                  onClick={() => moveUp(test._id)}
                  title="Move Up"
                >
                  <FontAwesomeIcon icon={faArrowCircleUp} color="green" />
                </Button>
              );
            }
          })()}

          {index === 0 ? (
            ""
          ) : (
            <Button
              variant="link"
              onClick={() => moveUp(test._id)}
              title="Move Up"
            >
              <FontAwesomeIcon icon={faArrowCircleUp} color="green" />
            </Button>
          )}

          {tests.length === index + 1 ? (
            ""
          ) : (
            <Button
              variant="link"
              onClick={() => moveDown(test._id)}
              title="Move Down"
            >
              <FontAwesomeIcon icon={faArrowCircleDown} color="blue" />
            </Button>
          )}
        </td>
      </tr>,
    ];
  };

  return (
    <Card>
      <Card.Header>
        <h4 className="float-left">Manage Test Collection</h4>
        <Button variant="info" onClick={handleClick} className="float-right">
          Add/Remove Test
        </Button>
      </Card.Header>
      <Card.Body className="p-0">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{tests.map(renderData)}</tbody>
        </Table>
      </Card.Body>
      <Card.Footer>
        <Button variant="info" onClick={goBack}>
          Save & Back
        </Button>
      </Card.Footer>
    </Card>
  );
}
export default Exam;
