import React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";

function Filter({ exams, onChange }) {
  function handleSubmit(e) {
    e.preventDefault();
    onChange({
      exam: document.getElementById("name").value,
      page: 1,
    });
  }

  function handleReset(e) {
    e.preventDefault();
    document.getElementById("name").value = "";
    onChange({
      exam: "",
      page: 1,
    });
  }

  const filterData = (exam) => (
    <option key={exam._id} value={exam._id}>
      {exam.name}
    </option>
  );
  return (
    <Form onSubmit={handleSubmit} onReset={handleReset}>
      <fieldset className="filters">
        <legend align="left">Filters:</legend>
        <div className="filters-body">
          <Row>
            <Col md="3">
              <Form.Group controlId="name">
                <Form.Label>Select</Form.Label>
                <Form.Control as="select" placeholder="Select" defaultValue="">
                  <option value="">All</option>
                  {exams.map(filterData)}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className="filters-footer">
          <Button type="reset" variant="outline-secondary">
            Reset
          </Button>{" "}
          <Button type="submit" variant="primary">
            Submit
          </Button>
        </div>
      </fieldset>
    </Form>
  );
}
export default Filter;
