import React, { useEffect, useState } from "react";
import { Card, Button, Col, Row, Form } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import queryString from "query-string";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

import { dynamicSort } from "../../helpers/UtilityHelper";

import { get, put } from "../../helpers/RequestHelper";

const getCharacterCode = function (value) {
  return String.fromCharCode(value);
};

function AddQuestionInTestSection() {
  const { testId, sectionId } = useParams();
  const history = useHistory();

  const [test, setTest] = useState({});
  const [loadingQuestion, setLoadingQuestion] = useState(false);
  const [availableQuestions, setAvailableQuestions] = useState([]);
  const [filterQ, setFilterQ] = useState([]);
  const [availableExams, setAvailableExams] = useState([]);
  const [availableSubjects, setAvailableSubjects] = useState([]);
  const [availableChapters, setAvailableChapters] = useState([]);
  const [availableTopics, setAvailableTopics] = useState([]);

  const [section, setSection] = useState({});

  useEffect(() => {
    if (!filterQ.length) {
      return;
    }
    // eslint-disable-next-line no-undef
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
  }, [filterQ]);

  useEffect(() => {
    (async () => {
      const testObj = await get(`/api/admin/test/${testId}`);
      for (let idx in testObj.sections) {
        if (testObj.sections[idx]._id === sectionId) {
          setSection(testObj.sections[idx]);
          break;
        }
      }
      setTest(testObj);

      const { exams } = await get(`/api/admin/exam`);
      exams.sort(dynamicSort("name"));
      setAvailableExams(exams);
      const { subjects } = await get(`/api/admin/subject`);
      subjects.sort(dynamicSort("name"));
      setAvailableSubjects(subjects);
    })();
  }, [sectionId, testId]);

  const loadChapters = async (subject_id) => {
    setAvailableChapters([]);
    setAvailableTopics([]);

    if (!subject_id) {
      return;
    }

    try {
      const { chapters } = await get(
        `/api/admin/subject/${subject_id}/chapter`
      );
      chapters.sort(dynamicSort("name"));
      setAvailableChapters(chapters);
    } catch (err) {}
  };

  const loadTopics = async (chapter_id, subject_id) => {
    setAvailableTopics([]);

    if (!chapter_id) {
      return;
    }

    try {
      const { topics } = await get(
        `/api/admin/chapter/${chapter_id}/topic?subjectId=${subject_id}`
      );
      topics.sort(dynamicSort("name"));
      setAvailableTopics(topics);
    } catch (err) {}
  };

  function isInTest(question) {
    for (let idx in test.questions) {
      if (test.questions[idx].question._id === question._id) {
        return true;
      }
    }
    return false;
  }

  async function toggleQuestion(test_question) {
    try {
      const test = await put("/api/admin/test/question/toggle", {
        id: testId,
        question: test_question,
        section: section,
      });

      setTest(test);
    } catch (err) {
      if (err && err.message) {
        alert(err.message);
        return;
      }
      alert("Error while toggling question");
    }
  }

  function handleChange(e) {
    const { id } = e.target;
    let { value } = e.target;

    if (id === "subject") {
      if (document.getElementById("chapter")) {
        document.getElementById("chapter").value = "";
      }

      if (document.getElementById("topic")) {
        document.getElementById("topic").value = "";
      }

      loadChapters(value);
    }

    if (id === "chapter") {
      const subject_id = document.getElementById("subject").value;
      if (document.getElementById("topic")) {
        document.getElementById("topic").value = "";
      }
      if (subject_id) {
        loadTopics(value, subject_id);
      }
    }
  }

  async function showQuestions() {
    let filters = {
      exam: document.getElementById("exam").value,
      subject: document.getElementById("subject").value,
      chapter: document.getElementById("chapter")
        ? document.getElementById("chapter").value
        : "",
      topic: document.getElementById("topic")
        ? document.getElementById("topic").value
        : "",
      created_by: document.getElementById("created_by").value,
      explanatory_solution: document.getElementById("explanatory_solution")
        .value,
    };

    var isInValid = true;
    Object.values(filters).forEach((val) => {
      if (val) {
        isInValid = isInValid && false;
      }
    });

    if (isInValid) {
      return alert("Please apply some filter to show question");
    }

    filters = {
      ...filters,
      exam: filters.exam || test.exam._id,
      language: test.language,
      status: 1,
      // limit: 50,
      // page: 1
    };

    if (!test || !test.language) {
      return alert("Unable to get test language!");
    }

    setLoadingQuestion(true);

    try {
      const { questions: questionList } = await get(
        `/api/admin/question?${queryString.stringify(filters)}`
      );
      setAvailableQuestions(questionList);
      setFilterQ(questionList);
    } catch (err) {
      alert("Error while feathing questions");
      setAvailableQuestions([]);
      setFilterQ([]);
    } finally {
      setLoadingQuestion(false);
    }
  }

  if (!test) {
    return (
      <div>
        <h1>Loading test ...</h1>
      </div>
    );
  }

  let filterTimeout = false;

  function onFilterChange(e) {
    if (filterTimeout) {
      clearTimeout(filterTimeout);
      filterTimeout = false;
    }

    const text = document.getElementById("text").value;
    const tag = document.getElementById("tag").value;

    setTimeout(() => {
      if (!text && !tag) {
        setFilterQ(availableQuestions);
        return;
      }

      const filterData = availableQuestions.filter((q) => {
        let isMatched = false;

        if (text && q.question.toLowerCase().includes(text.toLowerCase())) {
          isMatched = true;
        }

        if (
          tag &&
          q.tag.name &&
          q.tag.name.toLowerCase().includes(tag.toLowerCase())
        ) {
          isMatched = true;
        }

        if (isMatched) {
          return q;
        }
      });
      setFilterQ(filterData);
    }, 1000);
  }

  return (
    <Card>
      <Card.Header>
        <h4 className="float-left">
          Add / Remove Questions in{" "}
          <span ng-show="section">{section.name} of </span>
          {test.name} ( Test Language:{" "}
          <span className="text-capitalize">{test.language}</span> )
        </h4>
        <div className="float-right">
          <Link to={`/admin/test/${testId}/question`}>
            <Button variant="secondary">Back</Button>
          </Link>{" "}
        </div>
      </Card.Header>
      <Card.Body>
        <Form>
          <fieldset className="filters p-2">
            <legend>Filters</legend>
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="exam">
                <Form.Label>Exams</Form.Label>
                <Form.Control
                  className="text-capitalize"
                  as="select"
                  placeholder="Select Exam"
                >
                  <option value="">Select Exam</option>
                  {availableExams.map((exam) => (
                    <option key={exam._id} value={exam._id}>
                      {exam.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="subject">
                <Form.Label>Subject</Form.Label>
                <Form.Control
                  className="text-capitalize"
                  as="select"
                  placeholder="Select Subject"
                  onChange={handleChange}
                >
                  <option value="">Select Subject</option>
                  {availableSubjects.map((subject) => (
                    <option key={subject._id} value={subject._id}>
                      {subject.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form.Row>
            {availableChapters.length ? (
              <Form.Row>
                <Form.Group as={Col} md="12" controlId="chapter">
                  <Form.Label>Chapter</Form.Label>
                  <Form.Control
                    className="text-capitalize"
                    as="select"
                    placeholder="Select Chapter"
                    onChange={handleChange}
                  >
                    <option value="">Select Chapter</option>
                    {availableChapters.map((chapter) => (
                      <option key={chapter._id} value={chapter._id}>
                        {chapter.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form.Row>
            ) : (
              ""
            )}
            {availableTopics.length ? (
              <Form.Row>
                <Form.Group as={Col} md="12" controlId="topic">
                  <Form.Label>Topic</Form.Label>
                  <Form.Control
                    className="text-capitalize"
                    as="select"
                    placeholder="Select Topic"
                    onChange={handleChange}
                  >
                    <option value="">Select Topic</option>
                    {availableTopics.map((topic) => (
                      <option key={topic._id} value={topic._id}>
                        {topic.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form.Row>
            ) : (
              ""
            )}

            <Form.Row>
              <Form.Group as={Col} md="12" controlId="created_by">
                <Form.Label>Created By</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter user's email"
                  onChange={handleChange}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md="12" controlId="explanatory_solution">
                <Form.Label>Explanatory Solution</Form.Label>
                <Form.Control
                  className="text-capitalize"
                  as="select"
                  placeholder="Explanatory Solution?"
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <div className="filters-footer">
              {loadingQuestion ? (
                <span>Loading ...</span>
              ) : (
                <Button variant="primary" onClick={showQuestions}>
                  Show Questions
                </Button>
              )}
            </div>
          </fieldset>
          {test.sections && test.sections.length ? (
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="other_sections">
                <Form.Label>All Sections</Form.Label>
                <Form.Control
                  className="text-capitalize"
                  as="select"
                  onChange={(e) => {
                    if (!e.target.value) {
                      return;
                    }

                    history.push(
                      `/admin/test/${testId}/question/add/${e.target.value}`
                    );
                  }}
                  defaultValue={section._id}
                >
                  {test.sections.map((section) => (
                    <option key={section._id} value={section._id}>
                      {section.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form.Row>
          ) : (
            <div className="alert alert-danger" role="alert">
              Section(s) Not Available In This Test.
            </div>
          )}

          {availableQuestions.length ? (
            ""
          ) : (
            <div className="alert alert-danger" role="alert">
              Question(s) Not Found.
            </div>
          )}
        </Form>
        {availableQuestions.length ? (
          <div>
            <fieldset className="filters">
              <legend>Filter Results</legend>
              <div className="filters-body">
                <div className="form-group">
                  <label htmlFor="text">By Text</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter your search text"
                    id="text"
                    onChange={onFilterChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="tag">By Tag</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter tag name"
                    id="tag"
                    onChange={onFilterChange}
                  />
                </div>

                <fieldset className="filters">
                  <legend>Searched Questions</legend>
                  <div className="filters-body">
                    {filterQ.map((question, index) => (
                      <div key={question._id} className="card card-default p-4">
                        <div className="card-heading clearfix">
                          <div className="float-left">
                            <table>
                              <tbody>
                                <tr>
                                  <td style={{ verticalAlign: "top" }}>
                                    <strong>Question {index + 1}:</strong>
                                  </td>
                                  <td>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: question.question,
                                      }}
                                    ></div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="btn-group float-right">
                            {isInTest(question) ? (
                              <Button
                                variant="link"
                                onClick={() => {
                                  toggleQuestion(question);
                                }}
                                title="Remove from test"
                              >
                                <FontAwesomeIcon icon={faTimes} color="red" />
                              </Button>
                            ) : (
                              <Button
                                variant="link"
                                onClick={() => {
                                  toggleQuestion(question);
                                }}
                                title="Add to test"
                              >
                                <FontAwesomeIcon icon={faCheck} color="blue" />
                              </Button>
                            )}
                          </div>
                        </div>
                        <div className="card-body clearfix">
                          <Row>
                            {question.answer_choices.map(
                              (answer_choice, index) => {
                                return (
                                  <Col md={6} key={index}>
                                    <Row>
                                      <Col md={2}>
                                        ({getCharacterCode(65 + index)})
                                      </Col>
                                      <Col
                                        md={10}
                                        dangerouslySetInnerHTML={{
                                          __html: answer_choice,
                                        }}
                                      ></Col>
                                    </Row>
                                  </Col>
                                );
                              }
                            )}
                          </Row>
                        </div>
                      </div>
                    ))}
                  </div>
                </fieldset>
              </div>
            </fieldset>
          </div>
        ) : (
          ""
        )}
      </Card.Body>
    </Card>
  );
}
export default AddQuestionInTestSection;
