import React, { useEffect, useState } from "react";
import { Card, Button, Table } from "react-bootstrap";
import Pagination from "../../components/pagination/Pagination";
import { Link, useHistory, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faCheck,
  faTimes,
  faArrowCircleUp,
  faArrowCircleDown,
} from "@fortawesome/free-solid-svg-icons";
import queryString from "query-string";
import { get, deleteRequest, put } from "../../helpers/RequestHelper";
const LIMIT = 10;

function Exam() {
  let history = useHistory();
  let { examId } = useParams();
  const [collections, setCollection] = useState([]);
  const [filters, setFilters] = useState({
    limit: LIMIT,
    page: 1,
  });
  const [totalCount, setTotalCount] = useState(0);
  useEffect(() => {
    getTestCollectionData();
  }, [filters]);

  const addNew = () => {
    history.push("/admin/exam/" + examId + "/collection/create");
  };

  async function getTestCollectionData() {
    try {
      const stringified = queryString.stringify(filters);
      const collectionData = await get(
        `/api/admin/exam/${examId}/collection?${stringified}`
      );

      setCollection(collectionData.tests);
      setTotalCount(collectionData.total_count);
    } catch (err) {
      setCollection([]);
    }
  }

  async function moveUp(collectionId, collection) {
    await put(
      "/api/admin/exam/collection/" + collectionId + "/moveup",
      collection
    );
    onFilterChange({});
  }
  async function moveDown(collectionId, collection) {
    await put(
      "/api/admin/exam/collection/" + collectionId + "/movedown",
      collection
    );
    onFilterChange({});
  }

  async function onFilterChange(newFilters) {
    setFilters({
      ...filters,
      ...newFilters,
    });
  }

  async function toggleStatus(examId, collection_id) {
    try {
      await deleteRequest(
        "/api/admin/exam/" + examId + "/collection/" + collection_id
      );
      await getTestCollectionData(examId);
    } catch (err) {}
  }

  const renderData = (collection, index) => {
    let indexCount = (filters.page - 1) * LIMIT + index + 1;

    return [
      <tr
        key={collection._id}
        className={collection.status === 1 ? "" : "bg-danger"}
      >
        <td>{indexCount}</td>
        {collection.schema_version === 1 ? (
          <td>{collection.name}[Archived]</td>
        ) : (
          <td>{collection.name}</td>
        )}

        <td>
          <Button
            variant="info"
            onClick={() => {
              history.push(
                `/admin/exam/${examId}/collection/${collection._id}/managetest`
              );
            }}
          >
            Update Tests
          </Button>

          <Link
            to={`/admin/exam/${examId}/collection/${collection._id}/edit`}
            className="btn"
            title="Edit"
          >
            <FontAwesomeIcon icon={faEdit} color="blue" />
          </Link>

          {(() => {
            if (collection.status === 1) {
              return (
                <Button
                  variant="link"
                  onClick={() => toggleStatus(examId, collection._id)}
                  title="Disable"
                >
                  <FontAwesomeIcon icon={faTimes} color="red" />
                </Button>
              );
            }

            if (collection.schema_version === 1) {
              return (
                <Button title="Enable is not allowed." variant="link">
                  <FontAwesomeIcon icon={faCheck} color="grey" />
                </Button>
              );
            }

            return (
              <Button
                variant="link"
                onClick={() => toggleStatus(examId, collection._id)}
                title="Enable"
              >
                <FontAwesomeIcon icon={faCheck} color="blue" />
              </Button>
            );
          })()}

          {collection.order % 10 > 1 || collection.order % 10 === 0 ? (
            <Button
              variant="link"
              onClick={() => moveUp(collection._id, collection)}
              title="Move Up"
            >
              <FontAwesomeIcon icon={faArrowCircleUp} color="green" />
            </Button>
          ) : (
            ""
          )}
          {collections.length === index + 1 ? (
            ""
          ) : (
            <Button
              variant="link"
              onClick={() => moveDown(collection._id, collection)}
              title="Move Down"
            >
              <FontAwesomeIcon icon={faArrowCircleDown} color="blue" />
            </Button>
          )}
        </td>
      </tr>,
    ];
  };

  return (
    <Card>
      <Card.Header>
        <h4 className="float-left">Manage Test Collection</h4>
        <Button variant="info" onClick={addNew} className="float-right">
          Add New
        </Button>
      </Card.Header>
      <Card.Body className="p-0">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{collections.map(renderData)}</tbody>
        </Table>
      </Card.Body>
      <Card.Footer>
        <Pagination
          currentPage={filters.page}
          limit={LIMIT}
          totalCount={totalCount}
          onChange={onFilterChange}
          filters={{}}
        />
      </Card.Footer>
    </Card>
  );
}
export default Exam;
