import React, { useEffect, useState } from "react";
import { Card, Button, Table } from "react-bootstrap";

import queryString from "query-string";
import { Link } from "react-router-dom";

import { get, deleteRequest } from "../../../helpers/RequestHelper";
import QuestionRow from "./QuestionRow";
import Pagination from "../../../components/pagination/Pagination";
import Filter from "./Filter";

const LIMIT = 10;
const ListQuestion = function () {
  const [questions, setQuestions] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [filters, setFilters] = useState({
    limit: LIMIT,
    page: 1,
  });

  useEffect(() => {
    (async function getQuestionData() {
      const stringified = queryString.stringify(filters);
      const questionData = await get(`/api/admin/question?${stringified}`);
      const { questions: questionList, total_count: count } = questionData;
      setQuestions(questionList);
      setTotalCount(count);
    })();
  }, [filters]);

  async function onFilterChange(newFilters) {
    setFilters({
      ...filters,
      ...newFilters,
    });
  }

  async function toggleStatus(question_id) {
    try {
      await deleteRequest("/api/admin/question/" + question_id);
      onFilterChange({});
    } catch (err) {
      if (err && typeof err === "string") {
        alert(err);
        return;
      }

      if (err && err.error && typeof err.error === "string") {
        alert(err.error);
      }
      if (err && err.error && err.error.message) {
        alert(err.error.message);
      }
    }
  }

  useEffect(() => {
    if (questions && questions.length) {
      // eslint-disable-next-line no-undef
      MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    }
  }, [questions]);

  return (
    <>
      <Filter onChange={onFilterChange} />
      <Card>
        <Card.Header>
          <h4 className="float-left">Manage Questions</h4>
          <Link
            to={`/admin/question/create/steps/step1`}
            className="float-right"
          >
            <Button variant="info">Add New</Button>
          </Link>
          <p className="float-right m-2">Total Questions: {totalCount}</p>
        </Card.Header>
        <Card.Body className="p-0">
          <Table striped responsive bordered>
            <thead>
              <tr>
                <th>#</th>
                <th>Question</th>
                <th>Subject</th>
                <th style={{ width: "8%" }}>Tag</th>
                <th>Created By</th>
                <th>Created On</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {questions.map((question, index) => (
                <QuestionRow
                  key={question._id}
                  question={question}
                  toggleStatus={toggleStatus}
                  questionIndex={(filters.page - 1) * LIMIT + index + 1}
                />
              ))}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer>
          {questions && questions.length ? (
            <Pagination
              currentPage={filters.page}
              limit={LIMIT}
              totalCount={totalCount}
              onChange={onFilterChange}
              filters={filters}
            />
          ) : (
            ""
          )}
        </Card.Footer>
      </Card>
    </>
  );
};

export default ListQuestion;
