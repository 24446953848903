import React, { useEffect, useState } from "react";
import Pagination from "../../components/pagination/Pagination";
import { useHistory } from "react-router-dom";
import { Card, Button, Table, Row, Col, Form } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { get, deleteRequest } from "../../helpers/RequestHelper";
import queryString from "query-string";

const LIMIT = 10;
function Subject() {
  let history = useHistory();
  const handleClick = () => {
    history.push(
      "/admin/subject/" + subjectId + "/chapter/" + chapterId + "/topic/create"
    );
  };
  const goBack=()=>{
    history.push("/admin/subject/"+subjectId+"/chapter");
  }
  const [topics, setTopic] = useState([]);
  const [filters, setFilters] = useState({
    limit: LIMIT,
    page: 1,
  });
  const [totalCount, setTotalCount] = useState(0);

  let { subjectId, chapterId } = useParams();
  useEffect(() => {
    getTopicData(chapterId);
  }, [filters, chapterId]);

  async function getTopicData(chapterId) {
    try {
      const stringified = queryString.stringify(filters);
      const userData = await get(
        `/api/admin/chapter/${chapterId}/topic?${stringified}`
      );
      setTopic(userData.topics);
      setTotalCount(userData.total_count);
    } catch (err) {
      setTopic([]);
    }
  }

  async function onFilterChange(newFilters) {
    setFilters({
      ...filters,
      ...newFilters,
    });
  }

  async function toggleStatus(chapter_id, topicId) {
    try {
      await deleteRequest(
        "/api/admin/chapter/" + chapter_id + "/topic/" + topicId
      );
      await getTopicData(chapter_id);
    } catch (err) {}
  }

  const renderData = (topic, index) => {
    let indexCount = (filters.page - 1) * LIMIT + index + 1;
    return [
      <tr key={topic._id} className={topic.status === 1 ? "" : "bg-danger"}>
        <td>{indexCount}</td>
        <td>{topic.name}</td>
        <td>{topic.description}</td>
        <td>{topic.created_by.name}</td>
        <td>{new Date(topic.created_on).toLocaleString()}</td>
        <td>
          <Link
            to={`/admin/subject/${subjectId}/chapter/${chapterId}/topic/${topic._id}/edit`}
            className="btn"
            title="Edit"
          >
            <FontAwesomeIcon icon={faEdit} color="blue" />
          </Link>
          {topic.status === 1 ? (
            <Button
              variant="link"
              onClick={() => toggleStatus(chapterId, topic._id)}
              title="Disable"
            >
              <FontAwesomeIcon icon={faTimes} color="red" />
            </Button>
          ) : (
            <Button
              variant="link"
              onClick={() => toggleStatus(chapterId, topic._id)}
              title="Enable"
            >
              <FontAwesomeIcon icon={faCheck} color="blue" />
            </Button>
          )}
        </td>
      </tr>,
    ];
  };

  return (
    <>
      <Filter onChange={onFilterChange} />
      <Card>
        <Card.Header>
          <h4 className="float-left">Manage Topics</h4>
          <Button variant="info" onClick={goBack}  style={{marginLeft:"885px"}}>
           Back
          </Button>{" "}
          <Button variant="info" onClick={handleClick} className="float-right">
            Add New
          </Button>
        </Card.Header>
        <Card.Body className="p-0">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Description</th>
                <th>Created By</th>
                <th>Created On</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{topics.map(renderData)}</tbody>
          </Table>
        </Card.Body>
        <Card.Footer>
          <Pagination
            currentPage={filters.page}
            limit={LIMIT}
            totalCount={totalCount}
            onChange={onFilterChange}
            filters={{}}
          />
        </Card.Footer>
      </Card>
    </>
  );
}
export default Subject;

function Filter(props) {
  function onSubmit(e) {
    e.preventDefault();
    props.onChange({
      text: document.getElementById("name").value,
      page: 1,
    });
  }

  function onReset(e) {
    e.preventDefault();
    props.onChange({
      text: "",
      page: 1,
    });
    document.getElementById("name").value="";
  }

  return (
    <Form onSubmit={onSubmit} onReset={onReset}>
      <fieldset className="filters">
        <legend align="left">Filters:</legend>
        <div className="filters-body">
          <Row>
            <Col md="3">
              <Form.Group controlId="name">
                <Form.Label>Topic</Form.Label>
                <Form.Control as="input" placeholder="Enter text" />
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className="filters-footer">
          <Button type="reset" variant="outline-secondary">
            Reset
          </Button>{" "}
          <Button type="submit" variant="primary">
            Submit
          </Button>
        </div>
      </fieldset>
    </Form>
  );
}
