import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faCheck,
  faTimes,
  faPlayCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Moment from "react-moment";

import { post } from "../../../helpers/RequestHelper";

const TestRow = function ({
  test,
  testIndex,
  toggleStatus,
  handlePreviewShow,
}) {
  const history = useHistory();

  function toggleTestStatus() {
    toggleStatus(test._id);
  }

  async function createDuplicate() {
    if (!window.confirm("Are you sure, you want to create a duplicate test?")) {
      return true;
    }

    try {
      const response = await post("/api/admin/test/duplicate", { id: test._id });
      history.push("/admin/test/" + response._id + "/edit");
    } catch( err ) {}
  }

  return (
    <tr key={test._id} className={test.status === 1 ? "" : "bg-danger"}>
      <td>{testIndex}</td>
      <td>{test.name}</td>
      <td dangerouslySetInnerHTML={{ __html: test.description }}></td>
      <td>{test.exam.name}</td>
      <td>{test.created_by.name}</td>
      <td>
        <Moment format="MMM DD, YYYY hh:mm:ss a">{test.created_on}</Moment>
      </td>
      <td>
        <Button
          variant="link"
          onClick={() => handlePreviewShow(test)}
          title="Preview"
        >
          <FontAwesomeIcon icon={faPlayCircle} color="blue" />
        </Button>
        <Link to={`/admin/test/${test._id}/edit`} className="btn" title="Edit">
          <FontAwesomeIcon icon={faEdit} color="blue" />
        </Link>
        {test.status === 1 ? (
          <Button variant="link" onClick={toggleTestStatus} title="Disable">
            <FontAwesomeIcon icon={faTimes} color="red" />
          </Button>
        ) : (
          <Button variant="link" onClick={toggleTestStatus} title="Enable">
            <FontAwesomeIcon icon={faCheck} color="blue" />
          </Button>
        )}
        <br />
        <Link
          to={`/admin/test/${test._id}/question`}
          className="btn"
          style={{ color: "blue" }}
        >
          View Questions
        </Link>
        <br />
        <Button variant="link" onClick={createDuplicate} title="Duplicate">
          Duplicate
        </Button>
      </td>
    </tr>
  );
};

export default TestRow;
